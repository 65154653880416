import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import styles from './StoreManagement.module.scss';
import Modal from 'react-modal';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 
import Tooltip from '@mui/material/Tooltip';
import { countries } from 'countries-list';
import Select from 'react-select';

Modal.setAppElement('#root');

function StoreManagement() {
    useEffect(() => {
        document.title = 'Stores | Qiwi';
      }, []);

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false); 
    const [loadingExpire, setLoadingExpire] = useState(false); 
    const [loadingModal, setLoadingModal] = useState(false); 
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [storeToDeleteOrders, setStoreToDeleteOrders] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [shopDomain, setShopDomain] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [newCountry, setNewCountry] = useState({});
    const [showSelect, setShowSelect] = useState({}); // Toggler for each store's select dropdown

    const countryOptions = Object.keys(countries).map(code => ({
        value: countries[code].name, // Använd landets namn som value
        label: countries[code].name // Använd landets namn som label
    }));

    const customStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: '#edeeef',
          borderColor: '#9e9e9e',
          minHeight: '30px',
          border: 'none',
          height: '30px',
          boxShadow: null,
          '&:hover': {
            border: 'none',
            cursor: 'pointer'
          }
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: '30px',
          padding: '0 6px',
          minWidth: '120px',

        }),
        input: (provided) => ({
          ...provided,
          margin: '0px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
    };
    
    // Lägg till ett nytt land i backend och uppdatera state med det nya landet
    const addCountry = async (storeId, country) => {
        try {
            // Lägga till i backend och få tillbaka landets id och namn (country)
            const addedCountry = await merchantService.addCountryToStore(storeId, country.value);
            
            // Uppdatera store med det nya landet (inklusive id och country)
            const storeIndex = stores.findIndex(store => store.id === storeId);
            const updatedStores = [...stores];
            const store = updatedStores[storeIndex];

            store.countries.push({ id: addedCountry.id, country: addedCountry.country });
            setStores(updatedStores);

        } catch (error) {
            console.error('Failed to add country:', error);
        }
    };

    // Ta bort ett land i backend och uppdatera state
    const removeCountry = async (storeId, countryId) => {
        try {
            await merchantService.removeCountryFromStore(countryId);
            
            // Uppdatera store genom att ta bort landet
            const storeIndex = stores.findIndex(store => store.id === storeId);
            const updatedStores = [...stores];
            const store = updatedStores[storeIndex];

            store.countries = store.countries.filter(country => country.id !== countryId);
            setStores(updatedStores);

        } catch (error) {
            console.error('Failed to remove country:', error);
        }
    };

    // Lägg till ett nytt land till en butik
    const handleAddCountry = (storeId) => {
        const countryToAdd = newCountry[storeId];

        if (countryToAdd) {
            const storeIndex = stores.findIndex(store => store.id === storeId);
            const store = stores[storeIndex];

            // Kontrollera om landet redan finns i listan
            if (!store.countries.find(country => country.country === countryToAdd.value)) {
                // Uppdatera backend för att lägga till landet och ta emot det nya objektet
                addCountry(storeId, countryToAdd);

                setNewCountry(prevState => ({
                    ...prevState,
                    [storeId]: ''
                }));

                // Döljer select-menyn efter att ett land lagts till
                setShowSelect(prevState => ({
                    ...prevState,
                    [storeId]: false
                }));
            }
        }
    };

    // Ta bort ett land från en butik
    const handleRemoveCountry = (storeId, countryId) => {
        if (window.confirm(`Are you sure you want to remove this country?`)) {
            // Uppdatera backend och frontend
            removeCountry(storeId, countryId);
        }
    };

    // Hantera ändring i select-menyn
    const handleSelectChange = (storeId, selectedOption) => {
        setNewCountry(prevState => ({
            ...prevState,
            [storeId]: selectedOption
        }));
    };

    const toggleRow = (storeId) => {
        setExpandedRow(expandedRow === storeId ? null : storeId);
    };

    // Toggla visning av select-listan för att lägga till nya länder
    const toggleSelect = (storeId) => {
        setShowSelect(prevState => ({
            ...prevState,
            [storeId]: !prevState[storeId]
        }));
    };

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await merchantService.getStores();
                setStores(response || []);
            } catch (error) {
                console.error('Failed to fetch stores:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStores();
    }, []);

    const deleteStoreModal = async (store) => {
        setSelectedStore(store);
        setLoadingModal(true);
        try {
            const response = await merchantService.storeDeleteStatus(store.id);
            setStoreToDeleteOrders(response.orders || []);
        } catch (error) {
            console.error('Failed to fetch store orders:', error);
        } finally {
            setLoadingModal(false);
            setIsDeleteModalOpen(true);
        }
    };

    const expireStore = async () => {
        const confirmed = window.confirm("Are you sure you want to expire this store? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true);
                await merchantService.expireStore(selectedStore.id);
                alert("Store expired successfully.");
                setIsDeleteModalOpen(false);
                window.location.reload();
            } catch (error) {
                console.error("Error expiring store:", error);
            } finally {
                setLoadingExpire(false);
            }
        }
    };

    const deleteStore = async () => {
        if (window.confirm('Are you sure you want to delete this store? This action cannot be undone.')) {
            try {
                setLoadingDelete(true);
                await merchantService.deleteStore(selectedStore.id);
                setStores(stores.filter(store => store.id !== selectedStore.id));
                alert("Store deleted successfully.");
                setIsDeleteModalOpen(false);
            } catch (error) {
                console.error('Error deleting store:', error);
            } finally {
                setLoadingDelete(false);
            }
        }
    };

    const handleConnectStore = async () => {
        const response = await merchantService.fetchInstallationLink(shopDomain);
        window.open(response.installUrl, '_blank');
        setShowModal(false);
    };

    const calculateHoursLeft = (expiringDate) => {
        const currentTime = new Date();
        const expirationTime = new Date(expiringDate);
        const differenceInMilliseconds = expirationTime - currentTime;
        const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        return differenceInHours;
    };

    return (
        <div className={styles.storeManagement}>
            <div className={styles.storeManagementContent}>
                {stores.length !== 0 ? (
                    <>
                        <a className={styles.connectBtn} href={"https://apps.shopify.com/qiwi-fulfill?locale=sv&st_source=autocomplete"} target="_blank">Connect Store</a>
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <thead className={styles.mainThead}>
                                    <tr>
                                        <th className={styles.moreInfo}></th>
                                        <th>Id</th>
                                        <th>Shop Name</th>
                                        <th>Active</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading ? stores.map(store => (
                                        <>
                                        <tr key={store.id} className={expandedRow !== store.id && styles.rows}>
                                            <td className={styles.moreInfo} onClick={() => toggleRow(store.id)}>
                                                <span className="material-symbols-outlined">
                                                    {expandedRow === store.id ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                </span>
                                            </td>
                                            <td>{store.id}</td>
                                            <td className={styles.flexCell}><div style={{backgroundColor: store.color}} className={styles.shopLogo}>{store.shortName}</div>{store.shop.replace('.myshopify.com', '')}</td>
                                            <td>{store.expiringDate ? 
                                                    <Tooltip arrow title={`This store has uninstalled the app and will be deleted in ${calculateHoursLeft(store.expiringDate)} hours if there are no pending orders. If there are pending orders the store will be deleted until fulfilled or after 29-30 days from when the redact request. To prevent this, reinstall the app.`}>
                                                        <div className={styles.hourglass}>
                                                            <span className="material-symbols-outlined">hourglass_bottom</span>
                                                            {`${calculateHoursLeft(store.expiringDate)}h`}
                                                        </div>
                                                    </Tooltip>
                                                    :
                                                    (store.expiring ?
                                                        <div className={styles.expiring}>
                                                            <span className="material-symbols-outlined">hourglass_bottom</span>
                                                        </div> :
                                                        <div className={styles.checked}>
                                                            <span className="material-symbols-outlined">check_circle</span>
                                                        </div>
                                                    )}
                                            </td>
                                            <td>{new Date(store.createdAt).toISOString().split('T')[0]}</td>
                                            <td className={styles.actions}>
                                                <button className={styles.deleteButton} onClick={() => deleteStoreModal(store)} disabled={store.expiring}>Delete</button>
                                            </td>
                                        </tr>
                                        {expandedRow === store.id && (
                                            <tr className={styles.expandedRow}>
                                                <td colSpan="6">
                                                    <div>
                                                        <h4>Countries:</h4>
                                                        <div className={styles.countriesConfigure}>
                                                            {store.countries && store.countries.length > 0 ? (
                                                                store.countries.map((country, index) => (
                                                                    <div key={index} className={styles.countryItem}>
                                                                        <p>{country.country}</p>
                                                                        <button onClick={() => handleRemoveCountry(store.id, country.id)}>×</button>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p className={styles.noFoundCountry}>No countries added yet</p>
                                                            )}

                                                            <div className={styles.addCountryContainer}>
                                                                <button onClick={() => toggleSelect(store.id)} className={`${showSelect[store.id] ? styles.activeAddCountry : styles.inactiveAddCountry} ${styles.addCountryBtn}`}>
                                                                    {showSelect[store.id] ? "×" : "+"}
                                                                </button>
                                                                {showSelect[store.id] && (
                                                                    <>
                                                                    <Select
                                                                        value={newCountry[store.id] || null}
                                                                        onChange={(selectedOption) => handleSelectChange(store.id, selectedOption)}
                                                                        options={countryOptions}
                                                                        placeholder="Select a country"
                                                                        styles={customStyles}
                                                                    />
                                                                        <button onClick={() => handleAddCountry(store.id)} className={styles.confirmAddBtn}>
                                                                        <span class="material-symbols-outlined">
save
</span>
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        </>
                                    )) :
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                            <td colSpan={5}></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : !loading &&
                <div className={styles.connectFirst}>
                <a href={"https://apps.shopify.com/qiwi-fulfill?locale=sv&st_source=autocomplete"} target="_blank">No store connected, press here to download and connect store!</a>
                </div>
            }
            </div>

            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Store</h2>
                {loadingModal ? (
                    <div className={styles.spinnerModal}><LoadingSpinner /></div>
                ) : storeToDeleteOrders && storeToDeleteOrders.length > 0 ? (
                    <>
                        <div className={styles.warningMessage}>
                            <span className="material-symbols-outlined">warning</span>
                            <p><strong>Warning: There are existing orders pending for this store. The store will be set to expiring until these orders are fulfilled.</strong></p>
                        </div>
                        <p>When you delete this store, the app will not be automatically uninstalled, and products sourced from Qiwi will not be removed from your Shopify store. Please ensure you manually set these products to "Draft" or remove them from your store.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.buttons}>
                            <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                            <button className={styles.expiringButton} onClick={expireStore} disabled={loadingExpire}>
                                {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p><strong>Are you sure you want to delete this store? This action cannot be undone.</strong></p>
                        <p>When you delete this store, the app will not be automatically uninstalled, and products sourced from Qiwi will not be removed from your Shopify store. Please ensure you manually set these products to "Draft" or remove them from your store.</p>
                        <div className={styles.buttons}>
                            <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                            <button className={styles.deleteButtonModal} onClick={deleteStore} disabled={loadingDelete}>
                                {loadingDelete ? <LoadingButtonDots /> : 'Yes, delete this store'}
                            </button>
                        </div>
                    </>
                )}
            </Modal>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.content}>
                    <h2>Connect Shopify Store</h2>
                    <div className={styles.domainInput}>
                        <p>https://</p>
                        <input
                            type="text"
                            value={shopDomain}
                            onChange={(e) => setShopDomain(e.target.value)}
                            className={styles.inputField}
                        />
                        <p>.myshopify.com</p>
                    </div>
                    <div className={styles.buttons}>
                        <button onClick={handleConnectStore} className={styles.connectButton}>Connect Store</button>
                        <button onClick={() => setShowModal(false)} className={styles.closeButton}>Close</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default StoreManagement;
