import React, { useState } from 'react';
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import authService from '../services/authService';

const MerchantLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    // Kontrollera om någon av sourcing- eller payments-vägarna är aktiva
    const isPaymentsActive = location.pathname.includes('/admin/payments');
    const isUserActive = location.pathname.includes('/admin/users');

    return (
        <div className={styles.container}>
            <div className={styles.topnav}>
                <div className={styles.topnavComponent}>
                    <div className={styles.header}>
                        <h1>qiwi</h1>
                        <h2>ADMIN</h2>
                    </div>
                    <div className={styles.navLinks}>
                    <div
                            className={`${styles.dropdown} ${isUserActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowUserDropdown(true)}
                            onMouseLeave={() => setShowUserDropdown(false)}
                        >
                            <div>Users</div>
                            {showUserDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/admin/users/all-users" className={({ isActive }) => isActive ? styles.underline : ''}>All Users</NavLink>
                                    <NavLink to="/admin/users/merchants" className={({ isActive }) => isActive ? styles.underline : ''}>Merchants</NavLink>
                                    <NavLink to="/admin/users/suppliers" className={({ isActive }) => isActive ? styles.underline : ''}>Suppliers</NavLink>
                                    <NavLink to="/admin/users/create-supplier" className={({ isActive }) => isActive ? styles.underline : ''}>Create Supplier</NavLink>
                                    <NavLink to="/admin/users/create-user" className={({ isActive }) => isActive ? styles.underline : ''}>Create User</NavLink>
                                </div>
                            )}
                        </div>
                        <NavLink to="/admin/stores" className={({ isActive }) => isActive ? styles.active : ''}>
                            Stores
                        </NavLink>
                        <NavLink to="/admin/products" className={({ isActive }) => isActive ? styles.active : ''}>
                            Products
                        </NavLink>
                        <NavLink to="/admin/sourcing-requests/" className={({ isActive }) => isActive ? styles.active : ''}>
                            Sourcing
                        </NavLink>
                        <NavLink to="/admin/orders" className={({ isActive }) => isActive ? styles.active : ''}>
                            Orders
                        </NavLink>
                        <div
                            className={`${styles.dropdown} ${isPaymentsActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowPaymentDropdown(true)}
                            onMouseLeave={() => setShowPaymentDropdown(false)}
                        >
                            <div>Payments</div>
                            {showPaymentDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/admin/payments/all-payments" className={({ isActive }) => isActive ? styles.underline : ''}>All Payments</NavLink>
                                    <NavLink to="/admin/payments/supplier-payments" className={({ isActive }) => isActive ? styles.underline : ''}>Supplier Payments</NavLink>
                                </div>
                            )}
                        </div>
                        <NavLink to="/admin/support-tickets" className={({ isActive }) => isActive ? styles.active : ''}>
                            Support Tickets
                        </NavLink>
                        <NavLink to="/admin/partners" className={({ isActive }) => isActive ? styles.active : ''}>
                            Partners
                        </NavLink>
                    </div>
                </div>
                <button onClick={logout} className={styles.logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                </button>
            </div>
            <div className={styles.main}>
                <Outlet />
            </div>
        </div>
    );
};

export default MerchantLayout;
