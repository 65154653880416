// src/components/FilterComponent.jsx
import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { FormControl, Select, MenuItem } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./FilterComponent.module.scss";

const FilterComponent = ({
  options = [], // Array av strängar för filteralternativ
  activeOption, // Aktuell aktiv option
  setActiveOption, // Funktion för att sätta aktiv option
  statusFilter,
  setSelectFilter,
  setInitialLoad,
  selectLabel,
  dateRange,
  setDateRange,
  searchQuery,
  setSearchQuery,
  statusOptions = [], // Array av statusalternativ för Select
  searchPlaceholder = "Search...",
  includeSearch = false,
  applyFilters,
  filtersDisabled = false,
}) => {
  const [showDate, setShowDate] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const datepickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.dateContainer}`)
      ) {
        setShowDate(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datepickerRef]);

  const toggleDate = () => {
    setShowDate((prevShowDate) => !prevShowDate);
  };

  const handleOptionClick = (option) => {
    setInitialLoad(true);
    setActiveOption(option);
  };

  useEffect(() => {
    applyFilters();
  }, [activeOption]);

  useEffect(() => {}, []);

  const handleStatusChange = (e) => {
    setSelectFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearDates = () => {
    setDateRange([]);
    setShowDate(false);
  };

  const clearFilters = () => {
    if (statusFilter && statusFilter.length > 0) {
      setSelectFilter("all");
    }
    setDateRange([]);
    if (includeSearch) {
      setSearchQuery("");
      setShowSearch(false);
    }
  };

  const toggleSearch = () => {
    if (showSearch) {
      setSearchQuery(""); // Reset search query when hiding the search input
    }
    setShowSearch(!showSearch);
  };

  // Sätt den första optionen som aktiv om ingen är vald
  useEffect(() => {
    if (!activeOption && options.length > 0) {
      setActiveOption(options[0]);
    }
  }, [activeOption, options, setActiveOption]);

  return (
    <div className={styles.filterContainer}>
      <div className={styles.option}>
        {options.map((option, index) => (
          <button
            key={option}
            onClick={() => handleOptionClick(option)}
            className={`${styles.optionButton} ${
              activeOption === option ? styles.active : ""
            }`}
            disabled={filtersDisabled && activeOption === option}>
            {option}
          </button>
        ))}
      </div>

      <div className={styles.filterOptions}>
        {statusFilter && statusFilter.length > 0 && (
          <FormControl
            size="small"
            disabled={filtersDisabled}
            style={{ height: 30, borderRadius: 30 }}>
            <Select
              style={{ height: 30, borderRadius: 30 }}
              value={statusFilter}
              onChange={handleStatusChange}
              sx={{
                backgroundColor: "#edeeef",
                color: "#202331",
                boxShadow: "none",
                border: "none",
                transition: "color 0.3s",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                "&.Mui-focused": {
                  backgroundColor: "#000",
                  color: "white",
                  transition: "background-color 0.3s, color 0.3s",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                },
                "&:not(.Mui-disabled):hover": {
                  backgroundColor: "#000",
                  color: "white",
                  transition: "background-color 0.3s, color 0.3s",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                  transition: "color 0.3s",
                },
                "&.Mui-focused .MuiSvgIcon-root": {
                  color: "white",
                },
                "&:not(.Mui-disabled):hover .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiMenuItem-root": {
                  backgroundColor: "#edeeef",
                  color: "#202331",
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "yellow",
                  color: "#202331",
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "pink",
                  color: "white",
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: "red",
                  color: "white",
                },
              }}>
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Date Range Picker */}
        <div className={styles.dateContainer}>
          <button
            className={`${styles.dateBtn} ${
              dateRange.length > 0 && styles.activeDateBtn
            }`}
            onClick={toggleDate}
            disabled={filtersDisabled}>
            <span className="material-symbols-outlined">date_range</span>
          </button>
          {dateRange.length > 0 && (
            <>
              <div className={styles.dates} onClick={toggleDate}>
                <span>
                  {format(dateRange[0].startDate, "MM/dd/yy")} &rarr;{" "}
                  {format(dateRange[0].endDate, "MM/dd/yy")}
                </span>
              </div>
              <button onClick={clearDates} className={styles.clearDateButton}>
                <span className="material-symbols-outlined">close</span>
              </button>
            </>
          )}
          <div
            className={`${styles.datepicker} ${
              showDate ? styles.activeDate : styles.inactiveDate
            }`}
            ref={datepickerRef}>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={
                dateRange.length > 0
                  ? dateRange
                  : [
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                      },
                    ]
              }
              maxDate={new Date()}
            />
          </div>
        </div>

        {/* Search Funktionalitet */}
        {includeSearch && (
          <div className={styles.searchContainer}>
            <button
              className={`${styles.searchBtn} ${
                showSearch ? styles.activeSearchBtn : styles.inactiveSearchBtn
              }`}
              onClick={toggleSearch}
              disabled={filtersDisabled}>
              <span className="material-symbols-outlined">search</span>
            </button>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
              className={`${styles.searchInput} ${
                showSearch ? styles.activeSearch : styles.inactiveSearch
              }`}
              disabled={filtersDisabled}
            />
          </div>
        )}

        {/* Apply och Clear Filters */}
        <button
          onClick={applyFilters}
          className={styles.filterButton}
          disabled={filtersDisabled}>
          Apply
        </button>
        <button
          onClick={clearFilters}
          className={styles.clearButton}
          disabled={filtersDisabled}>
          <span className="material-symbols-outlined">close</span> Clear
        </button>
      </div>
    </div>
  );
};

export default FilterComponent;
