import React, { useState, useEffect, useRef } from "react";
import styles from "../CreateQuotation.module.scss";
import Modal from "react-modal";
import supplierService from "../../../services/supplierService";
import { useNavigate } from "react-router-dom";
import LoadingButtonDots from '../../../components/LoadingButtonDots';

const NonVariantForm = ({ request, existingQuotation, quotationExists, supplier }) => {
  const [formData, setFormData] = useState({
    productPrice: "",
    productProfit: "0", // Sätt initialt profit till "0"
    yourProductPrice: "",
    image: null,
    SKU: "",
    comments: "",
    shippingData: {},
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [errorCountries, setErrorCountries] = useState({});
  const nonVariantFileInputRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (request.SourcingCountries && request.SourcingCountries.length > 0) {
      setSelectedCountry(request.SourcingCountries[0]);
      const initialShippingData = {};
      request.SourcingCountries.forEach((country) => {
        initialShippingData[country.country] = {
          shippingPrice: '',
          shippingProfit: "0", // Sätt initialt shippingProfit till "0"
          yourShippingPrice: '',
        };
      });
      setFormData((prev) => ({
        ...prev,
        shippingData: initialShippingData,
      }));
    }

    if (quotationExists && existingQuotation && existingQuotation.QuotationNoVariant) {
      loadExistingQuotation();
    }
  }, [request, quotationExists, existingQuotation]);

  const generateRandomSKU = () => {
    const prefix = request.productName.split(" ")[0]; // En fast prefix, kanske produkttyp, kategori eller varumärke
    const randomPart = Math.random().toString(36).substring(2, 7).toUpperCase(); // Slumpmässiga bokstäver och siffror
    const timestamp = Date.now().toString().slice(-5); // Några siffror från tidsstämpel för att göra det unikt
  
    const sku = `${prefix}-${randomPart}-${timestamp}`;
  
    setFormData((prev) => ({
      ...prev,
      SKU: sku,
    }));
  };

  const loadExistingQuotation = () => {
    const qnv = existingQuotation.QuotationNoVariant;
    const shippingData = {};
    qnv.QuotationShippingPrices.forEach((shipping) => {
      shippingData[shipping.country] = {
        shippingPrice: shipping.shippingPrice,
        shippingProfit: "0", // Sätt shippingProfit till "0"
        yourShippingPrice: shipping.shippingPrice, // Eftersom profit är 0
      };
    });

    setFormData({
      productPrice: qnv.productPrice,
      productProfit: "0", // Sätt productProfit till "0"
      yourProductPrice: qnv.productPrice, // Eftersom profit är 0
      image: qnv.image,
      SKU: qnv.SKU,
      comments: existingQuotation.comments || '',
      shippingData,
    });
  };

  const handleDefaultInputChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (name === "productProfit") {
      // Ignorera ändringar på productProfit och behåll värdet "0"
      return;
    }

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else if (type === "number") {
      // Tillåt tomma strängar och nummer
      setFormData((prev) => {
        const updatedValues = {
          ...prev,
          [name]: value,
        };

        // Parse values endast om de inte är tomma
        const productPrice =
          updatedValues.productPrice !== ""
            ? parseFloat(updatedValues.productPrice)
            : NaN;
        const productProfit = 0; // productProfit är alltid 0
        const yourProductPrice =
          updatedValues.yourProductPrice !== ""
            ? parseFloat(updatedValues.yourProductPrice)
            : NaN;

        // Hantera productPrice och yourProductPrice kalkylering
        if (name === "productPrice") {
          if (!isNaN(productPrice)) {
            const calculatedYourProductPrice = (
              productPrice - productProfit
            );
            updatedValues.yourProductPrice =
              calculatedYourProductPrice.toString();
          } else {
            updatedValues.yourProductPrice = "";
          }
        }

        return updatedValues;
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;

    if (!selectedCountry) {
      console.error("No country selected.");
      return;
    }

    if (name === "shippingProfit") {
      // Ignorera ändringar på shippingProfit och behåll värdet "0"
      return;
    }

    setFormData((prev) => {
      const shippingDataForCountry =
        prev.shippingData[selectedCountry.country] || {};

      // Tillåt tomma strängar och nummer
      let updatedShippingData = {
        ...prev.shippingData,
        [selectedCountry.country]: {
          ...shippingDataForCountry,
          [name]: value, // Tillåt tomt värde
        },
      };

      // Parse values endast om de inte är tomma
      const shippingPrice =
        updatedShippingData[selectedCountry.country].shippingPrice !== ""
          ? parseFloat(updatedShippingData[selectedCountry.country].shippingPrice)
          : NaN;
      const shippingProfit = 0; // shippingProfit är alltid 0
      const yourShippingPrice =
        updatedShippingData[selectedCountry.country].yourShippingPrice !== ""
          ? parseFloat(
              updatedShippingData[selectedCountry.country].yourShippingPrice
            )
          : NaN;

      // Hantera shippingPrice och yourShippingPrice kalkylering
      if (name === "shippingPrice") {
        if (!isNaN(shippingPrice)) {
          const calculatedYourShippingPrice = (
            shippingPrice - shippingProfit
          );
          updatedShippingData[selectedCountry.country].yourShippingPrice =
            calculatedYourShippingPrice.toString();
        } else {
          updatedShippingData[selectedCountry.country].yourShippingPrice = "";
        }
      }

      return {
        ...prev,
        shippingData: updatedShippingData,
      };
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFormData((prev) => ({
        ...prev,
        image: file,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        image: null,
      }));
      alert("Please select a valid image file.");
    }
  };

  const validateForm = () => {
    const errors = {};
    const errorCountries = {}; // För att lagra länder med fel

    // Validera produktfält
    if (!formData.productPrice || parseFloat(formData.productPrice) <= 0) {
      errors.productPrice =
        "Product Price is required and must be greater than 0";
    }
    // Ändrad validering för productProfit till att tillåta 0
    if (formData.productProfit === "" || parseFloat(formData.productProfit) < 0) {
      errors.productProfit = "Product Profit cannot be negative";
    }
    if (
      formData.yourProductPrice === "" ||
      parseFloat(formData.yourProductPrice) < 0
    ) {
      errors.yourProductPrice = "Your Product Price cannot be negative";
    }
    if (!formData.SKU) {
      errors.SKU = "SKU is required";
    }
    if (!formData.image) {
      errors.image = "Image is required";
    }

    // Validera shipping data per land
    request.SourcingCountries.forEach((country) => {
      const shipping = formData.shippingData[country.country];
      if (
        !shipping ||
        !shipping.shippingPrice ||
        parseFloat(shipping.shippingPrice) <= 0
      ) {
        errors[
          `shippingPrice_${country.country}`
        ] = `Shipping Price for ${country.country} is required and must be greater than 0`;
        errorCountries[country.country] = country; // Lägg till detta land till errorCountries
      }
      // Ändrad validering för shippingProfit till att tillåta 0
      if (
        shipping.shippingProfit === "" ||
        parseFloat(shipping.shippingProfit) < 0
      ) {
        errors[
          `shippingProfit_${country.country}`
        ] = `Shipping Profit for ${country.country} cannot be negative`;
        errorCountries[country.country] = country; // Lägg till detta land till errorCountries
      }
      if (
        shipping.yourShippingPrice === "" ||
        parseFloat(shipping.yourShippingPrice) < 0
      ) {
        errors[
          `yourShippingPrice_${country.country}`
        ] = `Your Shipping Price for ${country.country} cannot be negative`;
        errorCountries[country.country] = country; // Lägg till detta land till errorCountries
      }
    });

    setFormErrors(errors);
    setErrorCountries(errorCountries); // Uppdatera state med länder som har fel

    return Object.keys(errors).length === 0;
  };

  const generateNonVariantData = () => {
    return {
      productPrice: formData.productPrice,
      productProfit: "0", // Sätt alltid productProfit till "0"
      SKU: formData.SKU,
      image: formData.image ? formData.image : null,
      comments: formData.comments,
      shippingData: Object.keys(formData.shippingData).reduce((acc, country) => {
        acc[country] = {
          shippingPrice: formData.shippingData[country].shippingPrice,
          shippingProfit: "0", // Sätt alltid shippingProfit till "0"
          yourShippingPrice: formData.shippingData[country].shippingPrice, // Eftersom shippingProfit är 0
        };
        return acc;
      }, {}),
    };
  };

  const handleModalConfirm = async () => {
    try {
      setUploading(true);
      const formDataToSend = new FormData();
      formDataToSend.append('requestId', request.id);

      // Förbered dataobjektet för serialisering
      const dataToSerialize = {
        productPrice: modalData.productPrice,
        productProfit: "0", // Sätt alltid productProfit till "0"
        SKU: modalData.SKU,
        comments: modalData.comments,
        shippingData: Object.keys(modalData.shippingData).reduce((acc, country) => {
          acc[country] = {
            shippingPrice: modalData.shippingData[country].shippingPrice,
            shippingProfit: "0", // Sätt alltid shippingProfit till "0"
            yourShippingPrice: modalData.shippingData[country].shippingPrice, // Eftersom shippingProfit är 0
          };
          return acc;
        }, {}),
      };

      if (modalData.image) {
        if (typeof modalData.image === 'string') {
          dataToSerialize.imageUrl = modalData.image;
        } else {
          // Förbered att skicka bildfilen
          const imageFieldName = 'image'; // Eller något unikt identifierare
          formDataToSend.append(imageFieldName, modalData.image);
          dataToSerialize.image = imageFieldName;
        }
      }

      formDataToSend.append('quotationData', JSON.stringify(dataToSerialize));

      await supplierService.createQuotation(formDataToSend);

      setUploading(false);
      navigate('/supplier/sourcing');
    } catch (error) {
      console.error('Error submitting form: ', error);
      setUploading(false); // Se till att sluta ladda även vid fel
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    setModalData(generateNonVariantData());
    setShowModal(true);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit} className={styles.form}>
        <div className={styles.basicFormContainer}>
          <p className={styles.profit}>
            Qiwi takes {supplier.provisionProcentage}% of the "Product Profit" and "Shipping Profit"
          </p>
          <div className={styles.pricesInput}>
            <div>
              Product Price
              <span style={{ color: "red" }}>*</span>
              <div className={styles.inputWithPrefix}>
                <span className={styles.prefix}>€</span>
                <input
                  type="number"
                  name="productPrice"
                  step="0.01"
                  value={formData.productPrice}
                  onChange={handleDefaultInputChange}
                  required
                  min="0.01"
                />
              </div>
            </div>
            <div className={styles.profitInputs}>
              <div>
                Product Profit
                <span style={{ color: "red" }}>*</span>
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>+€</span>
                  <input
                    type="number"
                    name="productProfit"
                    step="0.01"
                    value="0" // Sätt alltid värdet till "0"
                    disabled // Gör inputfältet icke-redigerbart
                    className={styles.disabledInput} // Lägg till en klass för eventuell styling
                  />
                </div>
              </div>
              <div className={styles.syncIcon}>
                <span className="material-symbols-outlined">sync_alt</span>
              </div>
              <div>
                Your Product Price
                <span style={{ color: "red" }}>*</span>
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>€</span>
                  <input
                    type="number"
                    name="yourProductPrice"
                    step="0.01"
                    value={formData.yourProductPrice}
                    disabled // Gör inputfältet icke-redigerbart
                    className={styles.disabledInput} // Lägg till en klass för eventuell styling
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pricesInput}>
            <div className={styles.countrySelector}>
              {request.SourcingCountries &&
                request.SourcingCountries.map((country) => (
                  <button
                    key={country.country}
                    className={`
                      ${styles.countryButton} 
                      ${selectedCountry?.country === country.country ? styles.selectedCountry : ""} 
                      ${
                        formData.shippingData[country.country] &&
                        parseFloat(formData.shippingData[country.country].shippingPrice) > 0 &&
                        parseFloat(formData.shippingData[country.country].yourShippingPrice) >= 0
                          ? styles.countryDone
                          : styles.countryNotDone
                      }
                      ${errorCountries[country.country] && styles.errorButton} 
                    `}
                    type="button"
                    onClick={() => setSelectedCountry(country)}>
                    {country.country}
                  </button>
                ))}
            </div>
            <div>
              Shipping Price
              <span style={{ color: "red" }}>*</span>
              <div className={styles.inputWithPrefix}>
                <span className={styles.prefix}>€</span>
                <input
                  type="number"
                  name="shippingPrice"
                  step="0.01"
                  value={
                    formData.shippingData[selectedCountry?.country]?.shippingPrice ||
                    ""
                  }
                  onChange={handleShippingInputChange}
                  required
                  min="0.01"
                />
              </div>
            </div>
            <div className={styles.profitInputs}>
              <div>
                Shipping Profit
                <span style={{ color: "red" }}>*</span>
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>+€</span>
                  <input
                    type="number"
                    name="shippingProfit"
                    step="0.01"
                    value="0" // Sätt alltid värdet till "0"
                    disabled // Gör inputfältet icke-redigerbart
                    className={styles.disabledInput} // Lägg till en klass för eventuell styling
                  />
                </div>
              </div>
              <div className={styles.syncIcon}>
                <span className="material-symbols-outlined">sync_alt</span>
              </div>
              <div>
                Your Shipping Price
                <span style={{ color: "red" }}>*</span>
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>€</span>
                  <input
                    type="number"
                    name="yourShippingPrice"
                    step="0.01"
                    value={
                      formData.shippingData[selectedCountry?.country]
                        ?.yourShippingPrice || ""
                    }
                    disabled // Gör inputfältet icke-redigerbart
                    className={styles.disabledInput} // Lägg till en klass för eventuell styling
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            SKU
            <span style={{ color: "red" }}>*</span>
            <div className={styles.skuContainer}>
            <input
              type="text"
              name="SKU"
              value={formData.SKU}
              onChange={handleDefaultInputChange}
              required
            />
            <button onClick={() => generateRandomSKU()}type="button">Generate Random</button>
            </div>
            </div>
          <div>
            Product Image
            <span style={{ color: 'red' }}>*</span>
            <div className={styles.imageCell}>
              <label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  ref={nonVariantFileInputRef}
                  className={styles.uploadFileLogo}
                  name="image"
                />
                <span className="material-symbols-outlined">
                  download
                </span>
                {formData.image && (
                  <img
                    src={
                      typeof formData.image === 'string'
                        ? `/uploads/productImages/${formData.image}`
                        : URL.createObjectURL(formData.image)
                    }
                    className={styles.imagePreview}
                    alt="Product"
                  />
                )}
                {formData.image && typeof formData.image !== 'string' && (
                  <div className={styles.fileName}>{formData.image.name}</div>
                )}
              </label>
            </div>
          </div>

          <div>
            Note
            <textarea
              name="comments"
              value={formData.comments}
              onChange={handleDefaultInputChange}
            ></textarea>
          </div>
        </div>
        <button type="submit" className={styles.submitButton}>
          View and Submit
        </button>
      </form>
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          if (!uploading) {
            setShowModal(false);
          }
        }}
        className={styles.quotationModal}
        overlayClassName={styles.overlay}>
        <div className={styles.quotationsModalContainer}>
          <h2>Confirm Your Quotation</h2>
          {modalData ? (
            <>
              <div className={styles.variantModalItem}>
                <div className={styles.variantMainInfo}>
                  <div className={styles.imageContainer}>
                    {modalData.image && (
                      <img
                        src={
                          typeof modalData.image === 'string'
                            ? `/uploads/productImages/${modalData.image}`
                            : URL.createObjectURL(modalData.image)
                        }
                        alt="Product"
                      />
                    )}
                  </div>
                  <div className={styles.detailsContainer}>
                    <p>
                      <strong>SKU:</strong> {modalData.SKU}
                    </p>
                    <p>
                      <strong>Product Price:</strong> €{modalData.productPrice}
                    </p>
                    <p>
                      <strong>Product Profit:</strong> €{modalData.productProfit}
                    </p>
                    <p>
                      <strong>Your Product Price:</strong> €{modalData.yourProductPrice}
                    </p>
                  </div>
                </div>
                <h3>Shipping Data</h3>
                <div className={styles.shippingDataContainer}>
                  {request.SourcingCountries.map((country) => {
                    const shipping = modalData.shippingData[country.country];
                    return (
                      <div key={country.country} className={styles.shippingData}>
                        <h4>{country.country}</h4>
                        <div className={styles.countryInfo}>
                          <p>
                            <strong>Shipping Price:</strong>
                          </p>
                          <p className={styles.shippingValue}>
                            {shipping.shippingPrice}€
                          </p>
                          <p>
                            <strong>Shipping Profit:</strong>
                          </p>
                          <p className={styles.shippingValue}>
                            {shipping.shippingProfit}€
                          </p>
                          <p>
                            <strong>Your Shipping Price:</strong>
                          </p>
                          <p className={styles.shippingValue}>
                            {shipping.yourShippingPrice}€
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {modalData.comments && (
                <p className={styles.modalComment}>
                  {" "}
                  <strong>Comments:</strong> {modalData.comments}
                </p>
              )}
            </>
          ) : (
            <p>Loading...</p>
          )}
          <div className={styles.buttonGroup}>
            <button
              onClick={handleModalConfirm}
              className={styles.submitButton}>
                {uploading ? <LoadingButtonDots /> : `Submit ${quotationExists ? "Edit" : ""}`}
            </button>
            <button
              disabled={uploading}
              onClick={() => setShowModal(false)}
              className={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NonVariantForm;
