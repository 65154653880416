// src/pages/Products/Products.jsx
import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Products.module.scss';
import Modal from 'react-modal';
import HoverableList from '../../components/HoverableList';
import Select from 'react-select'; // Importera react-select
import HoverableOverflowText from '../../components/HoverableOverflowText';

// Sätt upp app-elementet för modal
Modal.setAppElement('#root');

const Products = () => {
    // State-hantering
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [hoveredProductId, setHoveredProductId] = useState(null);
    const [visibleVariants, setVisibleVariants] = useState({});
    const [hoveredVariantId, setHoveredVariantId] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [productMoreInfo, setProductMoreInfo] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(''); // State för valt land
    const [selectedProductToDelete, setSelectedProductToDelete] = useState({});
    const [selectedProductToDeleteOrders, setSelectedProductToDeleteOrders] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // Funktion för att hantera visning av delete modal
    const deleteProductModal = async (product) => {
        const orders = await adminService.productDeleteStatus(product.id);
        setSelectedProductToDelete(product);
        setSelectedProductToDeleteOrders(orders.orders);
        setIsDeleteModalOpen(true);
    }

    // Funktion för att visa bild
    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    // Funktion för att visa produktinformation
    const viewInfo = (product) => {
        setProductMoreInfo(product);
        setSelectedCountry(''); // Återställ valt land när modalen öppnas
        setIsInfoModalOpen(true);
    };

    // Hantera hover över produkter
    const handleMouseEnter = (productId) => {
        setHoveredProductId(productId);
    };
    
    const handleMouseLeave = () => {
        setHoveredProductId(null);
    };

    // Hantera hover över varianter
    const handleMouseEnterVariant = (variantId) => {
        setHoveredVariantId(variantId);
    };
    
    const handleMouseLeaveVariant = () => {
        setHoveredVariantId(null);
    };

    // Hantera toggle för arkiverade produkter
    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    // Funktion för att applicera filter
    const applyFilters = () => {
        fetchProducts();
    };

    // Funktion för att hämta produkter
    const fetchProducts = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchProductsByPageAndFilters(currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived);
            setData(response.products || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    // Anropas vid komponentens mount och när beroenden ändras
    useEffect(() => {
        fetchProducts();
    }, [currentPage, itemsPerPage, showArchived, searchQuery, dateRange]);

    // Funktion för sortering
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    // Funktion för sidändring
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Toggle för att visa/dölja varianter
    const toggleVariantsDetail = (productId) => {
        setVisibleVariants(prev => ({
            ...prev,
            [productId]: !prev[productId]
        }));
    };

    // Funktion för att hantera produktutgång
    const expireProduct = async (productId) => {
        const confirmed = window.confirm("Are you sure you want to expire this product? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.expireProduct(productId);
                alert("Product expired successfully.");
                fetchProducts(); // Uppdatera listan
            } catch (error) {
                console.error("Error expiring product:", error);
                alert("Failed to expire product.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    // Funktion för att hantera produktborttagning
    const deleteProduct = async (productId) => {
        const confirmed = window.confirm("Are you sure you want to delete this product? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.productDelete(productId);
                alert("Product deleted successfully.");
                fetchProducts(); // Uppdatera listan
            } catch (error) {
                console.error("Error deleting product:", error);
                alert("Failed to delete product.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };
    
    // Funktion för att skapa detaljrad för varianter
    const createDetailRow = (product) => {
        return (
            <td colSpan="8">
                <table>
                    <thead>
                        <tr>
                            <th>Variant ID</th>
                            <th>Image</th>
                            <th>Variant</th>
                            <th>SKU</th>
                            <th colSpan={3}>Stores</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.variants.map((variant, index) => (
                            <tr key={`variant-${product.id}-${index}`}>
                                <td>{variant.id}</td>
                                <td>
                                    {variant.image && (
                                        <img 
                                            src={`/uploads/productImages/${variant.image}`} 
                                            className={styles.variantImage} 
                                            onClick={() => viewImage(`/uploads/productImages/${variant.image}`)} 
                                            alt={product.name}
                                        />
                                    )}
                                </td>
                                <td>{variant.variantTitle}</td>
                                <td>{variant.SKU}</td>
                                <td 
                                    colSpan={3}
                                    onMouseEnter={() => handleMouseEnterVariant(variant.id)}
                                    onMouseLeave={handleMouseLeaveVariant}
                                    className={variant.stores.length > 1 && styles.hoverable}
                                >
                                    <HoverableList list={variant.stores.map(store => store.shop)} />
                                </td>
                                <td>
                                    {/* Här kan du lägga till eventuella actions för varianter */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    // Funktion för att hantera landval i Info Modal
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption ? selectedOption.value : '');
    };

    // Funktion för att stänga Info Modal
    const closeInfoModal = () => {
        setIsInfoModalOpen(false);
        setProductMoreInfo(null);
        setSelectedCountry('');
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Products</h1>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.filterHeader}>
                    <FilterComponent
                        statusFilter={''} // Ingen statusfilter för produkter
                        setStatusFilter={() => {}} // Ingen operation
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        fetchData={fetchProducts}
                        statusOptions={[]} // Inga statusalternativ för produkter
                        searchPlaceholder="Search by name..."
                        includeSearch={true}
                        showArchived={showArchived}
                        setShowArchived={handleShowArchivedToggle}
                        applyFilters={applyFilters}
                    />
                </div>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Product ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('name')}>
                                <div className={styles.thHeader}>
                                    Product
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('merchant')}>
                                <div className={styles.thHeader}>
                                    Owner
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'merchant' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('Supplier.name')}>
                                <div className={styles.thHeader}>
                                    Supplier
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'Supplier.name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'Supplier.name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Stores</th>
                            <th>Variants</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(product => (
                            <React.Fragment key={product.id}>
                                <tr className={visibleVariants[product.id] ? styles.droppeddown : styles.rows}>
                                    <td>{product.id}</td>
                                    <td>
                                        <div  className={styles.productInfo}>
                                        {product.image && (
                                            <img 
                                                src={`/uploads/productImages/${product.image}`} 
                                                className={styles.variantImage} 
                                                onClick={() => viewImage(`/uploads/productImages/${product.image}`)} 
                                                alt={product.name}
                                            />
                                        )}
                                        <HoverableOverflowText text={product.name} fontSize={14}/>
                                        </div>
                                    </td>
                                    <td>{product.merchant}</td>
                                    <td>{`${product.supplierName} (${product.supplierAlias})`}</td>
                                    <td 
                                        onMouseEnter={() => handleMouseEnter(product.id)}
                                        onMouseLeave={handleMouseLeave}
                                        className={product.Stores.length > 1 && styles.hoverable}
                                    >
                                        <HoverableList list={product.Stores.map(store => store.shop)} />
                                        {/* Om du vill visa antal butiker kan du lägga till det här */}
                                        {/* {product.Stores.length === 1 ? product.Stores[0].shop : product.Stores.length} */}
                                    </td>
                                    <td>
                                        {product.variants.length > 0 &&
                                        <button 
                                            className={styles.toggleRowsButton}
                                            onClick={() => toggleVariantsDetail(product.id)}
                                        >
                                            {product.variants.length}
                                            <span className="material-symbols-outlined">
                                                {visibleVariants[product.id] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                            </span>
                                        </button>
                                        }
                                    </td>
                                    <td>
                                        {product.expiring ? 
                                        <div className={styles.hourglass}>
                                            <span className="material-symbols-outlined">
                                                hourglass_bottom
                                            </span>
                                        </div>
                                         :
                                         <div className={styles.checked}>
                                             <span className="material-symbols-outlined">
                                                 check_circle
                                             </span>
                                         </div>
                                        }
                                    </td>
                                    <td className={styles.buttons}>
                                        <button className={styles.infoButton} onClick={() => viewInfo(product)}>Prices</button>
                                        <button className={styles.deleteButton} onClick={() => deleteProductModal(product)}>Delete</button>
                                    </td>
                                </tr>
                                {visibleVariants[product.id] && product.variants.length > 0 && (
                                    <tr className={styles.detailRow} key={`details-${product.id}`}>
                                        {createDetailRow(product)}
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                        <tr className={styles.rows}>
                            <td colSpan={8}>
                                <PaginationComponent
                                    itemsPerPage={itemsPerPage}
                                    setItemsPerPage={setItemsPerPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Bildmodal */}
            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

            {/* Delete Modal */}
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Product</h2>
                <p>{selectedProductToDelete.name}</p>
                
                {selectedProductToDeleteOrders && selectedProductToDeleteOrders.length > 0 && (
                    <>
                        <p><strong>OBS. There are existing orders pending for this product</strong></p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProductToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                        <td>{order.items[0]?.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
                <div className={styles.buttonGroup}>
                    {selectedProductToDeleteOrders && selectedProductToDeleteOrders.length > 0 ? 
                        <button className={styles.expiringButton} onClick={() => expireProduct(selectedProductToDelete.id)}>Set as Expiring</button> :
                        <button className={styles.deleteButton} onClick={() => deleteProduct(selectedProductToDelete.id)}> Delete</button>
                    }
                </div>
            </Modal>

            {/* Info Modal */}
            <Modal
                isOpen={isInfoModalOpen}
                onRequestClose={closeInfoModal}
                className={styles.modaltwo}
                overlayClassName={styles.overlay}
            >
                <h2>Supply Information</h2>
                {productMoreInfo && (
                    <div className={styles.supplyInfoContent}>
                        {(!productMoreInfo.variants || productMoreInfo.variants.length === 0) ? (
                            <table>
                                <thead>
                                    <tr className={styles.table_header}>
                                        <th>SKU</th>
                                        <th>Product Price</th>
                                        <th className={styles.dynamicShippingTH}>
                                            <div>
                                            Shipping Price
                                            <Select
                                                value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                                onChange={handleCountryChange}
                                                options={Array.from(new Set(
                                                    (productMoreInfo.shippingPrices || []).map(sp => sp.country)
                                                )).map(country => ({ value: country, label: country }))}
                                                className={styles.countrySelect}
                                                isClearable
                                            />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.rows}>
                                        <td>{productMoreInfo.SKU}</td>
                                        <td>€{productMoreInfo.productPrice}</td>
                                        <td>
                                            €{productMoreInfo.shippingPrices.find(sp => sp.country === selectedCountry)?.shippingPrice || 'N/A'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table>
                                <thead>
                                    <tr className={styles.table_header}>
                                        <th>SKU</th>
                                        <th>Product Price</th>
                                        <th className={styles.dynamicShippingTH}>
                                            Shipping Price
                                            <Select
                                                value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                                onChange={handleCountryChange}
                                                options={Array.from(new Set(
                                                    productMoreInfo.variants.flatMap(variant => variant.shippingPrices.map(sp => sp.country))
                                                )).map(country => ({ value: country, label: country }))}
                                                className={styles.countrySelect}
                                                isClearable
                                            />
                                        </th>
                                        {Array.from(new Set(productMoreInfo.variants.flatMap(variant =>
                                            variant.categoryVariants.map(cv => cv.category)
                                        ))).map((category, idx) => (
                                            <th key={idx}>{category}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {productMoreInfo.variants.map((variant, index) => (
                                        <tr key={index} className={styles.rows}>
                                            <td>{variant.SKU}</td>
                                            <td>€{variant.productPrice}</td>
                                            <td>
                                                €{variant.shippingPrices.find(vsp => vsp.country === selectedCountry)?.shippingPrice || 'N/A'}
                                            </td>
                                            {Array.from(new Set(productMoreInfo.variants.flatMap(variant =>
                                                variant.categoryVariants.map(cv => cv.category)
                                            ))).map((category, idx) => {
                                                const variantCategory = variant.categoryVariants.find(cv => cv.category === category);
                                                return <td key={idx}>{variantCategory ? variantCategory.value : ''}</td>;
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        <button onClick={closeInfoModal} className={styles.closeButton}>Close</button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Products;
