import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import authService from '../../services/authService';
import styles from './Settings.module.scss';
import PasswordChangeModal from '../../components/PasswordChangeModal';
import Modal from 'react-modal';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    useEffect(() => {
        document.title = 'Settings | Qiwi';
      }, []);
      
    const [userInfo, setUserInfo] = useState(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [merchantToDeleteOrders, setMerchantToDeleteOrders] = useState({});
    const [newContactEmail, setNewContactEmail] = useState('');
    const [loadingEmail, setLoadingEmail] = useState(false); // Loading state for expire button
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingExpire, setLoadingExpire] = useState(false); // Loading state for expire button
    const [loadingDelete, setLoadingDelete] = useState(false); // Loading state for delete button
    const [loadingModal, setLoadingModal] = useState(false); // Loading state for modal
    const [expiring, setExpiring] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(null); 
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate();

    const deleteMerchantModal = async () => {
        setLoadingModal(true); // Start loading spinner for modal
        const orders = await merchantService.merchantDeleteStatus();
        setMerchantToDeleteOrders(orders.orders);
        setLoadingModal(false); // Stop loading spinner for modal
        setIsDeleteModalOpen(true);
    };

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    const updateEmail = async () => {
            try {
                setLoadingEmail(true); // Start loading spinner
                await merchantService.updateMerchantContactEmail(newContactEmail);
                alert("Merchant contact email updated successfully.");
                setIsEmailModalOpen(false);
                fetchUserInfo();
            } catch (error) {
                console.error("Error updating Merchant contact Email:", error);
            } finally {
                setLoadingEmail(false); // Stop loading spinner
            }
    };

    const expireMerchant = async () => {
        const confirmed = window.confirm("Are you sure you want to expire this Merchant? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true); // Start loading spinner
                await merchantService.expireMerchant();
                sessionStorage.setItem('expiring', true);
                alert("Merchant expired successfully.");
                setIsDeleteModalOpen(false);
                window.location.reload();
            } catch (error) {
                console.error("Error expiring Merchant:", error);
            } finally {
                setLoadingExpire(false); // Stop loading spinner
            }
        }
    };

    const deleteMerchant = async () => {
        if (deleteConfirmation === 'I confirm') {
            try {
                setLoadingDelete(true); // Start loading spinner
                await merchantService.deleteMerchant();
                alert("Merchant deleted successfully.");
                logout();
            } catch (error) {
                console.error('Error deleting user:', error);
            } finally {
                setLoadingDelete(false); // Stop loading spinner
                setIsDeleteModalOpen(false);
            }
        } else {
            alert('You must type "I confirm" to delete your account');
        }
    };

    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true');
        }
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await merchantService.getUserInfo();
            setUserInfo(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    };
    
    useEffect(() => {
        fetchUserInfo();
    }, []);

    const handleChangePassword = async (newPassword) => {
        try {
            setLoadingSubmit(true); // Visa loading dots på knappen
            await authService.changePassword(newPassword);
            alert('Password changed successfully');
            window.location.reload();
        } catch (error) {
            setPasswordChangeError(error.response.data.message); // Sätt felmeddelande
        } finally {
            setLoadingSubmit(false); // Stäng av loading dots på knappen
        }
    };

    if (loading) {
        return <div className={styles.spinner}><LoadingSpinner /></div>; 
    }

    return (
        <div className={styles.container}>
            <div>
            <h3>Personal Information</h3>
            <div className={styles.section}>
                <p><strong>Login email: </strong>{userInfo.user.email}</p>
                <div className={styles.buttonGroup}>
                    <button onClick={() => setIsPasswordModalOpen(true)} className={styles.edit}>Change Password</button>
                    <button onClick={() => deleteMerchantModal()} className={styles.remove} disabled={expiring}>Delete Account</button>
                </div>
            </div>
            </div>
            <div>
            <h3>Merchant Information</h3>
            <div className={styles.section}>
                <p><strong>Contact email: </strong>{userInfo.merchant.contactEmail}</p>
                <div className={styles.buttonGroup}>
                    <button onClick={() => setIsEmailModalOpen(true)} className={styles.edit}>Change Email</button>
                </div>
            </div>
            </div>
            <Modal
                isOpen={isEmailModalOpen}
                onRequestClose={() => setIsEmailModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
                >
                <div className={styles.contactEmailModalContent}>
                    <h2>Changing Contact Email</h2>
                    <input
                    type="email" // Typen är satt till email
                    placeholder="New contact email for merchant..."
                    value={newContactEmail}
                    onChange={(e) => setNewContactEmail(e.target.value)}
                    required // Gör att input måste uppfyllas
                    />
                </div>
                <button
                    className={styles.emailChangeButton}
                    onClick={updateEmail}
                    disabled={!newContactEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newContactEmail) || loadingEmail} // Kontrollera att e-posten är giltig
                >
                    {loadingEmail ? <LoadingButtonDots /> : 'Save email'}
                </button>
                </Modal>

            <PasswordChangeModal
                isOpen={isPasswordModalOpen}
                onRequestClose={() => setIsPasswordModalOpen(false)}
                onChangePassword={handleChangePassword}
                loading={loadingSubmit} 
                error={passwordChangeError} 
            />
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Merchant</h2>
                {loadingModal ? ( 
                    <div className={styles.spinnerModal}><LoadingSpinner /></div> 
                ) : merchantToDeleteOrders && merchantToDeleteOrders.length > 0 ? (
                    <>
                        <div className={styles.warningMessage}>
                            <span className="material-symbols-outlined">warning</span>
                            <p><strong>OBS. There are existing orders pending for this Merchant. Your account will be expiring until fulfilled.</strong></p>
                        </div>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {merchantToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.expiringButton} onClick={expireMerchant} disabled={loadingExpire}>
                            {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                        </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p><strong>Are you sure you want to delete your merchant account? This action cannot be undone.</strong></p>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <input
                            type="text"
                            placeholder='Type "I confirm" to delete'
                            value={deleteConfirmation}
                            onChange={(e) => setDeleteConfirmation(e.target.value)}
                        />
                        <div className={styles.buttons}>
                        <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                        <button className={styles.deleteButtonModal} onClick={deleteMerchant} disabled={deleteConfirmation !== 'I confirm' || loadingDelete}>
                            {loadingDelete ? <LoadingButtonDots /> : 'Yes, delete my account'}
                        </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Settings;
