// components/ProductManagement/ProductManagement.js
import React, { useState, useEffect } from 'react';
import React_Select from 'react-select'; // Use react-select
import merchantService from '../../services/merchantService';
import TablePagination from '@mui/material/TablePagination';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './ProductManagement.module.scss';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Modal from 'react-modal';
import HoverableList from '../../components/HoverableList';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner';
import productPlaceHolder from '../../images/productPlaceHolder.jpeg';
import PublishModal from './components/products/PublishModal'; // Corrected import path

Modal.setAppElement('#root');

function ProductManagement() {
    useEffect(() => {
        document.title = 'Products | Qiwi';
    }, []);

    // State Variables
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(0);
    const [showStorePublish, setShowStorePublish] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageInfo, setPageInfo] = useState({ next: {}, previous: {}, explored: [] });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [productTypes, setProductTypes] = useState('qiwi');
    const [direction, setDirection] = useState('');
    const [activeCardId, setActiveCardId] = useState(null);
    const [showArchived, setShowArchived] = useState(false);
    const [noActiveProducts, setNoActiveProducts] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [productImageStates, setProductImageStates] = useState({});
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [selectedProductForPublishing, setSelectedProductForPublishing] = useState(null);
    const [isSupplyInfoModalOpen, setIsSupplyInfoModalOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [productToDeleteOrders, setProductToDeleteOrders] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingExpire, setLoadingExpire] = useState(false);
    const [productMoreInfo, setProductMoreInfo] = useState(null);

    // Add these state variables
    const [selectedProduct, setSelectedProduct] = useState(null);

    // Functions to fetch data
    const fetchProducts = async () => {
        try {
            setActiveCardId(false);
            setLoading(true);

            let response;
            if (productTypes === 'qiwi' || productTypes === 'archived') {
                response = await merchantService.fetchUserProductsWithFilterAndPagination(currentPage, itemsPerPage, selectedStore, showArchived);
            } else {
                const formattedPageInfo = JSON.stringify(pageInfo);
                response = await merchantService.fetchAllShopifyProductsWithFilterAndPagination(selectedStore, itemsPerPage, formattedPageInfo, direction);

                const { next, previous, explored } = response.pageInfo;
                setPageInfo({ next, previous, explored });

                if (response.totalProductCount) {
                    setTotalProductCount(response.totalProductCount);
                }
            }
            if (initialLoad) {
                if (response.products.length === 0) {
                    setNoActiveProducts(true);
                } else {
                    setNoActiveProducts(false);
                }
                setInitialLoad(false);
            }
            setProducts(response.products);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch products', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStores = async () => {
        try {
            const storesResponse = await merchantService.getStores();
            setStores(storesResponse);
        } catch (error) {
            console.error("Error fetching stores: ", error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, itemsPerPage, productTypes, selectedStore, showArchived]);

    useEffect(() => {
        fetchStores();
    }, []);

    // Function to open more information modal
    const openMoreInformationModal = async (productId) => {
        try {
            const product = await merchantService.fetchProduct(productId);
            setProductMoreInfo(product);
            console.log('Fetched Product:', product);
            if (product.shippingPrices && product.shippingPrices.length > 0) {
                setSelectedCountry(product.shippingPrices[0].country);
            } else if (product.variants && product.variants.length > 0) {
                setSelectedCountry(product.variants[0].shippingPrices[0]?.country || null);
            }
            else {
                setSelectedCountry(null);
            }
            setIsSupplyInfoModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch product', error);
        }
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
    };

    // Handle product deletion
    const deleteProductModal = async (product) => {
        try {
            setLoadingModal(true);
            const orders = await merchantService.productDeleteStatus(product.id);
            setProductToDeleteOrders(orders.orders);
            setIsRemoveModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch product delete status:', error);
        } finally {
            setLoadingModal(false);
        }
    };

    const deleteProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to delete this product? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingRemove(true);
                await merchantService.deleteProduct(selectedProduct.id);
                alert("The product has been deleted.");
                fetchProducts();
                setIsRemoveModalOpen(false);
            } catch (error) {
                console.error('Failed to delete product:', error);
            } finally {
                setLoadingRemove(false);
            }
        }
    };

    const expireProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to set this product as expiring? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true);
                await merchantService.expireProduct(selectedProduct.id);
                alert("The product has been set as expiring.");
                fetchProducts();
                setIsRemoveModalOpen(false);
            } catch (error) {
                console.error('Failed to set product as expiring:', error);
            } finally {
                setLoadingExpire(false);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        if (productTypes === 'shopify') {
            if (newPage + 1 > currentPage) {
                setDirection("next");
            } else {
                setDirection("previous");
            }
        }
        setCurrentPage(newPage + 1);
    };

    const handleStoreChange = (event) => {
        setSelectedStore(event.target.value);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setCurrentPage(1);
    };

    const toggleCard = (id) => {
        setActiveCardId(prevId => {
            if (prevId === id) {
                return null;
            } else {
                setSelectedProduct(products.find(p => p.id === id) || null);
                setShowStorePublish(null);
                return id;
            }
        });
    };

    const togglePage = (value) => {
        setSelectedProduct(null);
        setActiveCardId(null);
        setProductTypes(value);
        setInitialLoad(true);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setCurrentPage(1);
        if (value === 'qiwi') {
            setShowArchived(false);
        } else if (value === 'archived') {
            setShowArchived(true);
        }
    };

    const getProductInfo = (product) => {
        let productInfoContent = [];

        if (product.supplier) {
            productInfoContent.push(<p key="supplier">Supplier: {product.supplier}</p>);
        }

        if (product.Stores && product.Stores.length > 0) {
            productInfoContent.push(
                <div key="stores" className={styles.storeInfo}>
                    Store: &nbsp;
                    <HoverableList list={
                        (product.Stores || []).map(store => {
                            const storeName = store.shop.replace('.myshopify.com', '');
                            return storeName + (store.expiring && !product.expiring ? ' (Expiring)' : '');
                        })
                    } wantRight={true}>
                    </HoverableList>
                </div>
            );
        }

        if (product.variants && product.variants.length > 0 && product.variants[0].variantTitle !== "Default Title") {
            productInfoContent.push(
                <div key="variants" className={styles.totalVar}>Total Variants:&nbsp; 
                    <HoverableList 
                        list={
                            product.variants.map(variant => 
                                variant.categoryVariants && variant.categoryVariants.length > 0 
                                ? variant.categoryVariants.map(cv => cv.value).join(' / ')
                                : variant.variantTitle
                            )
                        }
                    />
                </div>
            );
        }

        if (productTypes === 'qiwi') {
            return (
                <>
                    <div className={styles.productInfoContainer}>
                        {productInfoContent}
                        <button className={styles.supplyInfo} onClick={() => {
                            openMoreInformationModal(product.id);
                        }}>More Information</button>
                    </div>
                    {!product.expiring ?
                        <div className={styles.productButtons}>
                            <button onClick={() => deleteProductModal(product)}>Delete</button>
                            <button onClick={() => openPublishModal(product)}>Add to Store</button>
                        </div>
                        :
                        <div className={styles.expiringProduct}><span className="material-symbols-outlined">warning</span>The product is expiring</div>
                    }
                </>
            );
        }
        return (
            <div className={styles.productInfoContainer}>
                {productInfoContent}
            </div>
        );
    };

    const getImages = (product) => {
        const productState = productImageStates[product.id] || { index: 0, isPaused: false };
        const currentImageIndex = productState.index;

        if (product.noVariant && product.noVariant.image) {
            return <img src={product.noVariant.image} alt={product.name} className={styles.productImage} />;
        }

        const uniqueImages = new Set();
        const variantsWithImages = product.variants ? product.variants.filter(variant => {
            if (variant.image && !uniqueImages.has(variant.image)) {
                uniqueImages.add(variant.image);
                return true;
            }
            return false;
        }) : [];

        if (variantsWithImages.length === 0) {
            if (product.image) {
                return <img src={product.image} alt={product.name} className={styles.productImage} />;
            }
            return <img src={productPlaceHolder} alt="No image available" className={styles.productImage} />;
        }

        if (variantsWithImages.length === 1) {
            return <img src={variantsWithImages[0].image} alt={product.name} className={styles.productImage} />;
        }

        const currentImage = variantsWithImages[currentImageIndex] ? variantsWithImages[currentImageIndex].image : variantsWithImages[0].image;

        return (
            <div className={styles.imageCarousel}>
                <img src={currentImage} alt={`Variant ${currentImageIndex}`} className={styles.productImage} />
                <button onClick={() => handlePrevImage(product.id, variantsWithImages)} className={styles.prevButton}>{"<"}</button>
                <button onClick={() => handleNextImage(product.id, variantsWithImages)} className={styles.nextButton}>{">"}</button>
            </div>
        );
    };

    const openPublishModal = async (product) => {
        try {
            const detailedProduct = await merchantService.fetchProduct(product.id);
            setSelectedProductForPublishing(detailedProduct);
            setIsPublishModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch detailed product:', error);
        }
    };
    

    // Function to go to the next image
    const handleNextImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;

        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex + 1) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };

    // Function to go to the previous image
    const handlePrevImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;

        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex - 1 + variantsWithImages.length) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.productOption}>
                <a onClick={() => togglePage('qiwi')} className={productTypes === 'qiwi' ? styles.active : ''}>
                    Qiwi Products
                </a>
                <a onClick={() => togglePage('shopify')} className={productTypes === 'shopify' ? styles.active : ''}>
                    Shopify Products
                </a>
                <a onClick={() => togglePage('archived')} className={productTypes === 'archived' ? styles.active : ''}>
                    Archived Qiwi Products
                </a>
                <div className={styles.storeSelect}>
                    <FormControl variant="outlined" className={styles.storeSelect} size="small" disabled={noActiveProducts}>
                        <InputLabel>Store</InputLabel>
                        <Select value={selectedStore} onChange={handleStoreChange} label="Store">
                            <MenuItem value={0}>All</MenuItem>
                            {stores.map((store) => (
                                <MenuItem key={store.id} value={store.id}>
                                    {store.shop.replace('.myshopify.com', '')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>

            {loading ? 
                <div className={styles.placeHolderContainer}>
                    {Array.from({ length: itemsPerPage }).map((_, index) => (
                        <div key={index} className={`${styles.shimmer} ${styles.cardContainer}`}>
                        </div>
                    ))}
                </div>
                :  
                !products.length > 0 ? (
                    <p className={styles.noActiveProducts}>
                        No {productTypes === 'qiwi' ? "active" : productTypes === 'archived' ? "archived" : "shopify"} products found {!noActiveProducts && ", please check your filters"}
                    </p>
                ) : (
                    <>
                        <div className={styles.gridContainer}>
                            {products.map((product, index) => (
                                <div key={product.id} className={`${styles.cardContainer} ${activeCardId === product.id ? styles.activeCard : ''}`}>
                                    <div className={styles.card}>
                                        <div className={styles.imgContainer}>
                                            {getImages(product)}
                                        </div>
                                    </div>
                                    <div className={`${styles.cardContent} ${showStorePublish === product.id ? styles.storePublishcontainer : ''}`}>
                                        <div className={styles.contentHeader}>
                                            <h2>{product.name}</h2>
                                            {activeCardId === product.id ? (
                                                <span className="material-symbols-outlined" onClick={() => toggleCard(null)}>
                                                    keyboard_arrow_down
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined" onClick={() => toggleCard(product.id)}>
                                                    keyboard_arrow_up
                                                </span>
                                            )}
                                        </div>
                                        {getProductInfo(product)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {productTypes === 'qiwi' || productTypes === 'archived' ? (
                            <PaginationComponent
                                itemsPerPage={itemsPerPage}
                                setItemsPerPage={setItemsPerPage}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handlePageChange={handleChangePage}
                            />
                        ) : (
                            <div className={styles.pags}>
                                <TablePagination
                                    component="div"
                                    count={totalProductCount}
                                    page={currentPage - 1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={itemsPerPage}
                                    onRowsPerPageChange={() => {}} // Placeholder
                                    rowsPerPageOptions={[]}
                                    labelRowsPerPage="" 
                                    disabled={itemsPerPage === 10 && totalPages === 1}
                                />
                            </div>
                        )}
                    </>
                )
            }

            {isPublishModalOpen &&
                <PublishModal
                    isOpen={isPublishModalOpen}
                    onRequestClose={() => setIsPublishModalOpen(false)}
                    stores={stores}
                    selectedProduct={selectedProductForPublishing}
                    fetchProducts={fetchProducts}
                    merchantService={merchantService}
                />
            }


            {isSupplyInfoModalOpen &&
                <Modal
                    isOpen={isSupplyInfoModalOpen}
                    onRequestClose={() => setIsSupplyInfoModalOpen(false)}
                    contentLabel="Supply Information"
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                >
                    <h2>Supply Information</h2>
                    {productMoreInfo && (
                        <div className={styles.supplyInfoContent}>
                            {/* If the product does not have variants */}
                            {(!productMoreInfo.variants || productMoreInfo.variants.length === 0) ? (
                                <table>
                                    <thead>
                                        <tr className={styles.table_header}>
                                            <th>SKU</th>
                                            <th>Product Price</th>
                                            <th className={styles.dynamicShippingTH}>
                                                Shipping Price
                                                <React_Select
                                                    size="small"
                                                    value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                                    onChange={handleCountryChange}
                                                    options={Array.from(new Set(
                                                        (productMoreInfo.shippingPrices || []).map(sp => sp.country)
                                                    )).map(country => ({ value: country, label: country }))}
                                                    className={styles.countrySelect}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={styles.rows}>
                                            <td>{productMoreInfo.SKU}</td>
                                            <td>€{productMoreInfo.productPrice}</td>
                                            <td>
                                                €{productMoreInfo.shippingPrices.find(sp => sp.country === selectedCountry)?.shippingPrice || 'N/A'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <table>
                                    <thead>
                                        <tr className={styles.table_header}>
                                            <th>SKU</th>
                                            <th>Product Price</th>
                                            <th className={styles.dynamicShippingTH}>
                                                Shipping Price
                                                <React_Select
                                                    size="small"
                                                    value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                                    onChange={handleCountryChange}
                                                    options={Array.from(new Set(
                                                        productMoreInfo.variants.flatMap(variant => variant.shippingPrices.map(sp => sp.country))
                                                    )).map(country => ({ value: country, label: country }))}
                                                    className={styles.countrySelect}
                                                />
                                            </th>
                                            {Array.from(new Set(productMoreInfo.variants.flatMap(variant =>
                                                variant.categoryVariants.map(cv => cv.category)
                                            ))).map((category, idx) => (
                                                <th key={idx}>{category}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productMoreInfo.variants.map((variant, index) => (
                                            <tr key={index} className={styles.rows}>
                                                <td>{variant.SKU}</td>
                                                <td>€{variant.productPrice}</td>
                                                <td>
                                                    €{variant.shippingPrices.find(vsp => vsp.country === selectedCountry)?.shippingPrice || 'N/A'}
                                                </td>
                                                {Array.from(new Set(productMoreInfo.variants.flatMap(variant =>
                                                    variant.categoryVariants.map(cv => cv.category)
                                                ))).map((category, idx) => {
                                                    const quotationCategoryVariant = variant.categoryVariants.find(cv => cv.category === category);
                                                    return <td key={idx}>{quotationCategoryVariant ? quotationCategoryVariant.value : ''}</td>;
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <button onClick={() => setIsSupplyInfoModalOpen(false)} className={styles.closeButton}>Close</button>
                        </div>
                    )}
                </Modal>
            }

            {isRemoveModalOpen &&
                <Modal
                    isOpen={isRemoveModalOpen}
                    onRequestClose={() => setIsRemoveModalOpen(false)}
                    contentLabel="Delete Product"
                    className={styles.modaltwo}
                    overlayClassName={styles.overlay}
                >
                    <h2>Deleting Product{selectedProduct && `: ${selectedProduct.name}`}</h2>
                    {loadingModal ? (
                        <div className={styles.spinnerModal}><LoadingSpinner /></div> 
                    ) : productToDeleteOrders.length > 0 ? (
                        <>
                            <div className={styles.warningMessage}>
                                <span className="material-symbols-outlined">warning</span>
                                <p><strong>WARNING: There are existing orders awaiting for this product. Your product will be set as expiring until it is fulfilled.</strong></p>
                            </div>
                            <p>When you delete this product, it will not be automatically removed from your Shopify store. Make sure to manually set it to "Draft" or remove it from your store.</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productToDeleteOrders.map(order => (
                                        <tr key={order.orderId}>
                                            <td>{order.orderId}</td>
                                            <td>{order.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={styles.buttons}>
                                <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
                                <button 
                                    className={styles.expiringButton} 
                                    onClick={expireProduct} 
                                    disabled={loadingExpire}
                                >
                                    {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p><strong>Are you sure you want to delete this product? This action cannot be undone.</strong></p>
                            <p>When you delete this product, it will not be automatically removed from your Shopify store. Make sure to manually set it to "Draft" or remove it from your store.</p>
                            <div className={styles.buttons}>
                                <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
                                <button 
                                    className={styles.deleteButtonModal} 
                                    onClick={deleteProduct} 
                                    disabled={loadingRemove}
                                >
                                    {loadingRemove ? <LoadingButtonDots /> : 'Yes, Delete Product'}
                                </button>
                            </div>
                        </>
                    )}
                </Modal>
            }
        </div>
    );

}

export default ProductManagement;
