import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Requests.module.scss';
import Modal from 'react-modal';
import Tooltip from "@mui/material/Tooltip";
import HoverableOverflowText from '../../components/HoverableOverflowText';

const SourcingRequests = () => {
    // För att säkerställa att modalen är korrekt uppsatt för tillgänglighet
    useEffect(() => {
        document.title = 'Sourcing Requests | Qiwi';
    }, []);

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("Pending");
    const [dateRange, setDateRange] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
    const [quotationsData, setQuotationsData] = useState([]);
    const [currentQuotationIndex, setCurrentQuotationIndex] = useState(0);
    const [showArchived, setShowArchived] = useState(false);
    const [supplierCount, setSupplierCount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]); // Om du vill ha flera expanderade rader

    const navigate = useNavigate();

    // Status options för filter
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Responded', label: 'Responded' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'In Products', label: 'In Products' },
        { value: 'In Store', label: 'In Store' },
    ];

    // Funktion för att styla datum baserat på status och tid
    const getTimeDifferenceClass = (createdAt, status) => {
        if (status !== 'Pending') return ''; // Endast för 'Pending'

        const now = new Date();
        const createdDate = new Date(createdAt);
        const timeDifferenceInHours = (now - createdDate) / (1000 * 60 * 60); // Milliseconds to hours

        if (timeDifferenceInHours > 48) {
            return styles.red; // Röd
        } else if (timeDifferenceInHours > 24) {
            return styles.orange; // Orange
        } else {
            return styles.green; // Grön
        }
    };

    // Funktion för att toggla expansion av en rad
    const toggleRow = (requestId) => {
        if (expandedRows.includes(requestId)) {
            setExpandedRows(expandedRows.filter(id => id !== requestId));
        } else {
            setExpandedRows([...expandedRows, requestId]);
        }
    };

    // Funktion för att hantera visning av bilder
    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    // Funktion för att hantera sortering
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    // Funktion för att hämta data baserat på filtrering och paginering
    const fetchRequests = async () => {
        try {
            setLoading(true);
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchRequestsByPageAndFilters(
                statusFilter,
                currentPage,
                itemsPerPage,
                searchQuery,
                startDate,
                endDate,
                showArchived
            );
            setData(response.rows || []);
            setTotalPages(response.totalPages);
            setSupplierCount(response.supplierCount);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
            setTotalPages(1);
            setLoading(false);
        }
    };

    // Funktion för att hantera sidändring
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Funktion för att hantera filterändringar
    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1); // Återställ till första sidan vid filterändring
    };

    // Funktion för att öppna quotations
    const handleViewQuotations = (requestId) => {
        navigate(`/admin/sourcing-requests/quotation-details/${requestId}`);
    };

    // Funktion för att öppna quotation details modal
    const openQuotationListDetails = async (request) => {
        try {
            const quotations = await adminService.fetchQuotationDetails(request.id, showArchived);
            setQuotationsData(quotations.quotations);
            setIsQuotationModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    // Funktion för att arkivera en request
    const archiveRequest = async (requestId) => {
        const confirmed = window.confirm('Are you sure you want to archive this request? This action cannot be undone.');

        if (confirmed) {
            try {
                const response = await adminService.archiveRequest(requestId);
                if (response) {
                    fetchRequests();
                }
            } catch (error) {
                console.error('Failed to archive request:', error);
            }
        }
    };

    // Funktion för att stänga modalen
    const closeModal = () => {
        setIsQuotationModalOpen(false);
        setCurrentQuotationIndex(0);
    };

    // Funktion för att hantera att toggla arkiverade rader
    const handleShowArchivedToggle = (value) => {
        setShowArchived(value);
        setCurrentPage(1);
    };

    // Funktioner för navigering i quotations modal
    const handlePrevious = () => {
        if (currentQuotationIndex > 0) {
            setCurrentQuotationIndex(currentQuotationIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentQuotationIndex < quotationsData.length - 1) {
            setCurrentQuotationIndex(currentQuotationIndex + 1);
        }
    };

    // Effekt för att hämta data när komponenten mountas eller när beroenden ändras
    useEffect(() => {
        fetchRequests();
    }, [currentPage, itemsPerPage]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Requests</h1>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.filterHeader}>
                    <FilterComponent
                        statusFilter={statusFilter}
                        setSelectFilter={handleFilterChange}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        statusOptions={statusOptions}
                        searchPlaceholder="Search by product name..."
                        includeSearch={true}
                        selectLabel="Status"
                        showArchived={showArchived}
                        setShowArchived={handleShowArchivedToggle}
                        applyFilters={fetchRequests}
                    />
                </div>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                        <tr>
                            <th className={styles.moreInfo}></th>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('productName')}>
                                <div className={styles.thHeader}>
                                    Product
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'productName' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'productName' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Merchant</th>
                            <th>Status</th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Created At
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Quotations</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            Array.from({ length: 5 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                    <td colSpan={8}></td>
                                </tr>
                            ))
                        ) : !data.length ? (
                            <tr className={styles.rows}>
                                <td colSpan={8}>
                                    <p className={styles.noFound}>
                                        No {showArchived ? 'archived' : 'active'} requests found{statusFilter !== 'all' && ", check filtering"}
                                    </p>
                                </td>
                            </tr>
                        ) : (
                            data.map(request => (
                                <React.Fragment key={request.id}>
                                    <tr className={`${styles.rows} ${expandedRows.includes(request.id) ? styles.activeRow : ''}`}>
                                        <td className={styles.moreInfo} onClick={() => toggleRow(request.id)}>
                                            <span className="material-symbols-outlined">
                                                {expandedRows.includes(request.id) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                            </span>
                                        </td>
                                        <td>{request.id}</td>
                                        <td>
                                            <div className={styles.productInfo}>
                                            {request.image && (
                                                <img
                        
                                                    src={`/uploads/productImages/${request.image}`}
                                                    alt={request.productName}
                                                    className={styles.productImage}
                                                    onClick={() => viewImage(`/uploads/productImages/${request.image}`)}
                                                />
                                            )}
                                            <HoverableOverflowText text={request.productName} fontSize={14}/>
                                            </div>
                                        </td>
                                        <td>{request.merchantName}</td>
                                        <td>{request.status}</td>
                                        <td className={`${getTimeDifferenceClass(request.createdAt, request.status)}`}>
                                            {new Date(request.createdAt).toLocaleString('sv-SE', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            })}
                                        </td>
                                        <td>
                                            <div className={styles.quotationNum}>
                                            <button
                                                disabled={!request.numberResponses > 0}
                                                onClick={() =>  openQuotationListDetails(request)}
                                                className={styles.viewQuotations}
                                            >
                                                {request.numberResponses}
                                            </button>
                                            <span>+</span>
                                            <Tooltip
                                                disableHoverListener={!request.deniedSuppliers.length > 0}
                                                title={
                                                    <div style={{ fontSize: "15px" }}>
                                                        {request.deniedSuppliers.map((supplier, index) => (
                                                            <div key={index}>{supplier}</div>
                                                        ))}
                                                    </div>
                                                }
                                            >
                                                <span className={styles.deniedReq}>{request.deniedSuppliers.length}</span>
                                            </Tooltip>
                                            <span>=</span>
                                            <span
                                                className={
                                                    request.numberResponses + request.deniedSuppliers.length === supplierCount
                                                        ? styles.green
                                                        : ''
                                                }
                                            >
                                                {request.numberResponses + request.deniedSuppliers.length}/{supplierCount}
                                            </span>
                                            </div>
                                        </td>
                                        <td>
                                            {request.status === 'Pending' && !showArchived &&
                                                <button onClick={() => handleViewQuotations(request.id)} className={styles.sendQuotations}>Show/Send</button>
                                            }
                                        </td>
                                    </tr>

                                    {expandedRows.includes(request.id) && (
                                        <tr className={styles.expandedRow}>
                                            <td colSpan="8">
                                                <div className={styles.expandedContent}>
                                                    <div className={styles.expandedSection}>
                                                        <strong>Note:</strong>
                                                        <p>{request.note || 'N/A'}</p>
                                                    </div>
                                                    <div className={styles.expandedSection}>
                                                        <strong>URL:</strong>
                                                        <p>
                                                            {request.url ? (
                                                                <a href={request.url} target="_blank" rel="noopener noreferrer">
                                                                    {request.url}
                                                                </a>
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className={styles.expandedSection}>
                                                        <strong>Description:</strong>
                                                        <p>{request.description || 'N/A'}</p>
                                                    </div>
                                                    <strong>Countries:</strong>
                                                    <div className={styles.expandedCountries}>
                                                        {request.SourcingCountries && request.SourcingCountries.length > 0 ? (
                                                            request.SourcingCountries.map((country, index) => (
                                                                <p key={index}>{country.country}</p>
                                                            ))
                                                        ) : (
                                                            <p>N/A</p>
                                                        )}
                                                        </div>
                                                </div>

                                                <div className={styles.moreInfoList}>
                                                        <strong>Variants:</strong>
                                                        <div className={styles.variantsContainer}>
                                                            {request.SourcingRequestCategories.map((category) => (
                                                            <div key={category.id} className={styles.variantCategory}>
                                                                <strong>{category.name}:</strong>{" "}
                                                                {category.SourcingRequestCategoryValues.map((value) => value.value).join(", ")}
                                                            </div>
                                                            ))}
                                                        </div>
                                                        </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        )}
                        {/* Pagination rad */}
                        <tr className={styles.rows}>
                            <td colSpan={8}>
                                {data.length > 0 && (
                                    <PaginationComponent
                                        itemsPerPage={itemsPerPage}
                                        setItemsPerPage={setItemsPerPage}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        handlePageChange={handlePageChange}
                                    />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected Request Image" className={styles.largeImage} />
            </Modal>

            <Modal
                isOpen={isQuotationModalOpen}
                onRequestClose={closeModal}
                contentLabel="Quotations"
                className={styles.quotationModal}
                overlayClassName={styles.overlay}
            >
                {currentQuotationIndex > 0 && (
                    <button 
                        onClick={handlePrevious} 
                        className={styles.navButtonOne}
                    >
                        &lt;
                    </button>
                )}
                <div className={styles.quotationsModalContainer}>
                    {quotationsData && isQuotationModalOpen && (
                        <>
                        <div>
                            <h2>Quotation by {quotationsData[currentQuotationIndex].supplierName}</h2>
                            <p className={styles.qutStatus}><strong>Status: </strong>{quotationsData[currentQuotationIndex].status}</p>

                            </div>
                            {quotationsData[currentQuotationIndex]?.variants && quotationsData[currentQuotationIndex].variants.length > 0 ? (
                                <>
                                    {quotationsData[currentQuotationIndex].variants.map((variant, index) => (
                                        <div key={index} className={styles.variantModalItem}>
                                            <div className={styles.variantMainInfo}>
                                                <div className={styles.imageContainer}>
                                                    {variant.image && (
                                                        <img
                                                            src={`/uploads/productImages/${variant.image}`}
                                                            alt={`Variant ${index}`}
                                                            className={styles.quotationImage}
                                                        />
                                                    )}
                                                </div>
                                                <div className={styles.detailsContainer}>
                                                    <p><strong>SKU:</strong> {variant.SKU}</p>
                                                    <p><strong>Product Price:</strong> €{variant.productPrice}</p>
                                                    <p><strong>Product Profit:</strong> €{variant.productProfit}</p>
                                                    {/* Visa varje kategorivariant */}
                                                    {variant.categoryVariants.map((categoryVariant, catIndex) => (
                                                        <p key={catIndex}>
                                                            <strong>{categoryVariant.quotationCategory}:</strong> {categoryVariant.value}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <h3>Shipping Data</h3>
                                            <div className={styles.shippingDataContainer}>
                                                {variant.shippingPrices.map((shipping, shippingIndex) => (
                                                    <div key={shippingIndex} className={styles.shippingData}>
                                                        <h4>{shipping.country}</h4>
                                                        <p><strong>Shipping Price:</strong> €{shipping.shippingPrice}</p>
                                                        <p><strong>Shipping Profit:</strong> €{shipping.shippingProfit}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                quotationsData[currentQuotationIndex]?.noVariant && (
                                    <div className={styles.variantModalItem}>
                                        <div className={styles.variantMainInfo}>
                                            <div className={styles.imageContainer}>
                                                {quotationsData[currentQuotationIndex].noVariant.image && (
                                                    <img
                                                        src={`/uploads/productImages/${quotationsData[currentQuotationIndex].noVariant.image}`}
                                                        alt={`Product`}
                                                        className={styles.quotationImage}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.detailsContainer}>
                                                <p><strong>SKU:</strong> {quotationsData[currentQuotationIndex].noVariant.SKU}</p>
                                                <p><strong>Product Price:</strong> €{quotationsData[currentQuotationIndex].noVariant.productPrice}</p>
                                                <p><strong>Product Profit:</strong> €{quotationsData[currentQuotationIndex].noVariant.productProfit}</p>
                                            </div>
                                        </div>
                                        <h3>Shipping Data</h3>
                                        <div className={styles.shippingDataContainer}>
                                            {quotationsData[currentQuotationIndex].noVariant.shippingPrices.map((shipping, shippingIndex) => (
                                                <div key={shippingIndex} className={styles.shippingData}>
                                                    <h4>{shipping.country}</h4>
                                                    <p><strong>Shipping Price:</strong> €{shipping.shippingPrice}</p>
                                                    <p><strong>Shipping Profit:</strong> €{shipping.shippingProfit}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    )}
                </div>
                {currentQuotationIndex < quotationsData.length - 1 && (
                    <button 
                        onClick={handleNext} 
                        className={styles.navButtonTwo}
                    >
                        &gt;
                    </button>
                )}
            </Modal>
        </div>
    );
};

export default SourcingRequests;
