import React from 'react';
import styles from './PublishTutorial.module.scss';
import step1 from '../../images/step1.png';
import step2 from '../../images/step2.png';
import step3 from '../../images/step3.png';
import step4_1 from '../../images/step4.png';
import step4_2 from '../../images/step4.2.png';

const PublishTutorial = () => {
  return (
    <div className={styles.publishTutorial}>
      <h1>Publishing Guide for Shopify</h1>

      <div className={styles.step}>
        <h2>Step 1: Locate the Product</h2>
        <p>
          In your Shopify Admin Dashboard, go to <strong>Products</strong>. Find the existing product you want to connect and click on the product's name to open it.
        </p>
        <img
          src={step1}
          alt="Step 1 - Locate the Product"
          className={`${styles.image} ${styles.fullWidthImage}`}
        />
      </div>

      <div className={styles.step}>
        <h2>Step 2: Get the Product's Shopify ID</h2>
        <p>
          When viewing the product, take note of the last set of numbers in the URL. For example, in this URL: <br />
          <code>https://admin.shopify.com/store/qiwi-demo-store/products/9792650051924</code>, 
          the product ID is <strong>9792650051924</strong>. This is Shopify Order ID you should input in the modal.
        </p>
        <img
          src={step2}
          alt="Step 2 - Get the Product ID"
          className={styles.image}
        />
      </div>

      <div className={styles.step}>
        <h2>Step 3: (If the product has variants) Get the Variant's Shopify ID</h2>
        <p>
          Navigate to the specific variant and take note of the last set of numbers in the variant URL. For example, in this URL: <br />
          <code>https://admin.shopify.com/store/qiwi-demo-store/products/9792650051924/variants/50000157737300</code>, 
          the variant ID is <strong>50000157737300</strong>. This is the Shopify Variant ID you should input in the modal, make sure the ID corresponds correctly to the variant.
        </p>
        <img
          src={step3}
          alt="Step 3 - Get the Variant ID"
          className={styles.image}
        />
      </div>

      <div className={styles.step}>
        <h2>Step 4: Verify Inventory Settings</h2>
        <p>
          Double-check that the inventory is set to the '[Supplier] Fulfillment Service' on the Shopify product page. If its not changed, make sure you change it to the correct service belonging to the correct supplier. OBS you will need a SKU, preferably take the one you find in the product "More Information" section in the qiwi app.
          Also, ensure that the product appears in the store under the Qiwi Fulfill app.
        </p>
        <img
          src={step4_1}
          alt="Step 4 - Verify Inventory Settings Part 1"
          className={`${styles.image} ${styles.fullWidthImage}`}
        />
        <img
          src={step4_2}
          alt="Step 4 - Verify Inventory Settings Part 2"
          className={`${styles.image} ${styles.fullWidthImage}`}
        />
      </div>
    </div>
  );
};

export default PublishTutorial;
