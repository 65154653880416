import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { addDays } from 'date-fns';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Orders.module.scss';
import GridInfoBox from '../../components/GridInfoBox';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [visibleItems, setVisibleItems] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [statusFilter, setStatusFilter] = useState("all");
    const [dateRange, setDateRange] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [selectedStore, setSelectedStore] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [hoveredOrderId, setHoveredOrderId] = useState(null);

    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Customer Payment', label: 'Awaiting Customer Payment' },
        { value: 'Awaiting Payment', label: 'Awaiting Payment' },
        { value: 'Awaiting Payment Confirmation', label: 'Awaiting Payment Confirmation' },
        { value: 'Awaiting Fulfillment', label: 'Awaiting Fulfillment' },
        { value: 'Partially Fulfilled', label: 'Partially Fulfilled' },
        { value: 'Fulfilled', label: 'Fulfilled' },
        { value: 'Cancel Requested', label: 'Cancel Requested'},
        { value: 'Cancelled', label: 'Cancelled' },
    ];
    
    useEffect(() => {
        fetchOrders();
    }, [currentPage, itemsPerPage]);

    const fetchOrders = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const data = await adminService.fetchAllOrdersByPageAndFilters(statusFilter, currentPage, itemsPerPage, searchQuery, startDate, endDate, showArchived);
            setOrders(data.orders || []);
            setTotalPages(data.totalPages);

            if (initialLoad) {
                if (!data.orders.length) {
                    setFiltersApplied(false);
                } else {
                    setFiltersApplied(true);
                }
                setInitialLoad(false);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setOrders([]);
        }
    };

    const fetchMerchants = async () => {
        try {
            const merchants = await adminService.fetchMerchants();
            setMerchants(merchants);
        } catch (error) {
            console.error('Failed to fetch merchants:', error);
        }
    };
    
    const fetchStores = async (merchantId) => {
        try {
            const stores = await adminService.fetchMerchantStores(merchantId);
            setStores(stores);
        } catch (error) {
            console.error('Failed to fetch stores:', error);
        }
    };

    useEffect(() => {
        if (selectedMerchant) {
            fetchStores(selectedMerchant);
        } else {
            setStores([]);
        }
    }, [selectedMerchant]);

    const handleSyncClick = async () => {
        setIsModalOpen(true);
        await fetchMerchants(); // Fetch merchants when modal is opened
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMerchant(null);
        setSelectedStore(null);
    };

    const handleMerchantChange = (event) => {
        setSelectedMerchant(event.target.value);
        setSelectedStore(null); // Reset store if a merchant is selected
    };

    const handleStoreChange = (event) => {
        setSelectedStore(event.target.value);
    };

    const colorMap = ['#318b00', '#1a4b00'];

        // Generera färgmap för varje orderGroupId
        const mergeOrderColorMap = {};
        let colorIndex = 0;
        
        // Först, skapa en mappning för att räkna antalet ordrar per orderGroupId
        const orderGroupCountMap = {};
        
        // Räkna ordrarna per orderGroupId
        orders.forEach(order => {
            if (order.orderGroupId) {
                if (!orderGroupCountMap[order.orderGroupId]) {
                    orderGroupCountMap[order.orderGroupId] = 0;
                }
                orderGroupCountMap[order.orderGroupId]++;
            }
        });
        
        // Lägg bara till i färgkartan om det finns mer än en order med samma orderGroupId
        orders.forEach(order => {
            if (order.orderGroupId && orderGroupCountMap[order.orderGroupId] > 1 && !mergeOrderColorMap[order.orderGroupId]) {
                mergeOrderColorMap[order.orderGroupId] = colorMap[colorIndex % colorMap.length];
                colorIndex++;
            }
        });

    const handleSyncSubmit = async () => {
            
        if (!selectedMerchant) {
            alert('Please select a merchant.');
            return;
        }
        
        if (selectedStore === '' && stores.length > 0) {
            alert('Please select a store or choose "All Stores".');
            return;
        }
    
        try {
            const response = await adminService.syncShopifyOrders(selectedStore, selectedMerchant);
            alert(response.message)
            fetchOrders();
            closeModal();
        } catch (error) {
            console.error('Failed to sync Shopify orders:', error);
        }
    };

    const toggleItemDetails = async (orderId) => {
        if (visibleItems[orderId]) {
            setVisibleItems(prev => ({
                ...prev,
                [orderId]: false
            }));
        } else {
            setVisibleItems(prev => ({
                ...prev,
                [orderId]: 'loading'
            }));
            try {
                const items = await adminService.fetchOrderItems(orderId, showArchived);
                setOrders(prevOrders => prevOrders.map(order =>
                    order.orderId === orderId ? { ...order, items } : order
                ));
            } catch (error) {
                console.error('Failed to fetch order items:', error);
            } finally {
                setVisibleItems(prev => ({
                    ...prev,
                    [orderId]: true
                }));
            }
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...orders].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setOrders(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (filter) => {
        setStatusFilter(filter);
        setCurrentPage(1); // Reset to first page when filter changes
    };


    const handleShowArchivedToggle = (value) => {

        setShowArchived(value);
        setCurrentPage(1);
    };

    const applyFilters = () => {
        setFiltersApplied(true);
        fetchOrders();
    };

    const cancelItem = async (itemId, orderId) => {
        // Visa en bekräftelseruta
        const confirmed = window.confirm(`Are you sure you want to cancel the item?`);
        
        if (!confirmed) {
            // Om användaren klickar på "Cancel", avbryt funktionen
            return;
        }
    
        try {
            // Om användaren bekräftar, fortsätt med borttagningen
            await adminService.cancelItem(itemId);
            
            // Hämta uppdaterade order items
            const items = await adminService.fetchOrderItems(orderId, showArchived);
            
            // Uppdatera ordern med de nya items
            setOrders(prevOrders => prevOrders.map(order =>
                order.orderId === orderId ? { ...order, items } : order
            ));
        } catch (error) {
            console.error('Failed to remove item:', error);
        }
    };

    const cancelOrder = async (orderId) => {
        const confirmed = window.confirm(`Are you sure you want to remove the item?`);
        if (!confirmed) {
            return;
        }
    
        try {
            await adminService.cancelOrder(orderId);
            fetchOrders();
        } catch (error) {
            console.error('Failed to remove order:', error);
        }
    };
    
    

    const createDetailRow = (order) => {
        return (
            <td colSpan="9"
            >
                <table>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>SKU</th>
                            <th>Shopify ID</th>
                            <th>Variant</th>
                            <th>Shopify Variant ID</th>
                            <th colSpan={2}>Fulfilled</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.items.map((item, index) => (
                            <tr key={`item-${order.orderId}-${index}`}>
                                <td>{item.productName}</td>
                                <td>{item.quantity}</td>
                                <td>{item.SKU}</td>
                                <td>{item.storeProductShopifyId}</td>
                                <td>
                                    {item.Variant && item.Variant.categories.length > 0 ? (
                                        item.Variant.categories.map((cv, catIndex) => (
                                            <span key={catIndex}>
                                                {`${cv.name}: ${cv.value}`}
                                                {catIndex !== item.Variant.categories.length - 1 && ', '}
                                            </span>
                                        ))
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>{item.shopifyVariantId}</td>
                                <td colSpan={2}>{item.isFulfilled ? (
                                            <div className={styles.checkedCircle}>
                                                <span className="material-symbols-outlined">
                                                    check_circle
                                                </span>
                                            </div>
                                        ) : (
                                            <div className={styles.xCircle}>
                                                <span className="material-symbols-outlined">
                                                    cancel
                                                </span>
                                                {order.status === "Awaiting Payment" && "Awaiting Payment"}
                                            </div>
                                        )}</td>
                                <td>{!item.isFulfilled && order.items.length > 1 &&
                                    <button onClick={() => cancelItem(item.id, order.id)} className={styles.cancelButton}>Cancel item</button>
                                }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Orders</h1>
            </div>
            <button className={styles.loadShopifyButton} onClick={handleSyncClick}>
                    <span className="material-symbols-outlined">sync</span>Sync Shopify Orders
                </button>
                    <div className={styles.tableContainer}>
                    <div className={styles.filterHeader}>
                        <FilterComponent
                            statusFilter={statusFilter}
                            setSelectFilter={handleFilterChange}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            statusOptions={statusOptions}
                            searchPlaceholder="Search by Shopify Name..."
                            applyFilters={applyFilters}
                            includeSearch={showArchived ? false : true}
                            selectLabel="Status"
                            showArchived={showArchived}
                            setShowArchived={handleShowArchivedToggle}
                        />
                    </div>
                        <table className={styles.table}>
                            <thead className={styles.mainThead}>
                                <tr>
                                    <th onClick={() => handleSort('orderId')}>
                                        <div className={styles.thHeader}>
                                            Order ID
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Merchant</th>
                                    <th>Store</th>
                                    <th>Items</th>
                                    <th onClick={() => handleSort('shopifyId')}>
                                        <div className={styles.thHeader}>
                                            Shopify ID
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'shopifyOrderId' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'shopifyOrderId' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('status')}>
                                        <div className={styles.thHeader}>
                                            Status
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        Customer Data
                                    </th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Date
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {orders.map(order => (
                                        <React.Fragment key={order.orderId}>
                                            <tr
                                                className={visibleItems[order.orderId] ? styles.droppeddown : styles.rows}
                                                style={{ boxShadow: mergeOrderColorMap[order.orderGroupId] ? `inset 10px 0 0 0 ${mergeOrderColorMap[order.orderGroupId]}` : 'none' }}
                                                >
                                                <td
                                                >{order.orderId}</td>
                                                <td>{order.merchantName}</td>
                                                <td>{order.store.shop.replace('.myshopify.com', '')}</td>
                                                <td>
                                                    <button
                                                        className={styles.toggleRowsButton}
                                                        onClick={() => toggleItemDetails(order.orderId)}
                                                    >
                                                        {order.itemsCount}
                                                        <span className="material-symbols-outlined">
                                                            {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                        </span>
                                                    </button>
                                                </td>
                                                <td>
                                                    {order.shopifyOrderId}
                                                </td>
                                                <td> 
                                                        {order.status}
                                                </td>

                                                <td
                                                    className={styles.hoverable}
                                                > 
                                                        <GridInfoBox grid={order.customerData}>{order.customerData.fullName}</GridInfoBox>                                                    
                                                </td>

                                                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                                <td>
                                                    {
                                                        (
                                                        order.status === 'Awaiting Payment') &&
                                                        !order.isArchived
                                                     ?
                                                            <button onClick={() => cancelOrder(order.orderId)} className={styles.cancelButton}>
                                                                Cancel
                                                            </button>
                                                            :
                                                            <button onClick={() => console.log("D")} className={styles.archiveButton}>
                                                                Delete
                                                            </button>
                                                    }
                                                </td>
                                            </tr>
                                            {visibleItems[order.orderId] === 'loading' ? (
                                                <tr className={styles.detailRow} key={`details-${order.orderId}`}
                                                >
                                                    <td colSpan={10} >
                                                        <table>
                                                            <tbody>
                                                                {Array.from({ length: order.itemsCount + 1 }).map((_, index) => (
                                                                    <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                                                                        <td colSpan={10}></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ) : visibleItems[order.orderId] && order.items && (
                                                <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                                                    {createDetailRow(order)}
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    <tr className={styles.rows}>
                                        <td colSpan={9}>
                                        <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                                        </td>
                                    </tr>

                            </tbody>
                        </table>
                    </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Sync Shopify Orders"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h2>Sync Shopify Orders</h2>
                <div className={styles.storeAndMerchantSelect}>
                <FormControl fullWidth className={styles.formControl}>
                    <InputLabel>Merchant</InputLabel>
                    <Select
                    value={selectedMerchant}
                    onChange={handleMerchantChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {merchants.map(merchant => ( 
                        <MenuItem key={merchant.id} value={merchant.id}>
                            {merchant.name}
                        </MenuItem>
                    ))}
                </Select>

                </FormControl>

                <FormControl fullWidth className={styles.formControl}>
                    <InputLabel>Store</InputLabel>
                    <Select
                        value={selectedStore}
                        onChange={handleStoreChange}
                        disabled={!selectedMerchant}
                    >
                        <MenuItem value="">
                            <em>All Stores</em>
                        </MenuItem>
                        {stores.map(store => (
                            <MenuItem key={store.id} value={store.id}>
                                {store.shop}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </div>

                <div className={styles.modalActions}>
                    <button onClick={handleSyncSubmit} className={styles.syncButton}>Sync</button>
                    <button onClick={closeModal} className={styles.closeButton}>Close</button>
                </div>
            </Modal>
            </div>
    );
};

export default Orders;
