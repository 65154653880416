import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import LoadingButtonDots from "../../../../components/LoadingButtonDots";
import styles from "./PublishModal.module.scss";

Modal.setAppElement("#root");

const PublishModal = ({
  isOpen,
  onRequestClose,
  stores,
  selectedProduct,
  fetchProducts,
  merchantService,
}) => {
  // Local state variables
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [publishWarnings, setPublishWarnings] = useState([]);
  const [publishing, setPublishing] = useState(false);
  const [mode, setMode] = useState("new"); // "new" or "existing"
  const [shopifyOrderId, setShopifyOrderId] = useState("");
  const [variantShopifyIds, setVariantShopifyIds] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!isOpen) {
      // Reset state when the modal closes
      setSelectedStore(null);
      setSelectedVariants([]);
      setPublishWarnings([]);
      setMode("new");
      setShopifyOrderId("");
      setVariantShopifyIds({});
      setErrors({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedStore) {
      generateWarnings();
    }
  }, [selectedStore, selectedVariants]);

  const checkStoreAndProductCountries = (store, productShippingCountries) => {
    const warnings = [];

    if (store.countries && store.countries.length > 0) {
      const storeCountries = store.countries.map((c) => c.country);
      const productCountries = productShippingCountries;

      const commonCountries = productCountries.filter((country) =>
        storeCountries.includes(country)
      );

      if (commonCountries.length === 0) {
        warnings.push(
          "None of the product's shipping countries match the store's countries."
        );
      }

      const extraStoreCountries = storeCountries.filter(
        (country) => !productCountries.includes(country)
      );
      if (extraStoreCountries.length > 0) {
        warnings.push(
          `The store includes the following countries that are not available in the product's shipping regions: ${extraStoreCountries.join(
            ", "
          )}.`
        );
      }
    } else {
      warnings.push("The store has no specified countries.");
    }

    return warnings;
  };

  const generateWarnings = () => {
    const store = stores.find((s) => s.id === selectedStore);
    if (!store) return;

    const productShippingCountries = new Set();

    if (selectedProduct.shippingPrices) {
      selectedProduct.shippingPrices.forEach((sp) =>
        productShippingCountries.add(sp.country)
      );
    }

    selectedProduct.variants.forEach((variant) => {
      if (variant.shippingPrices) {
        variant.shippingPrices.forEach((sp) =>
          productShippingCountries.add(sp.country)
        );
      }
    });

    const warnings = checkStoreAndProductCountries(
      store,
      Array.from(productShippingCountries)
    );

    setPublishWarnings(warnings);
  };

  const handleSelectedStoreChange = (event) => {
    setSelectedStore(event.target.value);
    clearError("store");
  };

  const handleVariantSelection = (variantId) => {
    setSelectedVariants((prevState) => {
      const updatedVariants = prevState.includes(variantId)
        ? prevState.filter((item) => item !== variantId)
        : [...prevState, variantId];
      return updatedVariants;
    });
    clearError("variants");
  };

  const handleVariantShopifyIdChange = (variantId, value) => {
    setVariantShopifyIds((prevState) => ({
      ...prevState,
      [variantId]: { id: variantId, shopifyVariantId: value },
    }));
    clearError(`variant-${variantId}`);
  };

  const clearError = (field) => {
    setErrors((prevState) => {
      const newErrors = { ...prevState };
      delete newErrors[field];
      return newErrors;
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!selectedStore) newErrors.store = true;
    if (mode === "existing" && !shopifyOrderId) newErrors.shopifyOrderId = true;

    if (selectedProduct.variants.length > 0 && selectedVariants.length === 0) {
      // If there are variants available, at least one must be selected
      newErrors.variants = true;
    }

    selectedVariants.forEach((variantId) => {
      if (
        mode === "existing" &&
        !variantShopifyIds[variantId]?.shopifyVariantId
      ) {
        newErrors[`variant-${variantId}`] = true;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submit = async () => {
    if (!validateFields()) return;

    try {
      setPublishing(true);
      if (mode === "new") {
        if (selectedProduct.variants.length > 0) {
          // Om produkten har varianter, publicera med varianter
          await merchantService.publishProductWithVariants(
            selectedProduct.id,
            selectedStore,
            selectedVariants,
            shopifyOrderId
          );
        } else {
          // Om produkten inte har några varianter, publicera utan varianter
          await merchantService.publishNoVariantProduct(
            selectedProduct.id,
            selectedStore
          );
        }
      } else {
        if (selectedProduct.variants.length > 0) {
          // Om produkten har varianter, koppla med varianter
          await merchantService.connectProductWithVariantsToExisting(
            selectedProduct.id,
            selectedStore,
            shopifyOrderId,
            selectedVariants.map((id) => ({
              id,
              shopifyVariantId: variantShopifyIds[id]?.shopifyVariantId || "",
            }))
          );
        } else {
          // Om produkten inte har några varianter, koppla utan varianter
          await merchantService.connectProductToExisting(
            selectedProduct.id,
            selectedStore,
            shopifyOrderId
          );
        }
      }
      fetchProducts();
      onRequestClose();
    } catch (error) {
      console.error("Failed to submit:", error);
    } finally {
      setPublishing(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Publish to Store"
      className={styles.publishModal}
      overlayClassName={styles.overlay}>
      <h2>Publish to Store</h2>
      {selectedProduct && (
        <div className={styles.publishContainer}>
          <div className={styles.switchContainer}>
            <p>Create New</p>
            <FormControlLabel
              control={
                <Switch
                  checked={mode === "existing"}
                  onChange={(e) =>
                    setMode(e.target.checked ? "existing" : "new")
                  }
                  color="primary"
                />
              }
            />
            <div className={styles.connectInfo}>
              <p>Connect to Existing </p>
              <a href="./publish-tutorial" target="_blank">
                <span class="material-symbols-outlined">info</span>
              </a>
            </div>
          </div>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel>Select Store</InputLabel>
            <Select
              label="Select Store"
              value={selectedStore || ""}
              onChange={handleSelectedStoreChange}
              className={errors.store ? styles.errorField : ""}>
              {stores.map((store, index) => {
                const isDisabled = selectedProduct.storeIds?.includes(store.id);
                return (
                  <MenuItem key={index} value={store.id} disabled={isDisabled}>
                    {store.shop.replace(".myshopify.com", "")}
                    {isDisabled ? " (Already Published)" : ""}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {publishWarnings.length > 0 && (
            <div className={styles.warningsContainer}>
              <span className="material-symbols-outlined">warning</span>
              <div>
                {publishWarnings.map((warning, index) => (
                  <p key={index} className={styles.warningText}>
                    {warning}
                  </p>
                ))}
                <p>
                  Fulfillment requests for orders with delivery destinations
                  outside the store's supported countries or the product's
                  available shipping regions will be rejected.
                </p>
              </div>
            </div>
          )}
          {mode === "existing" && (
            <TextField
              label="Shopify Order ID"
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              value={shopifyOrderId}
              onChange={(e) => {
                setShopifyOrderId(e.target.value);
                clearError("shopifyOrderId");
              }}
              className={errors.shopifyOrderId ? styles.errorField : ""}
            />
          )}
          {selectedProduct.variants && selectedProduct.variants.length > 0 && (
            <div className={styles.variantsList}>
              {selectedProduct.variants.map((variant, index) => (
                <div key={index} className={styles.variantRow}>
                  <label>
                    <input
                      type="checkbox"
                      value={variant.id}
                      onChange={() => handleVariantSelection(variant.id)}
                    />
                    {variant.SKU} - {variant.variantTitle}
                  </label>
                  {mode === "existing" &&
                    selectedVariants.includes(variant.id) && (
                      <TextField
                      size="small"
                        label="Shopify Variant ID"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={
                          variantShopifyIds[variant.id]?.shopifyVariantId || ""
                        }
                        onChange={(e) =>
                          handleVariantShopifyIdChange(
                            variant.id,
                            e.target.value
                          )
                        }
                        className={
                          errors[`variant-${variant.id}`]
                            ? styles.errorField
                            : ""
                        }
                      />
                    )}
                </div>
              ))}
            </div>
          )}
          <div className={styles.buttons}>
            <button
              onClick={submit}
              className={styles.publishButton}
              disabled={publishing}>
              {publishing ? <LoadingButtonDots /> : "Submit"}
            </button>
            <button
              onClick={onRequestClose}
              className={styles.closeButton}
              disabled={publishing}>
              Close
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PublishModal;
