import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import authService from '../../services/authService';
import styles from './FormStyles.module.scss'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingButtonDots komponent

const Login = () => {
    useEffect(() => {
        document.title = 'Login | Qiwi';
      }, []);  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [existingStore, setExistingStore] = useState(false);
    const [loading, setLoading] = useState(false); // Lägg till en state för att hantera laddningsindikatorn

    const navigate = useNavigate(); 
    const hasShopifyCookie = document.cookie.split('; ').find(row => row.startsWith('shopify_shop'));
    const shopifyShopValue = hasShopifyCookie ? hasShopifyCookie.split('=')[1] : null;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Sätt loading till true när inloggningen påbörjas
        try {
            const response = await authService.login(email, password);
            if(response.unexpectedUser) {
                alert(`The store ${shopifyShopValue}, which you came from, is connected to another user`);
            }
            if(response.expiring) {
                alert(`Cannot connect store to expiring merchant`);
            }
            if(response.role === "Merchant") {
                navigate('/');
            } else if (response.role === "Admin") {
                navigate('/admin');
            } else if (response.role === "Supplier") {
                navigate('/supplier/sourcing');
            }

        } catch (error) {
            if(error.response) {
                setMessage(error.response.data.message);
            }
        } finally {
            setLoading(false); // Sätt loading till false när inloggningen är klar
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (message === "No user found with this email") {
            setMessage(''); // Rensa felmeddelandet när användaren börjar skriva
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (message === "Invalid password") {
            setMessage(''); // Rensa felmeddelandet när användaren börjar skriva
        }
    };

    useEffect(() => {
        if (shopifyShopValue) {
            authService.checkStoreAndUserExistance(shopifyShopValue)
                .then((userstore) => {
                    if (userstore.existing) {
                        setExistingStore(true);
                    }
                })
                .catch(error => {
                    console.error('Error checking store existence:', error);
                    setMessage('Error checking store existence');
                });
        }
    }, [shopifyShopValue]);

    return (
        <div className={styles.container}>
            <h1>qiwi</h1>
            {(!existingStore && hasShopifyCookie) && <p>Login or register to connect store</p>}
            <form onSubmit={handleSubmit} className={styles.form}>
            <h2>Sign in</h2>
                <div className={styles.inputs}>
                    <div className={styles.emailInput}>
                        <input 
                            type="email" 
                            value={email} 
                            onChange={handleEmailChange} 
                            required 
                            className={`${styles.input} ${message === "No user found with this email" ? styles.errorInput : ''}`} 
                            placeholder="Email"
                        />
                        {message === "No user found with this email" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.password}>
                        <div className={styles.passwordInput}>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={handlePasswordChange} 
                            required 
                            className={`${styles.input} ${message === "Invalid password" ? styles.errorInput : ''}`} 
                            placeholder="Password"
                        />
                        {message === "Invalid password" && <p className={styles.errorMessage}>{message}</p>}
                        </div>
                        <Link to="../forgot-password" className={styles.forgot}>Forgot Password?</Link>
                    </div>
                    <button type="submit" className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Sign in'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <p>New merchant to qiwi?</p><Link to="../register">Join now</Link>
                </div>
            </form>
            <p className={styles.termsOfservice}>By logging in, you agree to our <Link to="/terms-of-service" target="_blank">Terms of Service</Link></p>
        </div>
    );
};

export default Login;
