import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import merchantService from '../../services/merchantService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import HoverableOverflowText from '../../components/HoverableOverflowText';

import styles from './Sourcing.module.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Detta är viktigt för tillgänglighet, ange roten för din app

const Sourcing = () => {
        useEffect(() => {
          document.title = 'Sourcing | Qiwi';
        }, []);

      
        const [sourcingData, setSourcingData] = useState([]);
        const [currentPage, setCurrentPage] = useState(1);
        const [totalPages, setTotalPages] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(10);
        const [statusFilter, setStatusFilter] = useState("all");
        const [searchQuery, setSearchQuery] = useState('');
        const [dateRange, setDateRange] = useState([]);
        const [noActiveRequests, setNoActiveRequests] = useState(false);
        const [filtersApplied, setFiltersApplied] = useState(false);
        const [initialLoad, setInitialLoad] = useState(true);
        const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
        const [loading, setLoading] = useState(true);
        const [isImageModalOpen, setIsImageModalOpen] = useState(false);
        const [selectedImage, setSelectedImage] = useState('');
        const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
        const [selectedRequest, setSelectedRequest] = useState(null);
        const [expiring, setExpiring] = useState(false);
        const [options] = useState(['Active', 'Archived']);
        const [activeOption, setActiveOption] = useState(options[0]);
        const [sourcingInfo, setSourcingInfo] = useState([]);
        const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
        const navigate = useNavigate();
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Responded', label: 'Responded' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'In Products', label: 'In Products' },
        { value: 'In Store', label: 'In Store' },
    ];

    useEffect(() => {
      const storedExpiring = sessionStorage.getItem('expiring');
      if (storedExpiring !== null) {
        setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
      }
    }, []);

    useEffect(() => {
        const fetchSourcingInfo = async () => {
            try {
                setLoadingInitialInfo(true)
                const response = await merchantService.fetchSourcingInfo();
                setSourcingInfo(response);
                setLoadingInitialInfo(false)
            } catch (error) {
                console.error('Failed to fetch orders info:', error);
                setSourcingInfo([]);
            }
        };
        fetchSourcingInfo();
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage]);

    const fetchData = async () => {
      try {

      setLoading(true);
      let startDate, endDate;
      if (dateRange.length > 0) {
          startDate = dateRange[0].startDate;
          endDate = dateRange[0].endDate;
      }

      const showArchived = activeOption === 'Archived';

        const data = await merchantService.fetchSourcingDataByPageAndStatus(
          statusFilter,
          currentPage,
          itemsPerPage,
          searchQuery,
          startDate,
          endDate,
          showArchived
      );

      setSourcingData(data.requests);
      setTotalPages(data.totalPages);
      setLoading(false);

      if (initialLoad) {
          if (!data.requests.length) {
              setNoActiveRequests(true);
              setFiltersApplied(false);
          } else {
              setNoActiveRequests(false);
              setFiltersApplied(true);
          }
          setInitialLoad(false);
      } else {
          setFiltersApplied(true);
          setNoActiveRequests(!data.requests.length);
      }
    } catch(error) {
      console.error(error);
    }
  };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const applyFilters = () => {
        setFiltersApplied(true);
        fetchData();
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...sourcingData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : 0;
            }
            return 0;
        });
        setSourcingData(sortedData);
    };

    const viewQuotations = (requestId) => {
        navigate(`./quotations/${requestId}`);
    };

    const renderActionButton = (request) => {
        switch (request.status) {
            case 'Responded':
            case 'Confirmed':
            case 'In Products':
            case 'In Store':
                return <button onClick={() => viewQuotations(request.id)} className={styles.quotationsButton}>Quotations</button>;
            default:
                return null;
        }
    };

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    const viewDetails = (request) => {
        setSelectedRequest(request);
        setIsDetailsModalOpen(true);
    };

    const handleClick = (e) => {
        if (expiring) {
          e.preventDefault(); // Förhindrar navigering
          alert('Your account is expiring, you cannot create a new request.');
        } else {
          navigate('/sourcing/create-request'); // Tillåt navigering
        }
      };

    return (
        <div className={styles.container}>
            <div className={styles.infoContainers}>
            <div className={`${styles.infoboxYellow} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          equal
        </span>
      </div>
      <div className={styles.info}>
        <h2>{sourcingInfo.totalCount}</h2>
        <p>TOTAL</p>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoBoxCols} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
    <div className={styles.bluespan}>
      <div>
            <span class="material-symbols-outlined">
        more_horiz
        </span>
      </div>
      <div className={styles.info}>
        <p>PENDING</p>
        <h3>{sourcingInfo.pendingCount}</h3>
      </div>
      </div>
      <div  className={styles.orangespan}>
      <div >
        <span className="material-symbols-outlined">
          reply
        </span>
      </div>
      <div className={styles.info}>
        <p>RESPONDED</p>
        <h3>{sourcingInfo.respondedCount}</h3>

      </div>
      </div>
      <div className={styles.greenspan}>
      <div >
      <span class="material-symbols-outlined">
            check
            </span>
      </div>
      <div className={styles.info}>
        <p>CONFIRMED</p>
        <h3>{sourcingInfo.confirmedCount}</h3>
      </div>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoboxPurple} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
<span class="material-symbols-outlined">
timelapse
</span>
      <div className={styles.info}>
      <h2>{sourcingInfo.requestLeft}</h2>
        <p>REQUESTS LEFT</p>
        <p className={styles.extraInfo}>DAILY</p>
      </div>
    </>
  )}
</div>

            <div className={styles.createContainter}>
                    <Link 
                    to="/sourcing/create-request" 
                    className={`${styles.createButton} ${expiring || sourcingInfo.requestLeft === 0 ? styles.disabled : ''}`}
                    onClick={handleClick}
                    >
                    <span class="material-symbols-outlined">
                    add
                    </span>
                    <h2>Create <br/> Request</h2>
                    </Link>           
            </div>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.filterHeader}>
                <FilterComponent
                        options={options}
                        activeOption={activeOption}
                        setActiveOption={setActiveOption}
                        statusFilter={statusFilter}
                        setSelectFilter={setStatusFilter}
                        dateRange={dateRange}
                        setDateRange={setDateRange}
                        statusOptions={statusOptions}
                        includeSearch={true}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        searchPlaceholder="Search Product Name..."
                        applyFilters={applyFilters}
                        filtersDisabled={(!filtersApplied && noActiveRequests) || loading}
                        setInitialLoad={setInitialLoad}
                    />
                    </div>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                        <tr>
                            <th onClick={() => handleSort('id')}>
                                <div className={styles.thHeader}>
                                    Request ID
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('productName')}>
                                <div className={styles.thHeader}>
                                    Product Info
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'productName' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'productName' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Date
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('status')}>
                                <div className={styles.thHeader}>
                                    Status
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'status' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'status' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className={styles.thHeader}>
                                    Actions
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
  {loading ? (
    Array.from({ length: 5 }).map((_, index) => (
      <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
        <td colSpan={5}></td>
      </tr>
    ))
  ) : !noActiveRequests ? (
    sourcingData.map((request) => (
      <tr key={request.id} className={styles.rows}>
        <td>{request.id}</td>
        <td>
          <div className={styles.productInfo}>
            {request.image && (
              <img
                src={`/uploads/productImages/${request.image}`}
                alt={request.productName}
                className={styles.productImage}
                onClick={() =>
                  viewImage(`/uploads/productImages/${request.image}`)
                }
              />
            )}
            <HoverableOverflowText
              text={request.productName}
              className={styles.hoverableOverflowText}
            />
          </div>
        </td>
        <td>{new Date(request.createdAt).toLocaleDateString()}</td>
        <td>{request.status}</td>
        <td className={styles.actions}>
          <div className={styles.buttonGroup}>
            <button
              className={styles.detailsButton}
              onClick={() => viewDetails(request)}
            >
              Details
            </button>
            {renderActionButton(request)}
          </div>
        </td>
      </tr>
    ))
  ) : activeOption === 'Archived' ? (
    <tr className={styles.rows}>
      <td colSpan={5}>
        <p className={styles.noFound}>
          No archived requests found{filtersApplied && ", check filtering"}
        </p>
      </td>
    </tr>
  ) : (
    <tr className={styles.rows}>
      <td colSpan={5}>
        <p className={styles.noFound}>
          No active requests found{filtersApplied && ", check filtering"}
        </p>
      </td>
    </tr>
  )}
  <tr className={styles.rows}>
    <td colSpan={5}>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </td>
  </tr>
</tbody>
                </table>
            </div>

            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

            <Modal
  isOpen={isDetailsModalOpen}
  onRequestClose={() => setIsDetailsModalOpen(false)}
  className={styles.modaltwo}
  overlayClassName={styles.overlay}
>
  {selectedRequest && (
    <div className={styles.detailsContainer}>
      {selectedRequest.image && (
        <div className={styles.imageContainer}>
          <img src={`/uploads/productImages/${selectedRequest.image}`} alt={selectedRequest.productName} />
        </div>
      )}
      <div className={styles.detailsRows}>
        <h2>Request Details</h2>
        <div>
          <p><strong>Request ID</strong></p>
          <p>{selectedRequest.id}</p>
        </div>
        <div>
          <p><strong>Product Name</strong></p>
          <p>{selectedRequest.productName}</p>
        </div>
        <div>
          <p><strong>URL</strong></p>
          <p className={styles.url}><a href={selectedRequest.url} target="_blank" rel="noopener noreferrer">{selectedRequest.url}</a></p>
        </div>
        <div>
          <p><strong>Created At</strong></p>
          <p>{new Date(selectedRequest.createdAt).toLocaleDateString()}</p>
        </div>
        {selectedRequest.status && (
          <div>
            <p><strong>Status</strong></p>
            <p>{selectedRequest.status}</p>
          </div>
        )}
        {selectedRequest.description && (
          <div>
            <p><strong>Description</strong></p>
            <p className={styles.description}>{selectedRequest.description}</p>
          </div>
        )}
        {selectedRequest.note && (
          <div>
            <strong>Note</strong>
            <p>{selectedRequest.note}</p>
          </div>
        )}
        {selectedRequest.SourcingRequestCategories && selectedRequest.SourcingRequestCategories.length > 0 && (
          <div className={styles.moreInfoList}>
          <h4>Variants</h4>
          <div className={styles.variantsContainer}>
            {selectedRequest.SourcingRequestCategories.map((category) => (
              <div key={category.id} className={styles.variantCategory}>
                <strong>{category.name}:</strong>{" "}
                {category.SourcingRequestCategoryValues.map((value) => value.value).join(", ")}
              </div>
            ))}
          </div>
          </div>
        )}
        {selectedRequest.SourcingCountries && selectedRequest.SourcingCountries.length > 0 && (
          <div className={styles.moreInfoList}>
          <h4>Countries</h4>
          <div className={styles.countries}>
            {selectedRequest.SourcingCountries.map((country, index) => (
              <p key={index}>{country.country}</p>
            ))}
          </div>
          </div>
        )}
        
      </div>
    </div>
  )}
  <button onClick={() => setIsDetailsModalOpen(false)} className={styles.closeButton}>Close</button>
</Modal>
        </div>
    );
};

export default Sourcing;
