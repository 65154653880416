import React, { useEffect, useState } from 'react';
import FilterComponent from '../../components/FilterComponent';
import OrdersTable from './components/orders/OrdersTable';
import OrderDeclinesTable from './components/orders/OrderDeclinesTable'; // Importera den nya komponenten
import merchantService from '../../services/merchantService';
import styles from './Orders.module.scss';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Orders = () => {
  useEffect(() => {
    document.title = 'Orders | Qiwi';
  }, []);

  // States för Active/Archived Orders
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [visibleItems, setVisibleItems] = useState({});
  const [noActiveOrders, setNoActiveOrders] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mergedData, setMergedData] = useState([]);
  const [mergedModal, setMergedModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [ordersToPay, setOrdersToPay] = useState([]);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [paymentError, setPaymentError] = useState('');

  // States för Declined Orders
  const [orderDeclines, setOrderDeclines] = useState([]);

  const [options] = useState(['Active', 'Archived', 'Declined']); // Lägg till 'Declined'
  const [activeOption, setActiveOption] = useState(options[0]); // Första alternativet är aktivt som standard

  const navigate = useNavigate();
  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'Awaiting Payment', label: 'Awaiting Payment' },
    { value: 'Awaiting Fulfillment', label: 'Awaiting Fulfillment' },
    { value: 'Partially Fulfilled', label: 'Partially Fulfilled' },
    { value: 'Fulfilled', label: 'Fulfilled' },
    { value: 'Cancelled', label: 'Cancelled' },
  ];

  const fetchOrdersInfo = async () => {
    try {
      setLoadingInitialInfo(true);
      const response = await merchantService.fetchOrdersInfo();
      setOrderInfo(response);
      setLoadingInitialInfo(false);
    } catch (error) {
      setOrderInfo([]);
      setLoadingInitialInfo(false);
    }
  };

  useEffect(() => {
    fetchOrdersInfo();
  }, []);

  const toggleItemDetails = async (orderId) => {
    if (visibleItems[orderId]) {
      setVisibleItems(prev => ({
        ...prev,
        [orderId]: false
      }));
    } else {
      setVisibleItems(prev => ({
        ...prev,
        [orderId]: 'loading'
      }));
      try {
        const showArchived = activeOption === 'Archived';

        const items = await merchantService.fetchMerchantOrderItems(orderId, showArchived);
        setOrders(prevOrders => prevOrders.map(order =>
          order.orderId === orderId ? { ...order, items } : order
        ));
      } catch (error) {
        console.error('Failed to fetch order items:', error);
      } finally {
        setVisibleItems(prev => ({
          ...prev,
          [orderId]: true
        }));
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, statusFilter, searchQuery, dateRange, activeOption]);

  const fetchMergeData = async (mergeId) => {
    try {
      const { success, mergeGroup } = await merchantService.fetchMergeByMergeId(mergeId);
      if (success && mergeGroup) {
        setMergedData([mergeGroup]);
        setMergedModal(true);
      } else {
        console.error('No merge group found.');
      }
    } catch (error) {
      console.error('Failed to fetch merge info:', error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let startDate, endDate;
      if (dateRange.length > 0) {
        startDate = dateRange[0].startDate;
        endDate = dateRange[0].endDate;
      }

      if (activeOption === 'Declined') {
        // Hämta orderavslag
        const data = await merchantService.fetchOrderDeclines(
          currentPage,
          itemsPerPage,
          searchQuery,
          startDate,
          endDate
        );
        setOrderDeclines(data.orderDeclines);
        setTotalPages(data.totalPages);
        setNoActiveOrders(!data.orderDeclines.length);
      } else {
        // Hämta vanliga ordrar
        const showArchived = activeOption === 'Archived';

        const data = await merchantService.fetchOrdersByPageAndFilters(
          statusFilter,
          currentPage,
          itemsPerPage,
          searchQuery,
          startDate,
          endDate,
          showArchived
        );
        setOrders(data.orders);
        setTotalPages(data.totalPages);

        if (initialLoad) {
          if (!data.orders.length) {
            setNoActiveOrders(true);
            setFiltersApplied(false);
          } else {
            setNoActiveOrders(false);
            setFiltersApplied(true);
          }
          setInitialLoad(false);
        } else {
          setFiltersApplied(true);
          setNoActiveOrders(!data.orders.length);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setLoading(false);
      if (activeOption === 'Declined') {
        setOrderDeclines([]);
        setNoActiveOrders(true);
      } else {
        setOrders([]);
        setNoActiveOrders(true);
      }
    }
  };

  const handlePageChangeFunction = (event, page) => {
    setCurrentPage(page);
  };

  const applyFilters = () => {
    setFiltersApplied(true);
    fetchData();
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...(activeOption === 'Declined' ? orderDeclines : orders)].sort((a, b) => {
      if (key === 'quantity') {
        const totalQuantityA = a.items.reduce((total, item) => total + item.quantity, 0);
        const totalQuantityB = b.items.reduce((total, item) => total + item.quantity, 0);

        return direction === 'ascending' ? totalQuantityA - totalQuantityB : totalQuantityB - totalQuantityA;
      }

      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    if (activeOption === 'Declined') {
      setOrderDeclines(sortedData);
    } else {
      setOrders(sortedData);
    }
  };

  const handleRowSelect = (orderId) => {
    setSelectedRows(prevSelectedRows => {
      if (prevSelectedRows.includes(orderId)) {
        // Om raden redan är vald, avmarkera den
        return prevSelectedRows.filter(id => id !== orderId);
      } else {
        // Annars, lägg till den i listan över valda rader
        return [...prevSelectedRows, orderId];
      }
    });
  };

  const handleSelectAll = () => {
    if (activeOption === 'Declined') {
      if (selectedRows.length === orderDeclines.length && orderDeclines.length > 0) {
        setSelectedRows([]);
      } else {
        setSelectedRows(orderDeclines.map(decline => decline.shopifyOrderId));
      }
    } else {
      if (selectedRows.length === orders.length && orders.length > 0) {
        setSelectedRows([]);
      } else {
        setSelectedRows(orders.map(order => order.orderId));
      }
    }
  };

  const handlePayClick = (orderId) => {
    let ordersToInclude = [];

    const clickedOrder = orders.find(order => order.orderId === orderId);

    if (!clickedOrder || clickedOrder.status !== 'Awaiting Payment') {
      // Hantera fallet där ordern inte existerar eller inte är i 'Awaiting Payment' status
      setPaymentError('Selected order is not awaiting payment.');
      return;
    }

    if (selectedRows.includes(orderId)) {
      // Om den klickade ordern är markerad, inkludera alla markerade ordrar med status 'Awaiting Payment'
      ordersToInclude = orders.filter(
        order => selectedRows.includes(order.orderId) && order.status === 'Awaiting Payment'
      );
    } else {
      // Om den klickade ordern inte är markerad, inkludera endast den
      ordersToInclude.push(clickedOrder);
    }

    // Beräkna total betalningssumma
    const totalAmount = ordersToInclude.reduce((sum, order) => sum + order.totalCost, 0);

    setOrdersToPay(ordersToInclude);
    setTotalPaymentAmount(totalAmount);
    setPaymentError('');
    setShowPaymentModal(true);
  };

  const handleConfirmPayment = async () => {
    try {
      const orderIds = ordersToPay.map(order => order.orderId);
      const response = await merchantService.payOrders(orderIds);

      if (response.success) {
        fetchOrdersInfo();
        fetchData();
        setShowPaymentModal(false);
      } else {
        setPaymentError(response.message || 'Payment failed. Please try again.');
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setPaymentError('Payment failed. Please try again.');
    }
  };

  useEffect(() => {
    // Rensa valda rader när data ändras
    setSelectedRows([]);
  }, [orders, orderDeclines]);

  return (
    <div className={styles.container}>
      <div className={styles.infoContainers}>
        <div className={`${styles.infoBoxWithDetails} ${loadingInitialInfo && styles.loadinginfobox}`}>
          <div>
            <span className="material-symbols-outlined">
              query_stats
            </span>
          </div>
          {!loadingInitialInfo && (
            <>
              <div className={styles.mainInfo}>
                <h2>{orderInfo.pendingOrders.totalOrders}</h2>
                <p>PENDING ORDERS</p>
              </div>
              <div className={styles.listInfo}>
                <div>
                  <h3>{orderInfo.pendingOrders.awaitingMerchantPayment}</h3>
                  <p>AWAITING PAYMENT</p>
                </div>
                <div>
                  <h3>{orderInfo.pendingOrders.awaitingShipment}</h3>
                  <p>AWAITING FULFILLMENT</p>
                </div>
                <div>
                  <h3>{orderInfo.pendingOrders.partiallyFulfilled}</h3>
                  <p>PARTIALLY FULFILLED</p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={`${styles.infoboxBlue} ${loadingInitialInfo && styles.loadinginfobox}`}>
          {!loadingInitialInfo && (
            <>
              <div>
                <span className="material-symbols-outlined">
                  local_shipping
                </span>
              </div>
              <div className={styles.info}>
                <h2>{orderInfo.newlyFulfilled}</h2>
                <p>RECENT FULFILLMENTS</p>
              </div>
            </>
          )}
        </div>
        <div className={`${styles.infoboxGreen} ${loadingInitialInfo && styles.loadinginfobox}`}>
          {!loadingInitialInfo && (
            <>
              <div>
                <span className="material-symbols-outlined">
                  request_quote
                </span>
              </div>
              <div className={styles.info}>
                <h2>{orderInfo.debt}€</h2>
                <p>AWAITING PAYMENT</p>
              </div>
            </>
          )}
        </div>
        <div className={`${styles.infoboxOrange} ${loadingInitialInfo && styles.loadinginfobox}`}>
          {!loadingInitialInfo && (
            <>
              <div>
                <span className="material-symbols-outlined">
                  wallet
                </span>
              </div>
              <div className={styles.info}>
                <h2>{orderInfo.wallet}€</h2>
                <p>WALLET</p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.filterHeader}>
          <FilterComponent
            setInitialLoad={setInitialLoad}
            options={options}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            statusFilter={activeOption === 'Declined' ? null : statusFilter}
            setSelectFilter={setStatusFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
            statusOptions={statusOptions}
            includeSearch={true}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder="Search Shopify Order ID..."
            applyFilters={applyFilters}
            filtersDisabled={!filtersApplied && noActiveOrders}
          />

          <button
            onClick={() => navigate(`${window.location.pathname}/merge-orders`)}
            className={styles.mergeOrderButton}
          >
            <span className="material-symbols-outlined">call_merge</span>
            <span>Merge</span>
          </button>
        </div>
        {activeOption === 'Declined' ? (
          <OrderDeclinesTable
            orderDeclines={orderDeclines}
            loading={loading}
            noActiveOrders={noActiveOrders}
            selectedRows={selectedRows}
            handleSelectAll={handleSelectAll}
            handleRowSelect={handleRowSelect}
            handlePageChange={handlePageChangeFunction}
            setItemsPerPage={setItemsPerPage}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            totalPages={totalPages}
            filtersApplied={filtersApplied}
          />
        ) : (
          <OrdersTable
            orders={orders}
            loading={loading}
            noActiveOrders={noActiveOrders}
            selectedRows={selectedRows}
            handleSelectAll={handleSelectAll}
            handleRowSelect={handleRowSelect}
            visibleItems={visibleItems}
            toggleItemDetails={toggleItemDetails}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChangeFunction}
            activeOption={activeOption}
            filtersApplied={filtersApplied}
            fetchMergeData={fetchMergeData}
            handlePayClick={handlePayClick}
            sortConfig={sortConfig}
            handleSort={handleSort}
          />
        )}
      </div>
      {mergedModal && (
        <Modal
          isOpen={mergedModal}
          onRequestClose={() => setMergedModal(false)}
          contentLabel="Merged Orders"
          className={styles.modal} // Stilklasser för modalen
          overlayClassName={styles.overlay} // Stilklasser för overlay
        >
          <h2>Merged Orders</h2>
          <div className={styles.mergeGroupsContainer}>
            {mergedData.map(group => (
              <div key={group.mergeGroupId} className={styles.mergeGroup}>
                <p><strong>Supplier:</strong> {group.supplier}</p>
                <table className={styles.mergeTable}>
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Product Name</th>
                      <th>Variant</th>
                      <th>Quantity</th>
                      <th>SKU</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.fulfillmentOrders.map(fulfillmentOrder => (
                      <React.Fragment key={fulfillmentOrder.fulfillmentOrderId}>
                        {fulfillmentOrder.orderItems.map(item => (
                          <tr key={item.id}>
                            <td>{fulfillmentOrder.orderId}</td>
                            <td>{item.productName}</td>
                            <td>{item.variantTitle ? item.variantTitle : "-"}</td>
                            <td>{item.quantity}</td>
                            <td>{item.SKU}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <button onClick={() => setMergedModal(false)} className={styles.closeButton}>Close</button>
        </Modal>
      )}
      {showPaymentModal && (
        <Modal
          isOpen={showPaymentModal}
          onRequestClose={() => setShowPaymentModal(false)}
          contentLabel="Confirm Payment"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <h2>Confirm Payment</h2>
          <div className={styles.paymentDetails}>
            <p><strong>Wallet Balance:</strong> €{parseFloat(orderInfo.wallet)}</p>
            <p><strong>Total Payment Amount:</strong> €{totalPaymentAmount}</p>
            <p><strong>Balance After Payment:</strong> €{(orderInfo.wallet - totalPaymentAmount).toFixed(2)}</p>
            {orderInfo.wallet < totalPaymentAmount && (
              <p className={styles.errorMessage}>
                Insufficient wallet balance to cover the total payment.
              </p>
            )}
            {paymentError && (
              <p className={styles.errorMessage}>{paymentError}</p>
            )}
            <table className={styles.paymentTable}>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Total Cost</th>
                </tr>
              </thead>
              <tbody>
                {ordersToPay.map(order => (
                  <tr key={order.orderId}>
                    <td>{order.orderId}</td>
                    <td>€{order.totalCost}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.modalActions}>
              <button
                onClick={handleConfirmPayment}
                disabled={orderInfo.wallet < totalPaymentAmount}
                className={styles.payButton}
              >
                Pay
              </button>
              <button onClick={() => setShowPaymentModal(false)} className={styles.cancelButton}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Orders;
