// AddVariantModal.jsx
import React, { useState, useRef, useEffect } from 'react';
import styles from './AddVariantModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

const AddVariantModal = ({
  isOpen,
  onRequestClose,
  maxVariants = 3,
  onVariantsUpdated,
  existingVariantList
}) => {
  const [newVariantName, setNewVariantName] = useState('');
  const [newVariantValue, setNewVariantValue] = useState('');
  const [newVariants, setNewVariants] = useState([]);
  const variantValueInputRef = useRef(null);
  const [variantValueError, setVariantValueError] = useState("");
  const [variantNameError, setVariantNameError] = useState("");
  const [variantList, setVariantList] = useState(existingVariantList || []);

  useEffect(() => {
    if (isOpen) {
      setNewVariantName('');
      setNewVariants([]);
      setVariantValueError('');
      setVariantNameError('');
      setVariantList(existingVariantList || []);
    }
  }, [isOpen, existingVariantList]);

  const handleAddVariantValue = (e) => {
    if (e.key === 'Enter' && newVariantValue.trim() !== '') {
      e.preventDefault();
      if (newVariants.includes(newVariantValue.trim())) {
        setVariantValueError(`Duplicate variant value: ${newVariantValue.trim()}`);
      } else {
        setNewVariants((prev) => [...prev, newVariantValue.trim()]);
        setNewVariantValue('');
        setVariantValueError('');
        if (variantValueInputRef.current) {
          variantValueInputRef.current.focus();
        }
      }
    }
  };

  const handleAddVariantCategory = () => {
    if (newVariantName.trim() === '') {
      setVariantNameError('Variant category name cannot be empty');
      return;
    }
    if (variantList.some((variant) => variant.name === newVariantName.trim())) {
      setVariantNameError(`Duplicate variant category name: ${newVariantName.trim()}`);
      return;
    }
    if (newVariants.length === 0) {
      setVariantValueError('Please enter at least one variant value');
      return;
    }
    // Update the local variantList
    const updatedVariantList = [
      ...variantList,
      { name: newVariantName.trim(), values: newVariants },
    ];
    setVariantList(updatedVariantList);

    // Reset local state
    setNewVariantName('');
    setNewVariantValue('');
    setNewVariants([]);
    setVariantNameError('');
    setVariantValueError('');
  };

  const handleRemoveVariantFromModal = (index) => {
    setNewVariants((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveCategoryFromVariantList = (index) => {
    const updatedVariantList = variantList.filter((_, i) => i !== index);
    setVariantList(updatedVariantList);
  };

  const handleSaveVariants = () => {
    onVariantsUpdated(variantList);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <div className={styles.modalContent}>
        <h2>{variantList.length > 0 ? 'Edit Variants' : 'Add Variants'}</h2>
        <label>
          Variant Category Name
          <input
            placeholder={
              variantList.length >= maxVariants
                ? `Maximum of ${maxVariants} Variant Titles`
                : 'Enter Variant Title (e.g. Color)...'
            }
            type="text"
            value={newVariantName}
            onChange={(e) => setNewVariantName(e.target.value)}
            className={`${styles.categoryInput} ${variantNameError ? styles.error : ''}`}
            disabled={variantList.length >= maxVariants}
          />
          {variantNameError && <span className={styles.errorText}>{variantNameError}</span>}
        </label>
        <label>
          Variants
          <div
            className={`${styles.variantInputContainer} ${
              variantValueError ? styles.error : ''
            }`}
          >
            {newVariants.map((variant, index) => (
              <div key={index} className={styles.variantItem}>
                <button type="button" onClick={() => handleRemoveVariantFromModal(index)}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                {variant}
              </div>
            ))}
            <input
              type="text"
              id="variant"
              name="variant"
              value={newVariantValue}
              onChange={(e) => setNewVariantValue(e.target.value)}
              onKeyDown={handleAddVariantValue}
              placeholder="Enter Variant Value (e.g. Red)..."
              className={styles.variantInput}
              ref={variantValueInputRef}
            />
          </div>
          {variantValueError && <span className={styles.errorText}>{variantValueError}</span>}
        </label>
        <button
          type="button"
          onClick={handleAddVariantCategory}
          className={styles.addVariantButton}
          disabled={variantList.length >= maxVariants}
        >
          Add Variant Category
        </button>
        <div className={styles.addedVariantsContainer}>
          {variantList.map((variant, index) => (
            <div key={index} className={styles.addedVariants}>
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => handleRemoveCategoryFromVariantList(index)}
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                />
              </span>
              <p>
                <strong>{variant.name + ': '} </strong> {variant.values.join(', ')}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.buttonGroup}>
          <button type="button" onClick={handleSaveVariants} className={styles.submitButton}>
            Save
          </button>
          <button type="button" onClick={onRequestClose} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddVariantModal;
