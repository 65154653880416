import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import merchantService from '../../services/merchantService';
import styles from './QuotationList.module.scss';
import Modal from 'react-modal';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner';

Modal.setAppElement('#root');

const QuotationList = () => {
    useEffect(() => {
        document.title = 'Quotations | Qiwi';
    }, []);
      
    const { requestId } = useParams();
    const [quotations, setQuotations] = useState([]);
    const [request, setRequest] = useState({});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [expiring, setExpiring] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingQuotationId, setLoadingQuotationId] = useState(null);
    const [expandedRequest, setExpandedRequest] = useState(false); // State for expand/collapse

    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true');
        }
    }, []);

    useEffect(() => {
        fetchQuotationsAndRequest();
    }, [requestId]);

    const fetchQuotationsAndRequest = async () => {
        try {
            setLoading(true);
            const response = await merchantService.fetchQuotationsAndRequest(requestId);
            const { quotations, ...requestData } = response;

            setQuotations(quotations);
            setRequest(requestData);
        } catch (error) {
            console.error('Failed to fetch request:', error);
        } finally {
            setLoading(false);
        }
    };


    const chooseQuotation = async (quotationId) => {
        try {
            setLoadingQuotationId(quotationId);
            await merchantService.confirmQuotation(quotationId, requestId);
            alert('Quotation confirmed');
            fetchQuotationsAndRequest();
        } catch (error) {
            console.error('Error confirming quotation:', error);
        } finally {
            setLoadingQuotationId(null);
        }
    };

    const addToProducts = async (quotationId) => {
        try {
            setLoadingQuotationId(quotationId);
            await merchantService.addToProducts(quotationId, requestId);
            alert('Quotation added to products');
            fetchQuotationsAndRequest();
        } catch (error) {
            console.error('Error adding to products:', error);
        } finally {
            setLoadingQuotationId(null);
        }
    };


    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    // Uppdaterad groupQuotations funktion som hanterar både quotations med och utan varianter
    const groupQuotations = (quotations) => {
        const grouped = {};

        quotations.forEach((quotation) => {
            const supplier = quotation.supplierName || 'Unknown Supplier';

            if (quotation.hasVariants && quotation.variants) {
                quotation.variants.forEach((variant) => {
                    if (variant.shippingPrices && variant.shippingPrices.length > 0) {
                        variant.shippingPrices.forEach((shipping) => {
                            const country = shipping.country || 'Unknown Country';
                            if (!grouped[supplier]) {
                                grouped[supplier] = {};
                            }
                            if (!grouped[supplier][country]) {
                                grouped[supplier][country] = [];
                            }
                            grouped[supplier][country].push({
                                quotationId: quotation.id,
                                variantId: variant.id,
                                supplierName: supplier,
                                colorHex: quotation.colorHex,
                                shippingCountry: country,
                                SKU: variant.SKU,
                                productPrice: variant.productPrice,
                                shippingPrice: shipping.shippingPrice,
                                averageProcessingTimeHours: quotation.averageProcessingTimeHours,
                                averageShippingTimeHours: quotation.averageShippingTimeHours,
                                image: variant.image,
                                comments: quotation.comments,
                                hasVariants: quotation.hasVariants,
                                categoryVariants: variant.categoryVariants,
                                fullQuotation: quotation, // Add entire quotation for detailed view
                            });
                        });
                    }
                });
            } else if (!quotation.hasVariants && quotation.noVariant) {
                if (quotation.noVariant.shippingPrices && quotation.noVariant.shippingPrices.length > 0) {
                    quotation.noVariant.shippingPrices.forEach((shipping) => {
                        const country = shipping.country || 'Unknown Country';
                        if (!grouped[supplier]) {
                            grouped[supplier] = {};
                        }
                        if (!grouped[supplier][country]) {
                            grouped[supplier][country] = [];
                        }
                        grouped[supplier][country].push({
                            colorHex: quotation.colorHex,
                            quotationId: quotation.id,
                            supplierName: supplier,
                            shippingCountry: country,
                            SKU: quotation.noVariant.SKU,
                            productPrice: quotation.noVariant.productPrice,
                            shippingPrice: shipping.shippingPrice,
                            averageProcessingTimeHours: quotation.averageProcessingTimeHours,
                            averageShippingTimeHours: quotation.averageShippingTimeHours,
                            image: quotation.noVariant.image,
                            comments: quotation.comments,
                            hasVariants: quotation.hasVariants,
                            fullQuotation: quotation, // Add entire quotation for detailed view
                        });
                    });
                }
            }
        });

        return grouped;
    };

    // Memoize groupedQuotations för optimering
    const groupedQuotations = useMemo(() => groupQuotations(quotations), [quotations]);

    // Funktion för att sammanfoga kategorivarianten med " / "
    const joinCategoryVariants = (categoryVariants) => {
        return categoryVariants.map(cv => cv.value).join(' / ');
    };

    const handleRowClick = (quotation) => {
        setSelectedQuotation(quotation.fullQuotation); // Sätter full quotation när en rad klickas
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftPanel}>
                <div className={styles.requestContainer}>
                    <div className={styles.requestDetails}>
                        <div className={styles.toggleRequest} onClick={() => setExpandedRequest(!expandedRequest)}>
                            <h3>Request Details</h3>
                            {expandedRequest ? (
                                <span className="material-symbols-outlined">keyboard_arrow_up</span>
                            ) : (
                                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                            )}
                        </div>
{expandedRequest && (
  <div className={styles.requestInfoContainer}>
    {request.image && (
      <div className={styles.imageContainer}>
        <img
          src={`/uploads/productImages/${request.image}`}
          alt={request.productName}
          className={styles.productImage}
        />
      </div>
    )}
    <div className={styles.detailsContainer}>
      <p><strong>ID:</strong> {requestId}</p>
      <p><strong>Product:</strong> {request.productName}</p>
      <p><strong>Description:</strong> {request.description || 'N/A'}</p>
      <p><strong>URL:</strong> <a href={request.url} target="_blank" rel="noopener noreferrer">{request.url}</a></p>
      <p><strong>Note:</strong> {request.note || 'N/A'}</p>
      <p><strong>Status:</strong> {request.status || 'N/A'}</p>
      {request.categories && request.categories.length > 0 && (
        <div className={styles.variantsContainer}>
          <h3>Variants:</h3>
          {request.categories.map((category) => (
            <div key={category.id} className={styles.variantCategory}>
              <strong>{category.name}:</strong>{" "}
              {category.values.map((value) => value.value).join(", ")}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
)}

                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead className={styles.mainThead}>
                            <tr>
                                <th></th>
                                <th>Price</th>
                                <th>Shipping Price</th>
                                <th>Avg. Processing Time</th>
                                <th>Avg. Shipping Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading
                                ? Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                        <td colSpan="5"><LoadingSpinner /></td>
                                    </tr>
                                ))
                                : Object.keys(groupedQuotations).map((supplier, supIndex) => (
                                    <React.Fragment key={`supplier-${supIndex}`}>
                                        <tr 
                                            className={`${styles.groupHeader} ${groupedQuotations[supplier][Object.keys(groupedQuotations[supplier])[0]][0].quotationId === request.selectedQuotationId && styles.selectedGroup}`}
                                            onClick={() => handleRowClick(groupedQuotations[supplier][Object.keys(groupedQuotations[supplier])[0]][0])}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <td colSpan="5" style={{ cursor: 'pointer', backgroundColor: groupedQuotations[supplier][Object.keys(groupedQuotations[supplier])[0]][0].colorHex }}>
                                                <div><p>Supplier: {supplier}</p>{groupedQuotations[supplier][Object.keys(groupedQuotations[supplier])[0]][0].quotationId === request.selectedQuotationId && <p>Selected</p>}</div>
                                            </td>
                                        </tr>
                                        {Object.keys(groupedQuotations[supplier]).map((country, countryIndex) => (
                                            <React.Fragment key={`country-${countryIndex}`}>
                                                <tr 
                                                    className={styles.subGroupHeader}
                                                    onClick={() => handleRowClick(groupedQuotations[supplier][country][0])}
                                                >
                                                    <td colSpan="5">{country}</td>
                                                </tr>
                                                {groupedQuotations[supplier][country].map((quotation) => (
                                                    <tr
                                                        key={`${quotation.quotationId}-${quotation.variantId || 'no-variant'}`}
                                                        className={`${styles.rows} ${selectedQuotation?.id === quotation.quotationId ? styles.selectedQuotation : ''}`}
                                                        onClick={() => handleRowClick(quotation)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <td>
                                                            <div className={styles.prodName}>
                                                            {quotation.hasVariants
                                                                ? joinCategoryVariants(quotation.categoryVariants)
                                                                : request.productName
                                                            }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {quotation.productPrice !== undefined
                                                                ? `€${quotation.productPrice}`
                                                                : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {quotation.shippingPrice !== undefined
                                                                ? `€${quotation.shippingPrice}`
                                                                : 'N/A'
                                                            }
                                                        </td>
                                                        <td>
                                                            {quotation.averageProcessingTimeHours
                                                                ? `${(quotation.averageProcessingTimeHours / 24).toFixed(1)} d`
                                                                : '1-3'
                                                            }
                                                        </td>
                                                        <td>
                                                            {quotation.averageShippingTimeHours
                                                                ? `${(quotation.averageShippingTimeHours / 24).toFixed(1)} d`
                                                                : '6-12'
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={styles.rightPanel}>
                {selectedQuotation ? (
                    <div className={styles.detailView}>
                        <h2>Quotation Details</h2>
                        <div className={styles.requestDetails}>
                            {selectedQuotation.image && (
                                <div className={styles.imageContainer}>
                                    <img
                                        src={`/uploads/productImages/${selectedQuotation.image}`}
                                        alt={selectedQuotation.productName}
                                        className={styles.productImage}
                                    />
                                </div>
                            )}
                            <div className={styles.detailsContainer}>
                                <p><strong>Supplier:</strong> {selectedQuotation.supplierName}</p>
                                <p><strong>Comments:</strong> {selectedQuotation.comments || 'N/A'}</p>
                            </div>
                        </div>
                        <div className={styles.additionalDetails}>
                            {selectedQuotation.hasVariants ? (
                                <div>
                                    <h3>Variants:</h3>
                                    <table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>SKU</th>
                                                <th>Categories</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedQuotation.variants.map((variant) => (
                                                <tr key={variant.id}>
                                                    <td>
                                                        {variant.image && (
                                                            <img
                                                                src={`/uploads/productImages/${variant.image}`}
                                                                className={styles.variantImage}
                                                                onClick={() => viewImage(`/uploads/productImages/${variant.image}`)}
                                                                alt={variant.image}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>{variant.SKU}</td>
                                                    <td>
                                                        {joinCategoryVariants(variant.categoryVariants)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div>
                                    <p><strong>SKU:</strong> {selectedQuotation.noVariant.SKU}</p>
                                    {selectedQuotation.noVariant.image && (
                                        <img
                                            src={`/uploads/productImages/${selectedQuotation.noVariant.image}`}
                                            alt={selectedQuotation.noVariant.image}
                                            className={styles.noVariantImage}
                                            onClick={() => viewImage(`/uploads/productImages/${selectedQuotation.noVariant.image}`)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.actions}>
                            {request.status === "Responded" && !expiring &&
                                <button onClick={() => chooseQuotation(selectedQuotation.id)} className={styles.actionsButton}>
                                    {loadingQuotationId === selectedQuotation.id ? <LoadingButtonDots /> : 'Select'}
                                </button>
                            }
                            {request.status === "Confirmed" && request.selectedQuotationId === selectedQuotation.id &&
                                <button onClick={() => addToProducts(selectedQuotation.id)} className={styles.actionsButton}>
                                    {loadingQuotationId === selectedQuotation.id ? <LoadingButtonDots /> : 'Add to Products'}
                                </button>
                            }
                        </div>
                    </div>
                ) : (
                    <div className={styles.noSelection}>
                        <p>Select a quotation from the table on the left to view details here.</p>
                    </div>
                )}
            </div>

            {/* Modals för bildvisning */}
            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.imageModal}
                overlayClassName={styles.overlay}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>

        </div>
    );

}

export default QuotationList;
