import React, { useState, useEffect, useRef } from "react";
import styles from "../CreateQuotation.module.scss";
import Modal from "react-modal";
import supplierService from "../../../services/supplierService";
import { useNavigate } from "react-router-dom";
import LoadingButtonDots from '../../../components/LoadingButtonDots';
import {v4 as uuidv4} from 'uuid';

const VariantForm = ({
  request,
  existingQuotation,
  quotationExists,
  supplier,
}) => {
  const [variantFormData, setVariantFormData] = useState({
    comments: "",
    Variants: {
      VariantCategories: [],
    },
  });
  const [tableRows, setTableRows] = useState([]);
  const [editVariantData, setEditVariantData] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const fileInputRefs = useRef([]);
  const [formErrors, setFormErrors] = useState({});
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [isLoadingExistingData, setIsLoadingExistingData] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showEditVariantModal, setShowEditVariantModal] = useState(false);

  useEffect(() => {
    // When the component mounts or when the request changes
    if (request && request.SourcingRequestCategories) {
      // Map the SourcingRequestCategories to the format we need
      const variantCategories = request.SourcingRequestCategories.map((category) => ({
        name: category.name.toLowerCase(), // Convert to lowercase
        values: category.SourcingRequestCategoryValues.map((value) => value.value.toLowerCase()), // Convert values to lowercase
      }));

      setVariantFormData((prev) => ({
        ...prev,
        Variants: {
          ...prev.Variants,
          VariantCategories: variantCategories,
        },
      }));

      // Generate initial table rows based on these variants
      if (!quotationExists) {
        setTableRows(generateTableRows(variantCategories));
      }
    }
  }, [request]);

  useEffect(() => {
    if (
      quotationExists &&
      existingQuotation &&
      variantFormData.Variants.VariantCategories.length > 0
    ) {
      setIsLoadingExistingData(true);
      loadExistingQuotation();
    }
  }, [quotationExists, existingQuotation, variantFormData.Variants.VariantCategories]);

  const loadExistingQuotation = () => {
    const { comments, QuotationVariants } = existingQuotation;
    setVariantFormData((prev) => ({
      ...prev,
      comments: comments || "",
    }));

    // The variant categories are already set from the request
    const variantCategories = variantFormData.Variants.VariantCategories;

    // Generate initial tableRows based on the variant categories
    const initialTableRows = generateTableRows(variantCategories);

    // Now, update the tableRows with existing quotation data
    const updatedRows = initialTableRows.map((row) => {
      // Find the matching variant in QuotationVariants
      const matchingVariant = QuotationVariants.find((variant) => {
        // Build a map of category names and values from the variant
        const variantCategoryMap = variant.QuotationCategoryVariants.reduce((acc, categoryVariant) => {
          const categoryName = categoryVariant.QuotationCategory.name.toLowerCase();
          const value = categoryVariant.value.toLowerCase();
          acc[categoryName] = value;
          return acc;
        }, {});

        // Compare the categories in the row with those in the variant
        return variantCategories.every((category) => {
          const categoryName = category.name.toLowerCase();
          const rowValue = row[categoryName].toLowerCase();
          const variantValue = variantCategoryMap[categoryName];
          return rowValue === variantValue;
        });
      });

      if (matchingVariant) {
        // Update the row with existing data
        const shippingData = {};
        matchingVariant.QuotationVariantShippingPrices.forEach((shipping) => {
          shippingData[shipping.country] = {
            shippingPrice: shipping.shippingPrice.toString(),
            shippingProfit: "0", // Set profit to 0
            yourShippingPrice: shipping.shippingPrice.toString(), // Since profit is 0
          };
        });

        return {
          ...row,
          SKU: matchingVariant.SKU,
          image: matchingVariant.image,
          productPrice: matchingVariant.productPrice.toString(),
          productProfit: "0",
          yourProductPrice: matchingVariant.productPrice.toString(),
          shippingData,
          isRemoved: false, // Ensure the variant is not marked as removed
        };
      } else {
        // If no matching variant, mark as removed
        return {
          ...row,
          isRemoved: true, // Mark as removed
        };
      }
    });

    setTableRows(updatedRows);
    setIsLoadingExistingData(false);
  };

  const handleVariantInputChange = (e) => {
    const { name, value } = e.target;
    setVariantFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateVariantForm = () => {
    const tableErrors = tableRows.map((row) => {
      const errors = {};
      if (row.isRemoved) {
        return errors; // Skip validation for removed rows
      }
      // Validate SKU
      if (!row.SKU) {
        errors.SKU = "SKU is required";
      }
      // Validate Product Price
      if (!row.productPrice || parseFloat(row.productPrice) <= 0) {
        errors.productPrice =
          "Product Price is required and must be greater than 0";
      }
      // Validate Image
      if (!row.image) {
        errors.image = "Image is required";
      }
      // Validate shipping data per country
      const shippingDataErrors = {};
      request.SourcingCountries.forEach((country) => {
        const shipping = row.shippingData[country.country];
        if (
          !shipping ||
          !shipping.shippingPrice ||
          parseFloat(shipping.shippingPrice) <= 0
        ) {
          shippingDataErrors[country.country] = {
            shippingPrice: "Required and must be greater than 0",
          };
        }
      });

      if (Object.keys(shippingDataErrors).length > 0) {
        errors.shippingData = shippingDataErrors;
      }

      return errors;
    });

    // Check for errors
    const hasErrors = tableErrors.some(
      (rowErrors, index) => !tableRows[index].isRemoved && Object.keys(rowErrors).length > 0
    );

    setFormErrors({
      table: tableErrors,
    });

    return !hasErrors;
  };

  const handleEditSelectedVariants = () => {
    const selectedRows = tableRows.filter((row) => row.isSelected && !row.isRemoved);
    if (selectedRows.length === 0) {
      alert("Please select at least one row to edit.");
      return;
    }

    // Reset selectedRowIndex to null since we're editing multiple variants
    setSelectedRowIndex(null);

    // Determine initial values for the modal inputs
    const initialEditData = {
      productPrice: getCommonValue(selectedRows.map((row) => row.productPrice)),
      productProfit: "0", // Set profit to 0
      yourProductPrice: getCommonValue(selectedRows.map((row) => row.yourProductPrice)),
      shippingData: {},
      image: null,
      selectedCountry: request.SourcingCountries[0] || null,
    };

    // Initialize shippingData for each country
    request.SourcingCountries.forEach((country) => {
      initialEditData.shippingData[country.country] = {
        shippingPrice: getCommonValue(
          selectedRows.map(
            (row) => row.shippingData[country.country]?.shippingPrice
          )
        ),
        shippingProfit: "0", // Set profit to 0
        yourShippingPrice: getCommonValue(
          selectedRows.map(
            (row) => row.shippingData[country.country]?.yourShippingPrice
          )
        ),
      };
    });

    setEditVariantData(initialEditData);
    setShowEditVariantModal(true);
  };

  const getCommonValue = (values) => {
    const firstValue = values[0];
    if (values.every((value) => value === firstValue)) {
      return firstValue;
    } else {
      return "";
    }
  };

  const handleEditVariant = (index) => {
    setSelectedRowIndex(index);
    const row = tableRows[index];
    setEditVariantData({
      productPrice: row.productPrice,
      productProfit: "0", // Set profit to 0
      yourProductPrice: row.productPrice, // Since profit is 0
      shippingData: row.shippingData,
      image: row.image,
      selectedCountry: request.SourcingCountries[0] || null,
    });
    setShowEditVariantModal(true);
  };

  const handleVariantFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const updatedRows = [...tableRows];
      updatedRows[index].image = file;
      setTableRows(updatedRows);
    } else {
      const updatedRows = [...tableRows];
      updatedRows[index].image = null;
      setTableRows(updatedRows);
      alert("Please select a valid image file.");
    }
  };

  const generateVariantData = () => {
    // Exclude removed variants
    const variants = tableRows
      .filter((row) => !row.isRemoved)
      .map((row) => {
        const {
          isSelected,
          yourProductPrice,
          yourShippingPrice,
          showShippingData,
          selectedCountry,
          ...rest
        } = row;
        return rest;
      });
    return { variants, comments: variantFormData.comments };
  };

  const generateTableRows = (variantCategories) => {
    if (variantCategories.length === 0) return [];

    const variantCombinations = variantCategories.reduce(
      (acc, variantCategory) => {
        if (acc.length === 0)
          return variantCategory.values.map((value) => ({
            [variantCategory.name.toLowerCase()]: value.toLowerCase(), // Ensure keys and values are lowercase
          }));
        return acc.flatMap((prev) =>
          variantCategory.values.map((value) => ({
            ...prev,
            [variantCategory.name.toLowerCase()]: value.toLowerCase(), // Ensure keys and values are lowercase
          }))
        );
      },
      []
    );

    const initialTableRows = variantCombinations.map((combination) => {
      // Initialize shippingData for each country
      const shippingData = {};
      request.SourcingCountries.forEach((country) => {
        shippingData[country.country] = {
          shippingPrice: "",
          shippingProfit: "0", // Set profit to 0
          yourShippingPrice: "", // Since profit is 0
        };
      });

      return {
        ...combination,
        SKU: "",
        image: null,
        productPrice: "",
        productProfit: "0", // Set profit to 0
        yourProductPrice: "", // Since profit is 0
        shippingData,
        showShippingData: false,
        isSelected: false,
        isRemoved: false, // New property to track if the variant is removed
      };
    });

    return initialTableRows;
  };

  const handleRemoveVariant = (index) => {
    const updatedRows = [...tableRows];
    updatedRows[index].isRemoved = !updatedRows[index].isRemoved; // Toggle isRemoved
    setTableRows(updatedRows);
  };

  const handleConfirmEditVariant = () => {
    const updatedRows = tableRows.map((row, idx) => {
      if (selectedRowIndex !== null) {
        // Editing a single row
        if (idx === selectedRowIndex) {
          return {
            ...row,
            productPrice:
              editVariantData.productPrice !== ""
                ? editVariantData.productPrice
                : row.productPrice,
            productProfit: "0",
            yourProductPrice: editVariantData.productPrice,
            shippingData: {
              ...row.shippingData,
              ...editVariantData.shippingData,
            },
            image:
              editVariantData.image !== null ? editVariantData.image : row.image,
          };
        }
      } else if (row.isSelected && !row.isRemoved) {
        // Editing multiple selected rows
        const updatedShippingData = {
          ...row.shippingData,
          ...editVariantData.shippingData,
        };

        const updatedRow = {
          ...row,
          productPrice:
            editVariantData.productPrice !== ""
              ? editVariantData.productPrice
              : row.productPrice,
          productProfit: "0",
          yourProductPrice: editVariantData.productPrice,
          shippingData: updatedShippingData,
        };

        if (editVariantData.image) {
          updatedRow.image = editVariantData.image;
        }

        return updatedRow;
      }
      return row;
    });

    setTableRows(updatedRows);
    setShowEditVariantModal(false);
    setSelectedRowIndex(null);
  };

  const handleRowSelect = (index) => {
    const updatedRows = [...tableRows];
    updatedRows[index].isSelected = !updatedRows[index].isSelected;
    setTableRows(updatedRows);

    const allSelected = updatedRows.every((row) => row.isSelected || row.isRemoved);
    setAllRowsSelected(allSelected);
  };

  const handleSelectAll = () => {
    const selectAll = !allRowsSelected;
    const updatedRows = tableRows.map((row) => ({
      ...row,
      isSelected: row.isRemoved ? false : selectAll,
    }));
    setTableRows(updatedRows);
    setAllRowsSelected(selectAll);
  };


  const createVariantFormData = (variants, defaultComments) => {
    const data = new FormData();
    const uploadedImagesMap = new Map();

    // Prepare variants for serialization
    const preparedVariants = variants.map((variant) => {
      const preparedVariant = {
        SKU: variant.SKU,
        productPrice: variant.productPrice,
        productProfit: "0", // Set profit to 0
        yourProductPrice: variant.productPrice, // Since profit is 0
        shippingData: variant.shippingData,
        categories: {},
      };

      // Handle categories
      Object.entries(variant).forEach(([key, value]) => {
        if (
          ![
            'SKU',
            'productPrice',
            'productProfit',
            'image',
            'shippingData',
            'isSelected',
            'yourProductPrice',
            'showShippingData',
            'selectedCountry',
            'isRemoved',
          ].includes(key)
        ) {
          preparedVariant.categories[key] = value;
        }
      });

      // Handle images
      if (variant.image) {
        if (typeof variant.image === 'string') {
          // Existing image URL or filename
          preparedVariant.imageUrl = variant.image;
        } else {
          const imageFile = variant.image;

          // Check if this image has already been added
          if (uploadedImagesMap.has(imageFile.name)) {
            // Reuse the field name from earlier upload
            preparedVariant.image = uploadedImagesMap.get(imageFile.name);
          } else {
            // Use a fixed field name for the image
            const fieldName = `image_${uploadedImagesMap.size}`; // Use unique names
            data.append(fieldName, imageFile); // Add the image to FormData
            preparedVariant.image = fieldName; // Use this field name for this variant
            uploadedImagesMap.set(imageFile.name, fieldName); // Add to mapping to reuse later
          }
        }
      }

      return preparedVariant;
    });

    // Serialize the prepared variants array
    data.append('variants', JSON.stringify(preparedVariants));
    data.append('comments', defaultComments);
    data.append('existingQuotationId', existingQuotation?.id || ''); // Include the quotation ID for updates
    data.append('requestId', request.id);

    return data;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!validateVariantForm()) {
      return;
    }
    const modalData = generateVariantData();
    setShowModal(true);
    setVariantFormData((prev) => ({
      ...prev,
      modalData,
    }));
  };

  const handleModalConfirm = async () => {
    try {
      setUploading(true);
      const formDataToSend = createVariantFormData(
        variantFormData.modalData.variants,
        variantFormData.comments
      );
      await supplierService.createQuotation(formDataToSend);
      setUploading(false);
      navigate("/supplier/sourcing");
    } catch (error) {
      console.error("Error submitting form: ", error);
      setUploading(false);
    }
  };

  const handleEditVariantPriceChange = (e) => {
    const { name, value } = e.target;
    setEditVariantData((prev) => {
      const updatedValues = {
        ...prev,
        [name]: value,
      };

      // Since profit is always 0, we don't need to calculate it
      if (name === "productPrice") {
        updatedValues.yourProductPrice = value; // Your product price equals product price
      }

      return updatedValues;
    });
  };

  const handleEditVariantShippingChange = (countryName, e) => {
    const { name, value } = e.target;
    setEditVariantData((prev) => {
      const shippingData = {
        ...prev.shippingData,
        [countryName]: {
          ...prev.shippingData[countryName],
          [name]: value,
        },
      };

      // Since shippingProfit is always 0, we don't need to calculate it
      if (name === "shippingPrice") {
        shippingData[countryName].yourShippingPrice = value; // Your shipping price equals shipping price
      }

      return { ...prev, shippingData };
    });
  };

  const handleToggleShippingData = (index) => {
    setTableRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index].showShippingData = !newRows[index].showShippingData;
      return newRows;
    });
  };

  const generateRandomSKU = () => {
    const prefix = request.productName.split(" ")[0]; // En fast prefix, kanske produkttyp, kategori eller varumärke
    const randomPart = Math.random().toString(36).substring(2, 7).toUpperCase(); // Slumpmässiga bokstäver och siffror
    const timestamp = Date.now().toString().slice(-5); // Några siffror från tidsstämpel för att göra det unikt
  
    return`${prefix}-${randomPart}-${timestamp}`;
  };

  return (
    <>
      <form onSubmit={handleFormSubmit} className={styles.form}>
        <div className={styles.basicFormContainer}>
          <div>
            Note
            <textarea
              name="comments"
              value={variantFormData.comments}
              onChange={handleVariantInputChange}
            ></textarea>
          </div>
        </div>
        {variantFormData.Variants.VariantCategories.length > 0 &&
          tableRows.length > 0 && (
            <div>
              <div className={styles.bulkActions}>
                <button
                  type="button"
                  onClick={handleEditSelectedVariants}
                  disabled={!tableRows.some((row) => row.isSelected && !row.isRemoved)}
                  className={styles.editSelectedBtn}
                >
                  <span className="material-symbols-outlined">edit</span>
                  Edit Selected
                </button>
              </div>
              <div className={styles.categoryTable}>
                <table className={styles.table}>
                  <thead className={styles.mainThead}>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={allRowsSelected}
                        />
                      </th>
                      {variantFormData.Variants.VariantCategories.map(
                        (variantCategory, index) => (
                          <th key={index}>{variantCategory.name}</th>
                        )
                      )}
                      <th>SKU</th>
                      <th>Image</th>
                      <th>Product Price</th>
                      <th>Product Profit</th>
                      <th>Your Product Price</th>
                      <th>Shipping</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRows.map((row, index) => {
                      const rowErrors = formErrors.table
                        ? formErrors.table[index]
                        : {};
                      const hasRowErrors =
                        rowErrors && Object.keys(rowErrors).length > 0;
                      const hasShippingDataErrors =
                        rowErrors.shippingData &&
                        Object.keys(rowErrors.shippingData).length > 0;
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={`
                              ${!row.showShippingData ? styles.rows : ""}
                              ${hasRowErrors ? styles.errorRow : ""}
                              ${row.isRemoved ? styles.removedRow : ""}
                            `}
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={row.isSelected || false}
                                onChange={() => handleRowSelect(index)}
                                disabled={row.isRemoved}
                              />
                            </td>
                            {variantFormData.Variants.VariantCategories.map(
                              (variantCategory, i) => (
                                <td key={i}>
                                  {row[variantCategory.name.toLowerCase()]}
                                </td>
                              )
                            )}
                            {/* SKU */}
                            <td>
                              <div className={styles.tableSKU}>
                              <input
                                type="text"
                                name={`SKU-${index}`}
                                value={row.SKU}
                                onChange={(e) => {
                                  const updatedRows = [...tableRows];
                                  updatedRows[index].SKU = e.target.value;
                                  setTableRows(updatedRows);
                                }}
                                required
                                className={rowErrors.SKU ? styles.inputError : ""}
                                disabled={row.isRemoved}
                              />
                              <button
                                onClick={() => {
                                  const updatedRows = [...tableRows];
                                  updatedRows[index].SKU = generateRandomSKU(); // Generate random SKU
                                  setTableRows(updatedRows);
                                }}
                                type="button"
                                disabled={row.isRemoved}
                              >
                                Generate
                              </button>
                              </div>
                            </td>
                            {/* Image */}
                            <td>
                              <div
                                className={`${styles.imageCell} ${
                                  rowErrors.image ? styles.imgError : ""
                                }`}
                              >
                                <label>
                                  <input
                                    type="file"
                                    name={`image-${index}`}
                                    onChange={(e) =>
                                      handleVariantFileChange(index, e)
                                    }
                                    accept="image/*"
                                    ref={(el) =>
                                      (fileInputRefs.current[index] = el)
                                    }
                                    className={styles.uploadFileLogo}
                                    disabled={row.isRemoved}
                                  />
                                  <span className="material-symbols-outlined">
                                    download
                                  </span>
                                  {row.image && (
                                    <img
                                      src={
                                        typeof row.image === "string"
                                          ? `/uploads/productImages/${row.image}`
                                          : URL.createObjectURL(row.image)
                                      }
                                      alt={`Variant ${index}`}
                                      className={styles.imagePreview}
                                    />
                                  )}
                                  {row.image && typeof row.image !== "string" && (
                                    <div className={styles.fileName}>
                                      {row.image.name}
                                    </div>
                                  )}
                                </label>
                              </div>
                            </td>
                            {/* Product Price */}
                            <td>
                              <div
                                className={`${styles.priceField} ${
                                  rowErrors.productPrice ? styles.errorText : ""
                                }`}
                              >
                                {row.productPrice !== ""
                                  ? `€${row.productPrice}`
                                  : "-"}
                              </div>
                            </td>
                            {/* Product Profit */}
                            <td>
                              <div className={styles.priceField}>€0</div>
                            </td>
                            {/* Your Product Price */}
                            <td>
                              <div className={styles.priceField}>
                                {row.yourProductPrice !== ""
                                  ? `€${row.yourProductPrice}`
                                  : "-"}
                              </div>
                            </td>
                            {/* Toggle Shipping Data */}
                            <td>
                              <button
                                type="button"
                                onClick={() => handleToggleShippingData(index)}
                                className={`${
                                  hasShippingDataErrors
                                    ? styles.errorButton
                                    : ""
                                } ${styles.toggleShippingBtn} `}
                                disabled={row.isRemoved}
                              >
                                {row.showShippingData ? "-" : "+"}
                              </button>
                            </td>
                            {/* Actions */}
                            <td>
                              <button
                                type="button"
                                onClick={() => handleEditVariant(index)}
                                className={styles.editBtn}
                                disabled={row.isRemoved}
                              >
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </button>
                              <button
                                type="button"
                                onClick={() => handleRemoveVariant(index)}
                                className={styles.removeVariantBtn}
                              >
                                {row.isRemoved ? "+" : "×"}
                              </button>
                            </td>
                          </tr>
                          {row.showShippingData && !row.isRemoved && (
                            <tr className={styles.detailRow}>
                              <td
                                colSpan={
                                  1 +
                                  variantFormData.Variants.VariantCategories.length +
                                  7
                                }
                              >
                                {/* Render shipping data table */}
                                <table className={styles.shippingTable}>
                                  <thead>
                                    <tr>
                                      <th>Country</th>
                                      <th>Shipping Price</th>
                                      <th>Shipping Profit</th>
                                      <th>Your Shipping Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {request.SourcingCountries.map((country) => {
                                      const shipping =
                                        row.shippingData[country.country];
                                      const shippingErrors =
                                        rowErrors.shippingData &&
                                        rowErrors.shippingData[country.country];
                                      return (
                                        <tr key={country.country}>
                                          <td>{country.country}</td>
                                          <td>
                                            <div
                                              className={`${styles.priceField} ${
                                                shippingErrors?.shippingPrice
                                                  ? styles.errorText
                                                  : ""
                                              }`}
                                            >
                                              {shipping.shippingPrice !== ""
                                                ? `€${shipping.shippingPrice}`
                                                : "-"}
                                            </div>
                                          </td>
                                          <td>
                                            <div className={styles.priceField}>
                                              €0
                                            </div>
                                          </td>
                                          <td>
                                            <div className={styles.priceField}>
                                              {shipping.yourShippingPrice !== ""
                                                ? `€${shipping.yourShippingPrice}`
                                                : "-"}
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {tableRows.length > 0 && (
                <button type="submit" className={styles.submitButton}>
                  View and Submit
                </button>
              )}
            </div>
          )}
      </form>
      {/* Edit Variant Modal */}
      <Modal
        isOpen={showEditVariantModal}
        onRequestClose={() => setShowEditVariantModal(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <h2>
            Edit Prices for{" "}
            {selectedRowIndex !== null ? "Variant" : "Selected Variants"}
          </h2>
          <p className={styles.profitVariant}>
            Qiwi takes {supplier.provisionProcentage}% of the "Product Profit" and
            "Shipping Profit"
          </p>
          {selectedRowIndex === null && (
            <p className={styles.groupEditInfo}>
              Fields left blank will not overwrite existing values.
            </p>
          )}
          <div className={styles.pricesInput}>
            <div>
              Product Price
              <div className={styles.inputWithPrefix}>
                <span className={styles.prefix}>€</span>
                <input
                  type="number"
                  name="productPrice"
                  step="0.01"
                  value={editVariantData?.productPrice}
                  onChange={handleEditVariantPriceChange}
                  min="0"
                  className={
                    formErrors.modal && formErrors.modal.productPrice
                      ? styles.inputError
                      : ""
                  }
                />
              </div>
            </div>
            <div className={styles.profitInputs}>
              <div>
                Product Profit
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>+€</span>
                  <input
                    type="number"
                    name="productProfit"
                    step="0.01"
                    value="0" // Set profit to 0
                    disabled={true}
                    onChange={() => {}} // No change allowed
                    min="0"
                  />
                </div>
              </div>
              <div className={styles.syncIcon}>
                <span className="material-symbols-outlined">sync_alt</span>
              </div>
              <div>
                Your Product Price
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>€</span>
                  <input
                    type="number"
                    name="yourProductPrice"
                    step="0.01"
                    value={editVariantData?.yourProductPrice}
                    disabled={true}
                    onChange={() => {}} // No change allowed
                    min="0"
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedRowIndex === null && (
            <div className={styles.editModalImage}>
              Product Image:
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && file.type.startsWith("image/")) {
                    setEditVariantData((prev) => ({
                      ...prev,
                      image: file,
                    }));
                  } else {
                    setEditVariantData((prev) => ({
                      ...prev,
                      image: null,
                    }));
                    alert("Please select a valid image file.");
                  }
                }}
              />
            </div>
          )}

          {/* Country Selector */}
          <div className={styles.pricesInput}>
            <div className={styles.countrySelector}>
              {request.SourcingCountries.map((country) => {
                const shippingData =
                  editVariantData?.shippingData[country.country] || {};
                const hasShippingData =
                  parseFloat(shippingData.shippingPrice) > 0 &&
                  parseFloat(shippingData.shippingProfit) >= 0 &&
                  parseFloat(shippingData.yourShippingPrice) >= 0;
                const hasErrors = false; // Implement error checking if needed

                return (
                  <button
                    key={country.country}
                    className={`
                      ${styles.countryButton} 
                      ${
                        editVariantData?.selectedCountry?.country === country.country
                          ? styles.selectedCountry
                          : ""
                      }
                      ${
                        hasShippingData ? styles.countryDone : styles.countryNotDone
                      }
                      ${hasErrors ? styles.errorButton : ""}
                    `}
                    type="button"
                    onClick={() =>
                      setEditVariantData((prev) => ({
                        ...prev,
                        selectedCountry: country,
                      }))
                    }
                  >
                    {country.country}
                  </button>
                );
              })}
            </div>

            <div>
              Shipping Price
              <div className={styles.inputWithPrefix}>
                <span className={styles.prefix}>€</span>
                <input
                  type="number"
                  name="shippingPrice"
                  step="0.01"
                  value={
                    editVariantData?.shippingData[
                      editVariantData.selectedCountry?.country
                    ]?.shippingPrice || ""
                  }
                  onChange={(e) =>
                    handleEditVariantShippingChange(
                      editVariantData.selectedCountry?.country,
                      e
                    )
                  }
                  min="0"
                />
              </div>
            </div>
            <div className={styles.profitInputs}>
              <div>
                Shipping Profit
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>+€</span>
                  <input
                    type="number"
                    name="shippingProfit"
                    step="0.01"
                    value="0" // Set profit to 0
                    disabled={true}
                    onChange={() => {}} // No change allowed
                    min="0"
                  />
                </div>
              </div>
              <div className={styles.syncIcon}>
                <span className="material-symbols-outlined">sync_alt</span>
              </div>
              <div>
                Your Shipping Price
                <div className={styles.inputWithPrefix}>
                  <span className={styles.prefix}>€</span>
                  <input
                    type="number"
                    name="yourShippingPrice"
                    step="0.01"
                    value={
                      editVariantData?.shippingData[
                        editVariantData.selectedCountry?.country
                      ]?.yourShippingPrice || ""
                    }
                    disabled={true}
                    onChange={() => {}} // No change allowed
                    min="0"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.variantButtonGroup}>
            <button
              type="button"
              onClick={handleConfirmEditVariant}
              className={styles.submitButton}
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => setShowEditVariantModal(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          if (!uploading) {
            setShowModal(false);
          }
        }}
        className={styles.quotationModal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.quotationsModalContainer}>
          <h2>Confirm Your Quotation</h2>
          {variantFormData.modalData && (
            <>
              {variantFormData.modalData.variants.map((variant, index) => (
                <div key={index} className={styles.variantModalItem}>
                  <div className={styles.variantMainInfo}>
                    <div className={styles.imageContainer}>
                      {variant.image && (
                        <img
                          src={
                            typeof variant.image === "string"
                              ? `/uploads/productImages/${variant.image}`
                              : URL.createObjectURL(variant.image)
                          }
                          alt={`Variant ${index}`}
                        />
                      )}
                    </div>
                    <div className={styles.detailsContainer}>
                      <p>
                        <strong>SKU:</strong> {variant.SKU}
                      </p>
                      <p>
                        <strong>Product Price:</strong> €{variant.productPrice}
                      </p>
                      <p>
                        <strong>Product Profit:</strong> €0
                      </p>
                      <p>
                        <strong>Your Product Price:</strong> €
                        {parseFloat(variant.productPrice)}
                      </p>
                      {Object.keys(variant)
                        .filter(
                          (key) =>
                            ![
                              "SKU",
                              "productPrice",
                              "productProfit",
                              "image",
                              "shippingData",
                              "yourProductPrice",
                            ].includes(key)
                        )
                        .map((key) => (
                          <p key={key}>
                            <strong>{key}:</strong> {variant[key]}
                          </p>
                        ))}
                    </div>
                  </div>
                  <h3>Shipping Data</h3>
                  <div className={styles.shippingDataContainer}>
                    {request.SourcingCountries.map((country) => {
                      const shipping = variant.shippingData[country.country];

                      return (
                        <div key={country.id} className={styles.shippingData}>
                          <h4>{country.country}</h4>
                          <p>
                            <strong>Shipping Price:</strong> €
                            {shipping.shippingPrice}
                          </p>
                          <p>
                            <strong>Shipping Profit:</strong> €0
                          </p>
                          <p>
                            <strong>Your Shipping Price:</strong> €
                            {parseFloat(shipping.shippingPrice)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          )}
          {variantFormData.comments && (
            <p className={styles.modalComment}>
              {" "}
              <strong>Comments:</strong> {variantFormData.comments}
            </p>
          )}
          <div className={styles.buttonGroup}>
            <button
              onClick={handleModalConfirm}
              className={styles.submitButton}
            >
              {uploading ? (
                <LoadingButtonDots />
              ) : `Submit ${quotationExists ? "Edit" : ""}`}
            </button>
            <button
              disabled={uploading}
              onClick={() => setShowModal(false)}
              className={styles.cancelButton}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VariantForm;
