import styles from './Dashboard.module.scss';
import merchantService from '../../services/merchantService';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importera datalabels plugin

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard | Qiwi';
  }, []);

  const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const fetchDashboardInfo = async () => {
    try {
      setLoadingInitialInfo(true);
      const response = await merchantService.fetchDashboardInfo();
      setDashboardInfo(response);
      setGraphData(response.graphData || []); // Sätt tom array om graphData är null
      setLoadingInitialInfo(false);
    } catch (error) {
      setDashboardInfo([]);
      setGraphData([]); // Sätt tom array om felet uppstår
    }
  };

  useEffect(() => {
    fetchDashboardInfo();
  }, []);

  // Skapa data för grafen
  const createGraphData = () => {
    if (!graphData || graphData.length === 0) return null; // Kontrollera att graphData inte är tomt eller null

    // Sortera graphData baserat på datum i stigande ordning
    const sortedGraphData = [...graphData].sort((a, b) => new Date(a.date) - new Date(b.date));
  
    const labels = sortedGraphData.map(data => data.date); // Datum som labels
    const ordersCount = sortedGraphData.map(data => data.ordersCount); // Antal ordrar per dag
    const itemsCount = sortedGraphData.map(data => data.itemsCount); // Antal order-items per dag
  
  
    return {
      labels,
      datasets: [
        {
          label: 'Order Quantity',
          data: ordersCount,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          pointBackgroundColor: '#fff',
          borderWidth: 3,
          fill: true,
        },
        {
          label: 'Order Items Quantity',
          data: itemsCount,
          borderColor: 'rgba(153, 102, 255, 1)',
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          pointBackgroundColor: '#fff',
          borderWidth: 3,
          fill: true,
        },
      ],
    };
  };

  const graphOptions = {
    responsive: true,
    hover: { mode: null }, // Inaktiverar hover
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top', // Placerar legenden högst upp
        align: 'end', // Flyttar legenden till höger
        labels: {
          font: {
            size: 12,
          },
          usePointStyle: true,
        },
      },
      datalabels: {
        align: 'top',
        color: '#555',
        font: {
          size: 12,
        },
        formatter: (value) => value,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: graphData && graphData.length > 0 
          ? Math.max(...graphData.map(data => Math.max(data.ordersCount, data.itemsCount))) + 2
          : 10, // Höjer grafen lite över största värdet, fallback till 10 om graphData är tomt
        ticks: {
          stepSize: 1,
          padding: 6, // Lägg till padding mellan y-axelns värden och själva axeln
        },
        grid: {
          drawOnChartArea: true, // Behåll horisontella streck (gridlines)
          drawTicks: false,
          color: '#e0e0e0', // Ljusgrå färg på gridlinjer
        },
        border: {
          display: false, // Detta tar bort själva Y-axeln (den vertikala linjen)
        },
      },
      x: {
        grid: {
          display: false, // Ta bort vertikala streck
        },
        ticks: {
          autoSkip: false,
          padding: 2, // Lägg till padding mellan x-axelns värden och själva axeln
        },
        offset: true, // Skapar mellanrum mellan axel och datapunkterna
      },
    },
    elements: {
      line: {
        tension: 0.4, // För att få en mjukare linje
      },
      point: {
        radius: 4, // Mindre prickar
        hoverRadius: 4, // Samma storlek vid hover för att undvika förändring
        borderWidth: 2, // Bredd på border för outlined effekt
        backgroundColor: 'transparent', // Ingen fyllning, bara en outline
        borderColor: '#555', // Sätter outlinefärg för prickarna
      },
    },
  };
  
  
  
  return (
    <div className={styles.dashboard}>
      <div className={styles.infoContainer}>
      <div className={`${styles.infoboxYellow} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          assignment_add
        </span>
      </div>
      <div className={styles.info}>
        <h2>{dashboardInfo.ordersCreatedToday}</h2>
        <p>NEW ORDERS</p>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoboxGreen} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          request_quote
        </span>
      </div>
      <div className={styles.info}>
        <h2>{dashboardInfo.awaitingPaymentOrders}</h2>
        <p>AWAITING PAYMENT</p>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoboxBlue} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          local_shipping
        </span>
      </div>
      <div className={styles.info}>
        <h2>{dashboardInfo.handledOrders}</h2>
        <p>FULFILLED ORDERS</p>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoboxOrange} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          wallet
        </span>
      </div>
      <div className={styles.info}>
        <h2>{dashboardInfo.wallet}€</h2>
        <p>WALLET</p>
      </div>
    </>
  )}
</div>


        {/* Grafen */}
        <div className={styles.graph}>
          {!loadingInitialInfo && graphData && graphData.length > 0 && (
            <Line data={createGraphData()} options={graphOptions} />
          )}
        </div>
        <div className={styles.listBox}></div>
      </div>
    </div>
  );
};

export default Dashboard;
