import axios from "axios";
import { getGlobalErrorNotification } from "../components/ErrorContext"; // Importera global access till errorNotification

const API_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleError = (error) => {
  const errorNotification = getGlobalErrorNotification(); // Hämta errorNotification globalt
  if (errorNotification) {
    errorNotification(); // Visa "Server error" vid fel
  }
  console.error("API error:", error);
  throw error; // Kasta om felet så att komponenten kan hantera det om det behövs
};

const createSupplier = async (formData) => {
  try {
    const response = await api.post("suppliers/create", formData);
    return response;
  } catch (error) {
    handleError(error);
  }
};

const fetchRequestsByPageAndFilters = async (
  statusFilter,
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await api.get(
      "/sourcing-admin/fetchSourcingRequestsByPageAndFilters",
      {
        params: {
          showArchived,
          statusFilter,
          currentPage,
          itemsPerPage,
          searchQuery,
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchUsersByPageAndFilters = async (
  roleFilter,
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await api.get("user/fetchUsersByPageAndFilters", {
      params: {
        roleFilter,
        currentPage,
        itemsPerPage,
        startDate,
        endDate,
        showArchived,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchantsByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  startDate,
  endDate
) => {
  try {
    const response = await api.get("user/fetchMerchantsByPageAndFilters", {
      params: {
        currentPage,
        itemsPerPage,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchSuppliersByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  startDate,
  endDate
) => {
  try {
    const response = await api.get("user/fetchSuppliersByPageAndFilters", {
      params: {
        currentPage,
        itemsPerPage,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchStoresByPageAndFilters = async (
  statusFilter,
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await api.get("store/fetchAllStoresByPageAndFilters", {
      params: {
        statusFilter,
        currentPage,
        itemsPerPage,
        searchQuery,
        startDate,
        endDate,
        showArchived,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchProductsByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await api.get("product/fetchProductsByPageAndFilters", {
      params: {
        currentPage,
        itemsPerPage,
        searchQuery,
        startDate,
        endDate,
        showArchived,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`user/update/${userId}`, userData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateStore = async (storeId, storeData) => {
  try {
    const response = await api.put(`store/update/${storeId}`, storeData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateOrder = async (orderId, orderData) => {
  try {
    const response = await api.put(`order/update/${orderId}`, orderData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteOrder = async (orderId) => {
  try {
    const response = await api.delete(`order/delete/${orderId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateProduct = async (productId, productData) => {
  try {
    const response = await api.put(`product/update/${productId}`, productData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchAllOrdersByPageAndFilters = async (
  statusFilter,
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await api.get("orders/fetchAllOrdersByPageAndFilters", {
      params: {
        status: statusFilter,
        pageNumber: currentPage,
        pageAmount: itemsPerPage,
        searchQuery,
        startDate,
        endDate,
        showArchived,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchAllPaymentsByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  searchQuery
) => {
  try {
    const response = await api.get("payment/fetchAllPaymentsByPageAndFilters", {
      params: { currentPage, itemsPerPage, startDate, endDate, searchQuery },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchQuotationDetails = async (requestId, showArchived) => {
  try {
    const response = await api.get(`/sourcing-admin/fetchQuotationDetails/`, {
      params: {
        showArchived,
        requestId,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const giveFeedback = async (quotationId, feedback) => {
  try {
    const response = await api.post("/sourcing-admin/giveFeedback", {
      quotationId,
      feedback,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const submitQuotations = async (requestId, selectedQuotations) => {
  try {
    const response = await api.post("/sourcing-admin/submitQuotations", {
      requestId,
      selectedQuotations,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchSupportTickets = async (
  filter,
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate
) => {
  try {
    const response = await api.get("user/fetchTicketsToAdmin", {
      params: {
        filter,
        currentPage,
        itemsPerPage,
        searchQuery,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const setTicketViewed = async (ticketId) => {
  try {
    const response = await api.put(`user/setTicketViewed`, { ticketId });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchants = async () => {
  try {
    const response = await api.get(`user/fetchMerchants/`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchantStores = async (merchantId) => {
  try {
    const response = await api.get(`store/fetchMerchantStores/${merchantId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const syncShopifyOrders = async (selectedStore, selectedMerchant) => {
  try {
    const response = await api.post("/orders/syncShopifyOrders", {
      selectedStore,
      selectedMerchant,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchOrderItems = async (orderId, showArchived) => {
  try {
    const response = await api.get(`orders/fetchOrderItems/`, {
      params: {
        showArchived,
        orderId,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchantUsers = async (merchantId, showArchived) => {
  try {
    const response = await api.get(`user/fetchMerchantUsers/`, {
      params: {
        showArchived,
        merchantId,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchSupplierUsers = async (supplierId, showArchived) => {
  try {
    const response = await api.get(`user/fetchSupplierUsers/`, {
      params: {
        showArchived,
        supplierId,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const cancelItem = async (itemId) => {
  try {
    const response = await api.delete(`orders/cancelItem/${itemId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const cancelOrder = async (orderId) => {
  try {
    const response = await api.delete(`orders/cancelOrder/${orderId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const productDeleteStatus = async (productId) => {
  try {
    const response = await api.get(`product/productDeleteStatus/${productId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const storeDeleteStatus = async (storeId) => {
  try {
    const response = await api.get(`store/storeDeleteStatus/${storeId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const supplierDeleteStatus = async (supplierId) => {
  try {
    const response = await api.get(
      `suppliers/supplierDeleteStatus/${supplierId}`
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const merchantDeleteStatus = async (merchantId) => {
  try {
    const response = await api.get(`user/merchantDeleteStatus/${merchantId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const productDelete = async (productId) => {
  try {
    const response = await api.delete(`product/productDelete/${productId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireProduct = async (productId) => {
  try {
    const response = await api.get(`product/expireProduct/${productId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const storeDelete = async (storeId) => {
  try {
    const response = await api.delete(`store/delete/${storeId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`user/delete/${userId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteSupplier = async (supplierId) => {
  try {
    const response = await api.delete(`suppliers/deleteSupplier/${supplierId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteMerchant = async (merchantId) => {
  try {
    const response = await api.delete(`user/deleteMerchant/${merchantId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireSupplier = async (supplierId) => {
  try {
    const response = await api.get(`suppliers/expireSupplier/${supplierId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireMerchant = async (merchantId) => {
  try {
    const response = await api.get(`user/expireMerchant/${merchantId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireStore = async (storeId) => {
  try {
    const response = await api.get(`store/expireStore/${storeId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const archiveRequest = async (requestId) => {
  try {
    const response = await api.delete(
      `sourcing-admin/archiveSourcing/${requestId}`
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchantsAndSuppliers = async () => {
  try {
    const response = await api.get("user/fetchMerchantsAndSuppliers");
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createUser = async (formData) => {
  try {
    const response = await api.post("user/createUser", formData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Fetch partners
const fetchPartners = async () => {
  try {
    const response = await api.get("partner");
    return response.data; // Returnerar listan över partners
  } catch (error) {
    handleError(error);
  }
};

const fetchWeeklySummary = async () => {
  try {
    const response = await api.get("suppliers/weeklySummary");
    return response.data; // Returnerar listan över partners
  } catch (error) {
    handleError(error);
  }
};

const createPartner = async (newPartnerFormData) => {
  try {
    const response = await axios.post(`${API_URL}partner`, newPartnerFormData, {
      withCredentials: true, // Detta gör att cookies skickas med om du har authentication
      headers: {
        // Låt axios hantera Content-Type automatiskt
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const setSupplierPayment = async (fulfillmentOrderIds) => {
  try {
    const response = await axios.post(
      `${API_URL}suppliers/setSupplierPayment`,
      fulfillmentOrderIds,
      {
        withCredentials: true, // Detta gör att cookies skickas med om du har authentication
        headers: {
          // Låt axios hantera Content-Type automatiskt
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// För redigering av en partner
const editPartner = async (id, updatedPartnerFormData) => {
  try {
    const response = await axios.put(
      `${API_URL}partner/${id}`,
      updatedPartnerFormData,
      {
        withCredentials: true, // Detta gör att cookies skickas med om du har authentication
        headers: {
          "Content-Type": "multipart/form-data", // Låt axios hantera FormData korrekt
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
// Delete a partner
const deletePartner = async (id) => {
  try {
    const response = await api.delete(`partner/${id}`); // Skicka en DELETE-begäran med rätt partner-id
    return response.data; // Returnerar bekräftelsen på borttagningen
  } catch (error) {
    handleError(error);
  }
};

const setMarkups = async (markupData) => {
  try {
    const response = await api.post("sourcing-admin/setMarkups", markupData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export default {
  setMarkups,
  fetchWeeklySummary,
  deletePartner,
  editPartner,
  createPartner,
  fetchPartners,
  deleteSupplier,
  expireSupplier,
  supplierDeleteStatus,
  expireMerchant,
  deleteMerchant,
  createUser,
  fetchMerchantsAndSuppliers,
  expireStore,
  storeDelete,
  storeDeleteStatus,
  archiveRequest,
  expireProduct,
  productDelete,
  productDeleteStatus,
  cancelOrder,
  cancelItem,
  fetchSuppliersByPageAndFilters,
  fetchSupplierUsers,
  fetchMerchantUsers,
  fetchMerchantsByPageAndFilters,
  fetchOrderItems,
  fetchMerchantStores,
  fetchMerchants,
  syncShopifyOrders,
  setTicketViewed,
  fetchSupportTickets,
  submitQuotations,
  giveFeedback,
  fetchQuotationDetails,
  fetchAllPaymentsByPageAndFilters,
  fetchRequestsByPageAndFilters,
  createSupplier,
  fetchUsersByPageAndFilters,
  fetchStoresByPageAndFilters,
  fetchProductsByPageAndFilters,
  updateUser,
  deleteUser,
  updateStore,
  deleteOrder,
  updateOrder,
  updateProduct,
  fetchAllOrdersByPageAndFilters,
  merchantDeleteStatus,
};
