// supplierService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const fetchSourcingDataByPageAndStatus = async ({
  status,
  pageNumber,
  pageAmount,
  searchQuery,
  startDate,
  endDate,
}) => {
  try {
    const response = await axios.get(
      API_URL + "sourcing-supplier/sourcingDataByPageAndStatus",
      {
        params: {
          status,
          pageNumber,
          pageAmount,
          searchQuery,
          startDate,
          endDate,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sourcing requests:", error);
    throw error;
  }
};

const getResponseByIdandSupplier = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}sourcing-supplier/idSourcingData/${id}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching request details:", error);
    throw error;
  }
};

const denyRequest = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}sourcing-supplier/deny`,
      {
        id,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error denying request:", error);
    throw error;
  }
};

const createQuotation = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URL}sourcing-supplier/create-quote`,
      formData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating quotation:", error);
    throw error;
  }
};

const fetchFulfillmentOrdersByPageAndFilters = async ({
  status,
  pageNumber,
  pageAmount,
  searchQuery,
  startDate,
  endDate,
  showArchived,
}) => {
  try {
    const response = await axios.get(
      `${API_URL}orders/fetchFulfillmentOrdersByPageAndFilters`,
      {
        params: {
          status,
          pageNumber,
          pageAmount,
          searchQuery,
          startDate,
          endDate,
          showArchived,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

const setShipment = async ({
  fulfillmentOrderId,
  mergeGroupId,
  trackingId,
  trackingCompany,
  adjustedShipment,
}) => {
  try {
    const requestBody = {
      fulfillmentOrderId,
      mergeGroupId,
      trackingId,
      trackingCompany,
    };

    if (adjustedShipment !== null && adjustedShipment !== undefined) {
      requestBody.adjustedShipment = adjustedShipment;
    }

    const response = await axios.put(
      `${API_URL}orders/setShipment`,
      requestBody,
      {
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error confirming shipment:", error);
    throw error;
  }
};

const checkQuotation = async (requestId) => {
  const response = await axios.get(
    `${API_URL}sourcing-supplier/check-quotation/${requestId}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const fetchSupplierOrderItems = async (order, showArchived) => {
  try {
    const params = {
      showArchived: showArchived,
    };
    if (order.mergeGroupId) {
      params.mergeGroupId = order.mergeGroupId;
    } else {
      params.fulfillmentOrderId = order.fulfillmentOrderId;
    }
    const response = await axios.get(
      `${API_URL}orders/fetchSupplierOrderItems/`,
      {
        params,
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get order items details:", error);
    throw error;
  }
};

const fetchUnhandledMergeRequestCount = async () => {
  const response = await axios.get(
    API_URL + "orders/unhandled-merge-requests-count",
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const fetchMergeRequests = async () => {
  const response = await axios.get(API_URL + "orders/merge-requests", {
    withCredentials: true,
  });
  return response.data;
};

const acceptMergeRequest = (id) => {
  return axios.post(
    `${API_URL}orders/accept-merge/${id}`,
    {},
    {
      withCredentials: true,
    }
  );
};

const denyMergeRequest = (id) => {
  return axios.post(
    `${API_URL}orders/deny-merge/${id}`,
    {},
    {
      withCredentials: true,
    }
  );
};

const getComparisonData = async (requestId) => {
  const response = await axios.get(
    `${API_URL}sourcing-supplier/getComparisonData/${requestId}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const fetchCancellationRequestCount = async () => {
  const response = await axios.get(
    API_URL + "orders/cancellation-requests-count",
    {
      withCredentials: true,
    }
  );
  return response.data;
};

const fetchCancellationRequests = async () => {
  try {
    const response = await axios.get(`${API_URL}orders/cancellation-requests`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cancellation requests:", error);
    throw error;
  }
};

const fetchSupplier = async () => {
  try {
    const response = await axios.get(`${API_URL}suppliers`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cancellation requests:", error);
    throw error;
  }
};

const fetchOrderShipment = async (fulfillmentOrderId, mergeGroupId) => {
  try {
    const response = await axios.get(
      `${API_URL}orders/fetchOrderShipment/${fulfillmentOrderId || "null"}`,
      {
        params: {
          mergeGroupId: mergeGroupId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching order shipment:", error);
    throw error;
  }
};

const acceptCancellationRequest = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}orders/accept-cancellation/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting cancellation request:", error);
    throw error;
  }
};

const denyCancellationRequest = async (id) => {
  try {
    const response = await axios.post(
      `${API_URL}orders/deny-cancellation/${id}`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error denying cancellation request:", error);
    throw error;
  }
};

const fetchUpdates = async () => {
  try {
    const response = await axios.get(`${API_URL}user/fetchUpdates`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch updates:", error);
    throw error;
  }
};

const fetchSourcingInfo = async () => {
  try {
    const response = await axios.get(
      `${API_URL}sourcing-supplier/fetchSourcingInfo`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch sourcing Info:", error);
    throw error;
  }
};

export default {
  fetchSourcingInfo,
  fetchUpdates,
  fetchOrderShipment,
  fetchCancellationRequestCount,
  fetchCancellationRequests,
  acceptCancellationRequest,
  denyCancellationRequest,
  getComparisonData,
  denyMergeRequest,
  acceptMergeRequest,
  fetchMergeRequests,
  fetchUnhandledMergeRequestCount,
  fetchSupplierOrderItems,
  checkQuotation,
  setShipment,
  fetchFulfillmentOrdersByPageAndFilters,
  fetchSourcingDataByPageAndStatus,
  getResponseByIdandSupplier,
  createQuotation,
  denyRequest,
  fetchSupplier,
};
