// CreateRequest.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dataService from '../../services/merchantService';
import styles from './CreateRequest.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingDots-komponenten
import { countries } from 'countries-list';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import AddVariantModal from './components/sourcing/AddVariantModal'; // Justera importvägen om nödvändigt

const CreateRequest = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#edeeef',
      border: 'none',
      boxShadow: 'none',
      color: '#fff',
      padding: '3px',
      minHeight: '40px',
      '&:hover': {
        border: 'none',
        cursor: 'pointer'
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: '40px',
      padding: '0 6px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: '40px',
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000', // Ändrat färg till svart för läsbarhet
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: '3px',
      borderRadius: '10px',
      backgroundColor: '#000',
      color: '#fff'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#000',
        color: '#edeeef',
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000',
      '&:hover': {
        color: '#000',
      }
    }),
  };

  useEffect(() => {
    document.title = 'Create Sourcing Request | Qiwi';
  }, []);

  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name
  }));

  const [formDataURL, setFormDataURL] = useState({
    url: '',
    productName: '',
    note: '',
    selectedCountries: [],
    averageMonthlyOrders: '',
    variants: [] // Lägg till varianter
  });

  const [formDataDetails, setFormDataDetails] = useState({
    productName: '',
    description: '',
    note: '',
    url: '',
    image: null,
    selectedCountries: [],
    averageMonthlyOrders: '',
    variants: [] // Lägg till varianter
  });

  const [showCategoryModalURL, setShowCategoryModalURL] = useState(false); // Modal för URL-formuläret
  const [showCategoryModalDetails, setShowCategoryModalDetails] = useState(false); // Modal för Details-formuläret

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);

  const navigate = useNavigate();

  // Handlers för URL-formuläret
  const handleInputChangeURL = (e) => {
    const { name, value } = e.target;
    setFormDataURL(prev => ({ ...prev, [name]: value }));
  };

  const handleCountryChangeURL = (selectedOptions) => {
    setFormDataURL(prev => ({ ...prev, selectedCountries: selectedOptions }));
  };

  // Handlers för Details-formuläret
  const handleInputChangeDetails = (e) => {
    const { name, value } = e.target;
    setFormDataDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormDataDetails(prev => ({ ...prev, image: e.target.files[0] }));
  };

  const handleCountryChangeDetails = (selectedOptions) => {
    setFormDataDetails(prev => ({ ...prev, selectedCountries: selectedOptions }));
  };

  // Handler för att öppna modalen för URL-formuläret
  const openVariantModalURL = () => {
    setShowCategoryModalURL(true);
  };

  // Handler för att öppna modalen för Details-formuläret
  const openVariantModalDetails = () => {
    setShowCategoryModalDetails(true);
  };

  // Handler för att stänga modalen för URL-formuläret
  const closeVariantModalURL = () => {
    setShowCategoryModalURL(false);
  };

  // Handler för att stänga modalen för Details-formuläret
  const closeVariantModalDetails = () => {
    setShowCategoryModalDetails(false);
  };

  const handleSaveVariantsURL = (updatedVariants) => {
    setFormDataURL(prev => ({
      ...prev,
      variants: updatedVariants
    }));
  };

  const handleSaveVariantsDetails = (updatedVariants) => {
    setFormDataDetails(prev => ({
      ...prev,
      variants: updatedVariants
    }));
  };


  const handleSubmitURL = async (e) => {
    e.preventDefault();
    if (formDataURL.selectedCountries.length === 0) {
      alert('Please select at least one country.');
      return;
    }
    setIsLoading(true);
    try {
      const requestData = {
        url: formDataURL.url,
        productName: formDataURL.productName,
        averageMonthlyOrders: formDataURL.averageMonthlyOrders,
        countries: formDataURL.selectedCountries.map(c => c.label),
        variants: Array.isArray(formDataURL.variants) ? formDataURL.variants : []
      };
      await dataService.createSourcingRequest(requestData);
      alert('Request created successfully!');
      navigate('/sourcing');
    } catch (error) {
      console.error('Failed to create request: ', error);
      alert('Failed to create request.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitDetails = async (e) => {
    e.preventDefault();
    if (formDataDetails.selectedCountries.length === 0) {
      alert('Please select at least one country.');
      return;
    }
    setIsLoadingTwo(true);
    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('productName', formDataDetails.productName);
      formDataToSubmit.append('description', formDataDetails.description);
      formDataToSubmit.append('note', formDataDetails.note);
      formDataToSubmit.append('url', formDataDetails.url);
      formDataToSubmit.append('averageMonthlyOrders', formDataDetails.averageMonthlyOrders);
      if (formDataDetails.image) {
        formDataToSubmit.append('image', formDataDetails.image);
      }
      formDataDetails.selectedCountries.forEach(country => {
        formDataToSubmit.append('countries[]', country.label);
      });
      if (Array.isArray(formDataDetails.variants)) {
        formDataToSubmit.append('variants', JSON.stringify(formDataDetails.variants));
      }
      await dataService.createSourcingRequestWithImage(formDataToSubmit);
      alert('Detailed request created successfully!');
      navigate('/sourcing');
    } catch (error) {
      console.error('Failed to create request with details: ', error);
      alert('Failed to create detailed request.');
    } finally {
      setIsLoadingTwo(false);
    }
  };

  const back = () => {
    navigate('/sourcing');
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <button onClick={back} className={styles.backButton}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to requests
        </button>
      </div>
      <div className={styles.forms}>
        {/* Formulär för URL */}
        <form onSubmit={handleSubmitURL} className={styles.formContainer}>
          <h2>Submit with URL</h2>
          <label htmlFor="countriesURL"><span style={{ color: 'red' }}>*</span> Countries
            <Select
              isMulti
              name="countries"
              options={countryOptions}
              value={formDataURL.selectedCountries}
              onChange={handleCountryChangeURL}
              className={styles.countrySelect}
              classNamePrefix="select"
              styles={customStyles}
            />
          </label>
          <label htmlFor="urlURL"><span style={{ color: 'red' }}>*</span> URL
            <input
              type="text"
              id="urlURL"
              name="url"
              value={formDataURL.url}
              onChange={handleInputChangeURL}
              required
            />
          </label>
          <label htmlFor="productNameURL"><span style={{ color: 'red' }}>*</span> Product Name
            <input
              type="text"
              id="productNameURL"
              name="productName"
              value={formDataURL.productName}
              onChange={handleInputChangeURL}
              required
            />
          </label>
          <label htmlFor="averageMonthlyOrdersURL">
            <div className={styles.avgMonthlyLabel}>
              Average Monthly Orders
              <Tooltip title="If you already have this product in your store, please specify your average orders per month" arrow>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </div>
            <input
              type="number"
              id="averageMonthlyOrdersURL"
              name="averageMonthlyOrders"
              value={formDataURL.averageMonthlyOrders || ''}
              onChange={handleInputChangeURL}
            />
          </label>
          <label htmlFor="noteURL">Note
            <textarea
              id="noteURL"
              name="note"
              value={formDataURL.note}
              onChange={handleInputChangeURL}
            />
          </label>
          <div className={styles.addVariantContainer}>
            <button
              type="button"
              onClick={openVariantModalURL}
              className={styles.addVariantButton}
              disabled={formDataURL.variants.length >= 3}
            >
              {Array.isArray(formDataURL.variants) && formDataURL.variants.length > 0 ? 'Edit Variants' : 'Add Variants'}
            </button>
            <p>Make sure to add desired product variants to your request. Such as sizes (S,M,L) or colors (Red, Green, Blue)</p>
          </div>
          {Array.isArray(formDataURL.variants) && formDataURL.variants.length > 0 && (
            <div className={styles.selectedVariants}>
              {formDataURL.variants.map((variant, index) => (
                <div key={index} className={styles.variantCategory}>
                  <strong>{variant.name}:</strong> {variant.values.join(', ')}
                </div>
              ))}
            </div>
          )}
          <AddVariantModal
          isOpen={showCategoryModalURL}
          onRequestClose={closeVariantModalURL}
          maxVariants={3}
          onVariantsUpdated={handleSaveVariantsURL}
          existingVariantList={formDataURL.variants}
        />

          <button type="submit" className={styles.submitButton} disabled={isLoading}>
            {isLoading ? <LoadingButtonDots /> : 'Submit Request'}
          </button>
        </form>

        {/* Formulär för Details */}
        <form onSubmit={handleSubmitDetails} className={styles.detailsContainer}>
          <h2>Submit with Details</h2>
          <label htmlFor="countriesDetails"><span style={{ color: 'red' }}>*</span> Countries
            <Select
              isMulti
              name="countries"
              options={countryOptions}
              value={formDataDetails.selectedCountries}
              onChange={handleCountryChangeDetails}
              className={styles.countrySelect}
              classNamePrefix="select"
              styles={customStyles}
            />
          </label>
          <label htmlFor="imageDetails"><span style={{ color: 'red' }}>*</span> Image
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="imageDetails"
              onChange={handleFileChange}
              required
            />
          </label>
          <label htmlFor="productNameDetails"><span style={{ color: 'red' }}>*</span> Product Name
            <input
              type="text"
              id="productNameDetails"
              name="productName"
              value={formDataDetails.productName}
              onChange={handleInputChangeDetails}
              required
            />
          </label>
          <label htmlFor="descriptionDetails"><span style={{ color: 'red' }}>*</span> Description
            <textarea
              id="descriptionDetails"
              name="description"
              value={formDataDetails.description}
              onChange={handleInputChangeDetails}
              required
            />
          </label>
          <label htmlFor="urlDetails">URL
            <input
              id="urlDetails"
              name="url"
              value={formDataDetails.url}
              onChange={handleInputChangeDetails}
            />
          </label>
          <label htmlFor="averageMonthlyOrdersDetails">
            <div className={styles.avgMonthlyLabel}>
              Average Monthly Orders
              <Tooltip title="If you already have this product in your store, please specify your average orders per month" arrow>
                <span className="material-symbols-outlined">info</span>
              </Tooltip>
            </div>
            <input
              type="number"
              id="averageMonthlyOrdersDetails"
              name="averageMonthlyOrders"
              value={formDataDetails.averageMonthlyOrders || ''}
              onChange={handleInputChangeDetails}
            />
          </label>
          <label htmlFor="noteDetails">Note
            <textarea
              id="noteDetails"
              name="note"
              value={formDataDetails.note}
              onChange={handleInputChangeDetails}
            />
          </label>

          {/* Visa valda varianter */}
          <div className={styles.addVariantContainer}>
            {/* Knapp för att öppna AddVariantModal */}
            <button
              type="button"
              onClick={openVariantModalDetails}
              className={styles.addVariantButton}
              disabled={formDataDetails.variants.length >= 3}
            >
              {Array.isArray(formDataDetails.variants) && formDataDetails.variants.length > 0 ? 'Add Variants' : 'Edit Variants'}
            </button>
            <p>Make sure to add desired product variants to your request. Such as sizes (S,M,L) or colors (Red, Green, Blue)</p>
          </div>
          {/* AddVariantModal för Details-formuläret */}
          {Array.isArray(formDataDetails.variants) && formDataDetails.variants.length > 0 && (
            <div className={styles.selectedVariants}>
              {formDataDetails.variants.map((variant, index) => (
                <div key={index} className={styles.variantCategory}>
                  <strong>{variant.name}:</strong> {variant.values.join(', ')}
                </div>
              ))}
            </div>
          )}
          <AddVariantModal
          isOpen={showCategoryModalDetails}
          onRequestClose={closeVariantModalDetails}
          maxVariants={3}
          onVariantsUpdated={handleSaveVariantsDetails}
          existingVariantList={formDataDetails.variants}
        />
          <button type="submit" className={styles.submitButton} disabled={isLoadingTwo}>
            {isLoadingTwo ? <LoadingButtonDots /> : 'Submit Request with Details'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateRequest;
